import React, { FC, useContext, useEffect } from "react";
import {
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Divider,
  Flex,
  Text,
} from "@chakra-ui/react";

import { IConversation } from "../../helpers/types";
import { firestore_db } from "../../services/firebase";
import { ChatContext } from "../../contexts/ChatContext";
import { getCountry } from "../../helpers/getCountry";
import { MainContext } from "../../contexts/MainContext";

const Conversation: FC<{
  conversation: IConversation;
  selectedConversationId: string;
  onSidebarClose: Function;
}> = ({ conversation, selectedConversationId, onSidebarClose }) => {
  const {
    fullname,
    photoUrl,
    title,
    updatedAt,
    country,
    status,
    doc_id,

    readByUser,
    messages,
  } = conversation;
  const { handleRecieveMessage, handleSelectConversation } =
    useContext(ChatContext);
  const { isAuthenticated } = useContext(MainContext);
  const { flag, fr: name } = getCountry(country);

  useEffect(() => {
    if (isAuthenticated && doc_id) {
      const unSub = firestore_db
        .collection("chats")
        .doc(doc_id)
        .onSnapshot((doc) => {
          if (!doc.metadata.hasPendingWrites) {
            handleRecieveMessage(doc.id, doc.data(), selectedConversationId);
          }
        });
      return () => {
        unSub();
      };
    }
  }, [selectedConversationId]);

  return (
    <>
      <Flex
        flex={1}
        width={"full"}
        alignItems={"center"}
        my={2}
        p={2}
        rounded={"md"}
        cursor={"pointer"}
        bg={!readByUser ? "gray.200" : ""}
        _hover={{ backgroundColor: "#F1F1F1" }}
        onClick={() => {
          handleSelectConversation(doc_id);
          onSidebarClose();
        }}
      >
        <Avatar rounded={"md"} size={"lg"} src={photoUrl}>
          {!readByUser && <AvatarBadge boxSize="0.9em" bg="red.500" />}
        </Avatar>
        <Box mx={2} width={"full"}>
          <Flex justifyContent={"space-between"} flex={1} width={"full"}>
            <Text fontWeight={!readByUser ? "bold" : ""}>{title}</Text>
            {/* <Text color={"gray.400"}>2 jours</Text> */}
          </Flex>
          <Box>
            {/* <Text fontWeight={!readByUser ? "bold" : ""}>{title}</Text> */}
            {status === "ACTIF" && (
              <Badge
                bg={"green.100"}
                color={"green.700"}
                fontWeight={!readByUser ? "bold" : ""}
              >
                actif
              </Badge>
            )}
            {status === "CLOSED" && (
              <Badge
                bg={"red.100"}
                color={"red.700"}
                fontWeight={!readByUser ? "bold" : ""}
              >
                closed
              </Badge>
            )}

            <Badge>
              {flag} {name}
            </Badge>
          </Box>
        </Box>
      </Flex>
      <Divider />
    </>
  );
};

export default Conversation;

import axios from "axios";
import config from "../config";
const BASE_URL = `${config.API_URL}/paiements`;

export function usePaiements() {
  async function generatePaiementIntent() {
    const path = `${BASE_URL}/stripe-intent`;
    try {
      const { data } = await axios.get(path);
      return { data, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  }

  async function deletePaiementById(id: number) {
    const path = `${BASE_URL}/${id}`;
    try {
      const {
        data: { body },
      } = await axios.delete(path);
      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  }
  return { generatePaiementIntent, deletePaiementById };
}

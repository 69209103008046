import React, { FC, useContext, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { MainContext } from "../../contexts/MainContext";
import { inputs } from "../../data/inputs";
import LevelFormModal from "../modals/LevelFormModal";
import _ from "lodash";
import { getCountry } from "../../helpers/getCountry";
import moment from "moment";

const ApplicationForm: FC<{
  id: number;
  applicationId?: number | null;
  nextStep: () => void;
  prevStep: () => void;
  displayedInputs: string[];
  levleForm?: boolean;
  onCreateApplication: Function;
}> = ({
  id,
  applicationId = null,
  nextStep,
  prevStep,
  displayedInputs,
  onCreateApplication,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { account, updateProfile } = useContext(MainContext);
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...account,
      birthDate: moment(account?.birthDate).format("DD/MM/YYYY"),
      country: !_.isNull(account?.country)
        ? {
            label: `${getCountry(account.country).flag} - ${
              getCountry(account.country).fr
            }`,
            value: getCountry(account.country).code,
          }
        : null,
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    data.country = data?.country?.value;
    await updateProfile(data);
    setLoading(false);
    if (_.isNull(applicationId)) await onCreateApplication(id);
    nextStep();
  };

  return (
    <>
      <SimpleGrid columns={4} spacing={2} mt={2}>
        {inputs.map(
          ({
            component = Input,
            accessor,
            label,
            type,
            validation,
            handleSetValue = (e: any) => e?.target?.value,
            errorMessage,
            value = (e: any) => e,
            defaultValue = (e: any) => e,
            md = 2,
            ...rest
          }: any) => {
            const Component = component;

            return (
              displayedInputs.includes(accessor) && (
                <GridItem colSpan={[2, 2, md]}>
                  <FormControl
                    id={accessor}
                    isInvalid={!_.isNil(errors[String(accessor)])}
                    isRequired={validation?.required || false}
                  >
                    <FormLabel color={"grey"}>{label}</FormLabel>
                    <Component
                      bg={"white"}
                      {...register(String(accessor), { ...validation })}
                      {...rest}
                      onChange={(e: any) => {
                        setValue(accessor, handleSetValue(e));
                      }}
                      value={value(watch(accessor))}
                      defaultValue={value(getValues(accessor))}
                    />

                    <FormErrorMessage>{errorMessage}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              )
            );
          }
        )}
      </SimpleGrid>

      <Button
        my={4}
        onClick={handleSubmit(onSubmit)}
        width={"full"}
        bg={"primary"}
        color={"white"}
        isLoading={isLoading}
      >
        Suivant
      </Button>
    </>
  );
};

export default ApplicationForm;

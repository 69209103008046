import React, { FC } from "react";
import { Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { IFeature } from "../../../helpers/types";

const Feature: FC<IFeature> = ({ title, text, icon }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.900")}
      // boxShadow={"2xl"}
      rounded={"lg"}
      p={3}
      _hover={{ bg: "primary", color: "white", scale: 1.2 }}
      _groupHover={{ color: "white" }}
    >
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        mb={1}
      >
        {icon}
      </Flex>

      <Text fontWeight={600} fontSize={"base"}>
        {" "}
        {title}
      </Text>
      <Text fontSize={"sm"}>{text}</Text>
    </Stack>
  );
};

export default Feature;

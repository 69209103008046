import {
  Alert,
  Box,
  GridItem,
  Heading,
  RadioGroup,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { FC, useContext, useEffect, useState } from "react";
import { MainContext } from "../contexts/MainContext";
import { firestore_db } from "../services/firebase";
import CashPaiement from "./CashPaiement";
import RadioCard from "./common/RadioCard";
import DepositPaiement from "./DepositPaiement";
import StripePaiement from "./StripePaiement";

const Paiement: FC<{
  priceDZD: number;
  priceEUR: number;
  refused: boolean;
  onSubmit: Function;
  getIntnet: Function;
  nextStep: Function;
  prevStep: () => void;
}> = ({
  priceDZD,
  priceEUR,
  refused,
  onSubmit,
  nextStep,
  prevStep,
  getIntnet,
}) => {
  const { account } = useContext(MainContext);
  const [status, setStatus] = useState<"DEPOSIT" | "CASH" | "ON_LINE">(
    account.country === "DZ" ? "DEPOSIT" : "ON_LINE"
  );
  const [settings, setSettings] = useState<any>({});

  const handleChange = (e: "DEPOSIT" | "CASH" | "ON_LINE") => {
    setStatus(e);
  };

  const getSettings = async () => {
    await firestore_db
      .collection("settings")
      .doc("prod")
      .get()
      .then((snapshot: any) => {
        setSettings(snapshot?.data() || {});
      });
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Box width={"full"}>
      <RadioGroup
        defaultValue={"DEPOSIT"}
        onChange={handleChange}
        bg={"white"}
        p={4}
        rounded={"md"}
      >
        <Box my={2}>
          <Text fontSize={"sm"}>Etape 3/3 </Text>
          <Heading size={"md"}>Paiement</Heading>
          <Text fontSize={"xs"}>
            Vous pouvez choisir un des trois modes de paiement.
          </Text>
        </Box>
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing={2}>
          {account.country === "DZ" && (
            <>
              <GridItem colSpan={1}>
                <RadioCard
                  value={"DEPOSIT"}
                  title={"Paiement par versement"}
                  description={
                    "Copier les coordonnées bancaires et revenez plus tard pour télécharger votre reçu une fois le paiement effectué."
                  }
                />
              </GridItem>
              <GridItem>
                <RadioCard
                  value={"CASH"}
                  title={"Paiement par rendez-vous"}
                  description={
                    "Prenez un rendez-vous pour vous rendre au sein de nos bureaux afin d’effectuer votre paiement."
                  }
                />
              </GridItem>
            </>
          )}
          <GridItem>
            <RadioCard
              value={"ON_LINE"}
              title={"Paiement en-ligne"}
              description={
                "Effectuer votre paiement avec une carte bancaire et votre demande sera acceptée automatiquement "
              }
            />
          </GridItem>
        </SimpleGrid>
      </RadioGroup>
      <Box bg={"white"} rounded={"md"} py={4} my={2}>
        {account.country === "DZ" && (
          <Heading textAlign={"center"}>
            Total a payer (DZD) : {priceDZD} DZD
          </Heading>
        )}
        <Heading textAlign={"center"}>
          Total a payer (Euro) : {priceEUR} Euro
        </Heading>
        {/* <Text mt={2} textAlign={"center"} fontSize={"xl"} color={"red.600"}>
          Vous avez le choix entre faire un versement en euro ou en dinars
        </Text> */}
      </Box>
      {refused && (
        <Alert status="warning">
          This application has been refused before, you should be submitting
          another paiement.
        </Alert>
      )}
      <Box my={2}>
        {status === "DEPOSIT" && (
          <DepositPaiement
            settings={settings}
            onSubmit={onSubmit}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        )}
        {status === "CASH" && (
          <CashPaiement
            excludedDays={settings?.excludedDays}
            onSubmit={onSubmit}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        )}
        {status === "ON_LINE" && (
          <StripePaiement
            onSubmit={onSubmit}
            nextStep={nextStep}
            prevStep={prevStep}
            handleGetPaiementIntent={getIntnet}
          />
        )}
      </Box>
    </Box>
  );
};

export default Paiement;

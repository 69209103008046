import React, { FC, useContext } from "react";
import { Box, Button, Stack } from "@chakra-ui/react";
import { INavItem } from "../../../helpers/types";
import MobileNavItem from "./MobileNavItem";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../../contexts/MainContext";

const MobileNavBar: FC<{ items: INavItem[]; onClose: () => void }> = ({
  items,
  onClose,
}) => {
  const { isAuthenticated } = useContext(MainContext);
  const navigate = useNavigate();
  return (
    <Box pb={4}>
      <Stack bg={"secondary"} height={"100vh"} p={4} display={{ md: "none" }}>
        {items.map(({ link = "", content, children = [] }) => (
          <MobileNavItem
            key={uuidv4()}
            path={link}
            content={content}
            children={children}
            onClose={onClose}
          />
        ))}
        {!isAuthenticated && (
          <>
            <Button
              mx={2}
              width={"full"}
              borderColor={"primary"}
              onClick={() => {
                navigate("/sign-in");
                onClose();
              }}
            >
              Connexion
            </Button>
            <Button
              bg={"primary"}
              color={"white"}
              width={"full"}
              onClick={() => {
                navigate("/sign-up");
                onClose();
              }}
            >
              Inscription
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default MobileNavBar;

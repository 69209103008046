import { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
const BASE_URL = `${config.API_URL}/programs`;

export function usePrograms() {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>({
    countryCode: "",
    establishmentType: "",
    establishmentId: "",
    programType: "",
  });

  useEffect(() => {
    getData();
  }, [filter]);

  async function init() {
    return {
      countryCode: "",
      establishmentType: "",
      establishmentId: "",
      programType: "",
    };
  }

  async function getData() {
    setLoading(true);
    setError(false);

    if (filter.countryCode !== "") {
      const path = `${BASE_URL}?itemsPerPage=100&${
        filter.countryCode.length > 0 ? "countryCode=" + filter.countryCode : ""
      }${
        filter.establishmentType.length > 0
          ? "&establishment=" + filter.establishmentType
          : ""
      }${
        filter.establishmentId.length > 0
          ? "&establishmentId=" + filter.establishmentId
          : ""
      }${
        filter.programType.length > 0 ? "&degrees=" + filter.programType : ""
      }`;

      try {
        const {
          data: {
            body: { data },
          },
        } = await axios.get(path);

        setData(data);
        setLoading(false);
      } catch (error) {
        setError(true);
      }
    } else {
      setData([]);
    }
    setLoading(false);
  }
  async function getAttachedPrograms() {
    const path = `${BASE_URL}?attached=1`;
    try {
      const {
        data: {
          body: { data = [] },
        },
      } = await axios.get(path);
      return { data: data || [], error: null };
    } catch (error) {
      return {
        error,
      };
    }
  }
  async function getProgramById(id: number) {
    const path = `${BASE_URL}/${id}`;
    try {
      const {
        data: { body },
      } = await axios.get(path);
      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  }

  function updateFilter(updatedFilter: any) {
    setFilter({ ...updatedFilter });
  }

  return {
    data,
    filter,
    isLoading,
    error,
    init,
    getProgramById,
    getData,
    getAttachedPrograms,
    updateFilter,
  };
}

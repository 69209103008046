import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { FC } from "react";

const SectionWithImage: FC<{
  title: string;
  paragraph: string;
  reverse: boolean;
  imageUrl: string;
}> = ({ title, paragraph, reverse, imageUrl }) => (
  <Flex flexDirection={["column", reverse ? "row-reverse" : "row"]}>
    <Image
      alt={"Hero Image"}
      fit={"cover"}
      align={"center"}
      roundedTopLeft={["3xl", 80]}
      roundedBottomRight={["3xl", 80]}
      roundedTopRight={"3xl"}
      roundedBottomLeft={"3xl"}
      w={["100%", "100%", "50%"]}
      h={"100%"}
      src={imageUrl}
    />

    <Box my={"auto"} px={[2, 8]} py={[4, 8]}>
      <Heading size={"lg"}>{title}</Heading>
      <Text my={2} fontSize={"lg"}>
        {paragraph}
      </Text>
    </Box>
  </Flex>
);

export default SectionWithImage;

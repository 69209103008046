import { Badge, Box, Flex, ListItem, UnorderedList } from "@chakra-ui/react";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";

const Documents: FC<{ documents: any[] }> = ({ documents }) => {
  return (
    <>
      <UnorderedList listStyleType={"none"} py={2} px={8}>
        {documents?.map(({ document = null, mandatory }) => (
          <>
            <ListItem>
              <Flex>
                <FontAwesomeIcon
                  icon={faThumbtack}
                  size={"lg"}
                  color={mandatory ? "#02044A" : "#79798A"}
                  rotate={90}
                />
                <Box mx={2}>
                  {document?.name} ({mandatory && <Badge>obligatoire</Badge>}
                  {!mandatory && <Badge>optional</Badge>})
                </Box>
              </Flex>
            </ListItem>
          </>
        ))}
      </UnorderedList>
    </>
  );
};

export default Documents;

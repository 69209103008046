import React, { FC, ReactElement } from "react";
import {
  Badge,
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

const CardItem: FC<{
  pictureUrl: string;
  handleClick: () => void;
  children: ReactElement;
  subChildren: ReactElement;
}> = ({ pictureUrl, handleClick, children, subChildren }) => {
  return (
    <Stack
      borderWidth="1px"
      borderRadius="lg"
      minHeight={{ sm: "auto", md: "10rem" }}
      direction={{ base: "column", md: "row" }}
      m={4}
      pr={[0, 2]}
    >
      <Box minHeight={"10rem"} maxWidth={["auto", "30%"]}>
        <Image
          objectFit="cover"
          boxSize="100%"
          // width={"100%"}
          roundedLeft={["none", "xl"]}
          roundedTop={["xl", "none"]}
          src={pictureUrl}
        />
      </Box>
      <Stack
        // flex={1}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="start"
        p={1}
        pt={2}
      >
        {children}
      </Stack>
      <Stack
        py={2}
        px={[2, 0]}
        width={{ sm: "100%", md: "20%" }}
        justifyContent={[null, "center"]}
      >
        <Box px={["", 5]}>{subChildren}</Box>

        <Button
          width={"100%"}
          fontSize={"sm"}
          bg={"primary"}
          color={"white"}
          onClick={handleClick}
        >
          Postuler
        </Button>
      </Stack>
    </Stack>
  );
};

export default CardItem;

import React, { FC } from "react";

import { Step, Steps, useSteps } from "chakra-ui-steps";
import { Box, Button, Center, Flex, Heading, Text } from "@chakra-ui/react";
import ApplicationForm from "../../common/ApplicationForm";
import ApplicationFilesForm from "../../common/ApplicationFilesForm";
import Paiement from "../../Paiement";
import ApplicationSubmitted from "../../common/ApplicationSubmitted";

const Stepper: FC<{
  id: number;
  applicationId: number | null;
  url: string;
  filesNotes: string;
  documents: any[];
  inputs: string[];
  initialStep: number;
  levelForm: boolean;
  priceDZD: number;
  priceEUR: number;
  refused: boolean;
  onCreateApplication: Function;
  onSubmit: Function;
  getIntnet: Function;
}> = ({
  id,
  applicationId,
  url,
  filesNotes,
  documents,
  inputs,
  initialStep = 0,
  levelForm,
  priceDZD,
  priceEUR,
  refused,
  onCreateApplication,
  onSubmit,
  getIntnet,
}) => {
  const { nextStep, activeStep, prevStep } = useSteps({
    initialStep,
  });

  const steps = [
    {
      label: "Informations personnels",
      content: (
        <ApplicationForm
          id={id}
          applicationId={applicationId || null}
          levleForm={levelForm}
          displayedInputs={inputs}
          nextStep={nextStep}
          prevStep={prevStep}
          onCreateApplication={onCreateApplication}
        />
      ),
    },
    {
      label: "Dossier a fournir ",
      content: (
        <ApplicationFilesForm
          notes={filesNotes}
          documents={documents}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      ),
    },
    {
      label: "paiement",
      content: (
        <Paiement
          priceDZD={priceDZD}
          priceEUR={priceEUR}
          refused={refused}
          onSubmit={onSubmit}
          nextStep={nextStep}
          getIntnet={getIntnet}
          prevStep={prevStep}
        />
      ),
    },
  ];
  return (
    <Flex flexDir="column" width="100%" my={6}>
      <Steps activeStep={activeStep} colorScheme={"blue"}>
        {steps.map(({ label, content }, index) => (
          <Step label={label} key={label}>
            <Box p={[1, 10]}>{content}</Box>
          </Step>
        ))}
      </Steps>
      {activeStep === steps.length ? (
        <ApplicationSubmitted url={url || ""} />
      ) : (
        <Flex width="100%" justify="flex-end"></Flex>
      )}
    </Flex>
  );
};

export default Stepper;

import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Flex, GridItem, SimpleGrid } from "@chakra-ui/react";
import _ from "lodash";
import Select from "react-select";
import { VisasContext } from "../../contexts/VisasContext";
import getFlagByCode from "../../helpers/getFlagByCode";
import { useEstablishments } from "../../hooks/useEstablishments";
import { useProgramCountries } from "../../hooks/useProgramCountries";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useVisaCountries } from "../../hooks/useVisaCountries";

const VisasSearchForm = () => {
  const { updateFilter, init, data } = useContext(VisasContext);
  const { getCountries } = useVisaCountries();
  // OPTIONS LISTS
  const [countries, setCountries] = useState<any>([]);

  // States
  const [country, setCountry] = useState<any>(null);
  const [visaType, setVisaType] = useState<any>(null);

  const formatCountriesToOptionsList = (data: any) => {
    let options: any[] = [];
    data.map(
      ({
        code,
        name,
        displayUniversities,
      }: {
        code: string;
        name: string;
        displayUniversities: boolean;
      }) => {
        options.push({
          label: `${getFlagByCode(code)} - ${name}`,
          value: code,
          displayUniversities,
        });
      }
    );
    return options;
  };

  const formatVisasToOptionsList = (data: any) => {
    let options: any[] = [];
    data.map(({ id, type }: { id: number; type: string }) => {
      options.push({
        label: `Visa ${type}`,
        value: id,
      });
    });
    return options;
  };

  const getCountriesList = async () => {
    const { data, error } = await getCountries();

    const options = formatCountriesToOptionsList(data);
    setCountries(options);
  };

  const handleChangeCountry = async (e: any) => {
    // init();
    setCountry(e);
    setVisaType(null);
    updateFilter("countryCode", e.value);
  };

  const inputs = [
    {
      show: true,
      options: countries,
      handleChange: handleChangeCountry,
      placeholder: "Choisir le pays ...",
      value: country,
    },
  ];

  useEffect(() => {
    getCountriesList();
  }, []);

  return (
    <Box bg={"white"} p={4} rounded={"md"}>
      <SimpleGrid columns={4} spacing={2}>
        {inputs.map(
          ({ show, options, handleChange, placeholder, value }) =>
            show && (
              <GridItem colSpan={[4, 1]} key={uuidv4()}>
                <Select
                  options={options}
                  onChange={handleChange}
                  placeholder={placeholder}
                  value={value}
                />
              </GridItem>
            )
        )}
      </SimpleGrid>
      <Flex justifyContent={"flex-end"} my={1}>
        <Button
          size={"xs"}
          bg={"primary"}
          color={"white"}
          onClick={() => {
            setCountry(null);
            init();
          }}
        >
          clear all
        </Button>
      </Flex>
    </Box>
  );
};

export default VisasSearchForm;

import React, { ReactNode } from "react";
import { Text } from "@chakra-ui/react";

const FooterListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text color="white" fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default FooterListHeader;

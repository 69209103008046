import React, { Component, FC, ReactNode } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { IApplicationItem } from "../../helpers/types";
import { Navigate, useNavigate } from "react-router-dom";
import DeleteApplicationPopover from "../common/DeleteApplicationPopover";

const ApplicationItem: FC<IApplicationItem> = ({
  imageUrl,
  items,
  path,
  buttonText,
  isDeleteEnabled,
  onDelete,
}) => {
  const navigate = useNavigate();
  const renderComponent = (component: any) => {
    const Component = component;
    return <Component />;
  };
  return (
    <Stack
      borderWidth="1px"
      borderRadius="lg"
      height={["auto", "4rem"]}
      direction={["column", "row"]}
      m={4}
      alignItems={"center"}
      flexDirection={["column", "row"]}
    >
      <Box height={["8rem", "4rem"]} width={["100%", "15%"]}>
        <Image objectFit="cover" boxSize="100%" rounded={"md"} src={imageUrl} />
      </Box>
      <Grid
        px={4}
        py={4}
        width={"100%"}
        templateColumns={["repeat(2, 1fr)", "repeat(4, 1fr)"]}
        gap={6}
      >
        {items.map(({ label, accessor }) => (
          <GridItem key={uuidv4()} w={["100%", "80%"]}>
            {typeof label === "string" && (
              <Heading size={"xs"}>{label}</Heading>
            )}
            {typeof label === "function" && renderComponent(label)}
            {typeof accessor === "string" && (
              <Text fontSize={"md"} color={"gray.900"}>
                {accessor}
              </Text>
            )}
            {typeof accessor === "function" && renderComponent(accessor)}
          </GridItem>
        ))}
      </Grid>
      {/* <Flex flex={1}> */}
      <Button
        width={["100%", "15%"]}
        bg="primary"
        color={"white"}
        px={6}
        onClick={() => {
          navigate(path);
        }}
        // css={{ zIndex: -1 }}
        zIndex={"base"}
      >
        {buttonText}
      </Button>
      <DeleteApplicationPopover onDelete={onDelete} enabled={isDeleteEnabled} />
      {/* </Flex> */}
    </Stack>
  );
};

export default ApplicationItem;

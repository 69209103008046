import axios from "axios";
import { createRef, useContext, useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";
import config from "../config";
import { IConversation, MessageType } from "../helpers/types";
import { firestore_db } from "../services/firebase";
import firebase from "firebase/app";
import moment from "moment";
import { MainContext } from "../contexts/MainContext";
import _ from "lodash";
import { useToast } from "@chakra-ui/react";

const BASE_URL = `${config.API_URL}/conversations`;
export function useChat() {
  const toast = useToast();

  const { isAuthenticated } = useContext(MainContext);
  const [isLoading, setLoading] = useState(false);
  const [conversations, setConversations] = useState<any[]>([]);

  const [messages, setMessages] = useState<any[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<
    string | null
  >(null);

  const [metaData, setMetaData] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    itemsPerPage: localStorage.getItem("itemsPerPage") || 40,
    type: "",
  });
  const filterDebouncedValue = useDebounce(filter);

  useEffect(() => {
    if (isAuthenticated) getConversations();
  }, [isAuthenticated]);

  async function getConversations() {
    setLoading(true);
    const path = `${BASE_URL}`;

    try {
      const {
        data: { body },
      } = await axios.get(path);

      //   format conversations
      let formatedConversations: any[] = [];
      const conversations: any = Object.values(body);
      for (let index = 0; index < conversations.length; index++) {
        const conversation = conversations[index];

        let title = "";
        let country = "";

        if (conversation.paiement.applicationType === "PROGRAM") {
          var {
            firstname = "",
            lastname = "",
            photoUrl = null,
          } = conversation?.paiement?.programApplication?.client || {
            firstname: "",
            lastname: "",
            photoUrl: null,
          };
          title =
            conversation?.title ||
            conversation?.paiement?.programApplication?.program?.name ||
            "No-name";
          country =
            conversation?.paiement?.programApplication?.program?.countryCode ||
            "CA";
          const {
            id,
            doc_id,
            readByAdmin,
            readByUser,
            messages = [],
          } = conversation;
          formatedConversations.push({
            id,
            doc_id,
            readByAdmin,
            readByUser,
            fullname: `${firstname} ${lastname}`,
            title,
            country,
            status: conversation.status,
            photoUrl: (photoUrl && `${config.S3_URL}/${photoUrl}`) || "",
            updatedAt: "2022-09-09",
            messages,
          });
        }
        if (conversation.paiement.applicationType === "VISA") {
          var {
            firstname,
            lastname,
            photoUrl = null,
          } = conversation?.paiement?.visaApplication?.client || {
            firstname: "",
            lastname: "",
            photoUrl: null,
          };
          title =
            conversation?.title ||
            "Visa " + conversation?.paiement?.visaApplication?.visa?.type ||
            "";
          country =
            conversation?.paiement?.visaApplication?.visa?.countryCode || "CA";
          const {
            id,
            doc_id,
            readByAdmin,
            readByUser,
            messages = [],
          } = conversation;
          formatedConversations.push({
            id,
            doc_id,
            readByAdmin,
            readByUser,
            fullname: `${firstname} ${lastname}`,
            title,
            country,
            status: conversation.status,
            photoUrl: (photoUrl && `${config.S3_URL}/${photoUrl}`) || "",
            updatedAt: "2022-09-09",
            messages,
          });
        }
        if (
          conversation.paiement.applicationType === "ADMINISTRATIVE_SUPPORT"
        ) {
          var {
            firstname,
            lastname,
            photoUrl = null,
          } = conversation?.paiement?.administrativeSupportApplication
            ?.client || {
            firstname: "",
            lastname: "",
            photoUrl: null,
          };
          title = "Support Administrative";
          country = "FR";
          const {
            id,
            doc_id,
            readByAdmin,
            readByUser,
            messages = [],
          } = conversation;
          formatedConversations.push({
            id,
            doc_id,
            readByAdmin,
            readByUser,
            fullname: `${firstname} ${lastname}`,
            title,
            country,
            status: conversation.status,
            photoUrl: (photoUrl && `${config.S3_URL}/${photoUrl}`) || "",
            updatedAt: "2022-09-09",
            messages,
          });
        }
      }

      const sortedConversations = _.orderBy(
        formatedConversations,
        ({ updatedAt }: IConversation) => {
          return moment(updatedAt);
        },
        ["desc"]
      );

      setConversations(sortedConversations || []);
    } catch (error) {}
    setLoading(false);
  }

  async function handleSelectConversation(doc_id: string | null) {
    if (doc_id) {
      const index = conversations.findIndex((item) => item.doc_id === doc_id);
      const conversation = conversations[index];
      setSelectedConversation(conversation);
      if (!conversation.readByUser) {
        await firestore_db
          .collection("chats")
          .doc(doc_id)
          .update({ readByUser: true });
        let updatedConversations = conversations;
        updatedConversations[index].readByUser = true;
        setConversations(updatedConversations);
      }
    }
  }

  async function handleSendMessage(
    type: MessageType,
    content: string,
    currentConversation: string
  ) {
    const index = conversations.findIndex(
      (item) => item.doc_id === currentConversation
    );
    const conversation = conversations[index];
    const message = {
      type,
      content,
      byAdmin: false,
      sendAt: moment().toISOString(),
    };
    let updatedConversation: any = {
      ...conversation,
      readByUser: true,
      readByAdmin: false,
      messages: [...(conversation?.messages || []), message],
      updatedAt: moment().toISOString(),
    };
    let updatedConversations = conversations;
    updatedConversations[
      conversations.findIndex((item) => item.doc_id === currentConversation)
    ] = updatedConversation;
    await firestore_db
      .collection("chats")
      .doc(conversation.doc_id)
      .update({
        readByUser: true,
        readByAdmin: false,
        messages: firebase.firestore.FieldValue.arrayUnion(message),
        updatedAt: moment().toISOString(),
      });
    const sortedConversations = _.orderBy(
      updatedConversations,
      ({ updatedAt }: IConversation) => {
        return moment(updatedAt);
      },
      ["desc"]
    );
    setConversations(sortedConversations);
    handleSelectConversation(currentConversation);
  }

  async function handleRecieveMessage(
    doc_id: string,
    conversation: any,
    currentConversation: string | null
  ) {
    let updatedConversations: IConversation[] = conversations;
    const index = updatedConversations.findIndex(
      (item) => item.doc_id === doc_id
    );
    const updatedConversation = {
      ...updatedConversations[index],
      ...conversation,
    };
    updatedConversations[index] = updatedConversation;
    const sortedConversations = _.orderBy(
      updatedConversations,
      ({ updatedAt }: IConversation) => {
        return moment(updatedAt);
      },
      ["desc"]
    );
    // if (!conversation?.readByUser)
    //   toast({
    //     duration: 5000,
    //     description: "Nouveaux messages",
    //     status: "warning",
    //     isClosable: true,
    //     // icon:,
    //   });
    setConversations(sortedConversations);
    handleSelectConversation(currentConversation);
  }

  function updateFilter(attribute: string, value: string) {
    setFilter({ ...filter, [attribute]: value });
  }

  return {
    conversations,
    selectedConversation,
    messages,
    handleSelectConversation,
    handleSendMessage,
    handleRecieveMessage,
    metaData,
    filter,
    isLoading,
    updateFilter,
  };
}

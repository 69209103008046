import React, { useContext, useEffect, useRef } from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import CardItem from "../../components/CardItem/CardItem";
import VisasSearchForm from "../../components/forms/VisasSearchForm";
import { VisasContext } from "../../contexts/VisasContext";
import { v4 as uuidv4 } from "uuid";

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import config from "../../config";
import shortenString from "../../helpers/shortenString";

const SearchVisa = () => {
  const navigate = useNavigate();
  const { data, isLoading, error } = useContext(VisasContext);

  const handleClick = (id: number) => {
    navigate(`/visas/${id}`);
  };

  const topRef: any = useRef();

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div ref={topRef} />
      <Box my={10}>
        <Box bg={"gray.100"} py={8}>
          <Container maxW={"6xl"}>
            <Box my={4}>
              <Heading fontSize={"lg"}>Faire une demande de visa</Heading>
              <Text fontSize={"sm"}>
                choisir le pays et le type de visa auquel vous souhaitez
                postuler
              </Text>
            </Box>
            <VisasSearchForm />
          </Container>
        </Box>
        <Container maxW={"6xl"}>
          {isLoading && (
            <Center height={"100vh"}>
              <Spinner size={"xl"} />
            </Center>
          )}
          {!error && !isLoading && data.length === 0 && (
            <Center height={"100vh"}>
              <Box>
                <Text textAlign={"center"}>
                  No data found ,<br /> please try again !
                </Text>
                <Image src={"/search.svg"} w={80} />
              </Box>
            </Center>
          )}
          src=
          {!error && !isLoading && data.length > 0 && (
            <SimpleGrid columns={5} spacing={2}>
              {/* <GridItem colSpan={1}></GridItem> */}
              <GridItem colSpan={4}>
                <div ref={topRef} />

                {/* {error && <Alert>error happened please try again</Alert>} */}
                {data.map((item: any) => (
                  <CardItem
                    key={uuidv4()}
                    pictureUrl={
                      (item?.pictureUrl &&
                        `${config.S3_URL}/${item.pictureUrl}`) ||
                      "/default-placeholder.png"
                    }
                    handleClick={() => {
                      handleClick(item.id);
                    }}
                    subChildren={
                      <>
                        Prix a payer :<br />
                        {/* <b>{item.priceDZD} DA</b> ou */}
                        <b>{item.priceEUR} Euros</b>
                      </>
                    }
                  >
                    <>
                      <Box>
                        <Heading size={"md"}>
                          visa {item.type} vers {item.countryCode}
                        </Heading>
                      </Box>
                      <Box my={1}>
                        <Text fontSize={"sm"}>
                          {" "}
                          {shortenString(item.shortDescription, 100)}
                        </Text>

                        <Flex fontSize={"sm"}>
                          <FontAwesomeIcon icon={faLanguage} size={"lg"} />
                          <Text mx={1}>{item.language}</Text>
                        </Flex>
                      </Box>
                    </>
                  </CardItem>
                ))}
              </GridItem>
            </SimpleGrid>
          )}
        </Container>
      </Box>
    </>
  );
};

export default SearchVisa;

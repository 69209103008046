import React, { FC } from "react";
import {
  Box,
  Button,
  Heading,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { flags } from "../../../data/flags";
import { getCountry } from "../../../helpers/getCountry";
import { useNavigate } from "react-router-dom";

const Card: FC<{
  id: number;
  pictureUrl: string;
  country: string;
  title: string;
  description: string;
}> = ({ id, pictureUrl, country, title, description }) => {
  const navigate = useNavigate();
  const { flag, fr } = getCountry(country);
  return (
    <Box
      maxW={["auto", "420px"]}
      w={"full"}
      bg={useColorModeValue("white", "gray.900")}
      // boxShadow={"2xl"}

      rounded={"2xl"}
      p={3}
      overflow={"hidden"}
      zIndex={"base"}
      _hover={{ scaleX: 2, cursor: "pointer" }}
    >
      <Box h={"210px"} bg={"gray.100"} mt={-6} mx={-6} mb={6} pos={"relative"}>
        <Image src={pictureUrl} objectFit="cover" boxSize="100%" />
      </Box>
      <Stack>
        <Text
          color={"primary"}
          textTransform={"uppercase"}
          fontWeight={800}
          fontSize={"sm"}
          letterSpacing={1.1}
        >
          {flag} - {fr}
        </Text>
        <Heading
          color={useColorModeValue("gray.700", "white")}
          fontSize={"lg"}
          fontFamily={"body"}
        >
          {title}
        </Heading>
        <Text color={"gray.500"} fontSize={"sm"}>
          {description}
        </Text>
      </Stack>
      <Button
        py={1}
        my={1}
        fontSize={"base"}
        bg={"primary"}
        color={"white"}
        width={"100%"}
        onClick={() => {
          navigate(`/programs/${id}`);
        }}
      >
        Voir plus
      </Button>
      {/* <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
      <Avatar
        src={"https://avatars0.githubusercontent.com/u/1164541?v=4"}
        // alt={"Author"}
      />
      <Stack direction={"column"} spacing={0} fontSize={"sm"}>
        <Text fontWeight={600}>Achim Rolle</Text>
        <Text color={"gray.500"}>Feb 08, 2021 · 6min read</Text>
      </Stack>
    </Stack> */}
    </Box>
  );
};
export default Card;

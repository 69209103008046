import React, { FC, ReactNode, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
const ProtectedRoute: FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthenticated } = useContext(MainContext);

  return isAuthenticated ? children : <Navigate to="/sign-in" />;
};

export default ProtectedRoute;

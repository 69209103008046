import React, { FC, useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Alert, AlertIcon, Box, Button, SimpleGrid } from "@chakra-ui/react";

const CheckoutForm: FC<{
  onSubmit: Function;
  nextStep: Function;
  prevStep: () => void;
}> = ({ onSubmit, nextStep, prevStep }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isError, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError(false);
    setMessage(null);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3000/#/programs",
        return_url: "https://sdzdtravel.dz/api/",
      },
      redirect: "if_required",
    });
    if (error) {
    }
    if (!error) await onSubmit("ON_LINE");
    if (error) {
      setError(true);
      setMessage(error?.message || null);
    } else {
      nextStep();
    }
    // if(paymentIntent){nextStep()}

    setIsLoading(false);
  };

  return (
    <Box bg={"white"} p={6} rounded={"md"}>
      {isError && (
        <Alert status="error" rounded={"md"} my={4}>
          <AlertIcon /> {message}
        </Alert>
      )}
      <PaymentElement id="payment-element" />
      <SimpleGrid columns={[1, 2]} gap={2} my={4}>
        <Button
          onClick={prevStep}
          width={"full"}
          color={"primary"}
          bg={"white"}
        >
          Précédent
        </Button>

        <Button
          color={"white"}
          bg={"primary"}
          width={"full"}
          isDisabled={isLoading || !stripe || !elements}
          isLoading={isLoading}
          onClick={handleSubmit}
          my={2}
        >
          Pay now
        </Button>
      </SimpleGrid>
    </Box>
  );
};
export default CheckoutForm;

import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  GridItem,
  Heading,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Spinner,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePrograms } from "../../hooks/usePrograms";
import { CalendarIcon, AtSignIcon } from "@chakra-ui/icons";
import { FaLanguage } from "react-icons/fa";
import isBetween from "../../helpers/isBetween";
import ListSidebar from "../../components/common/ListSidebar";
import Documents from "../../components/common/Documents";
import config from "../../config";
import ItemNotFound from "../../components/common/ItemNotFound";

const Program = () => {
  const { id = null } = useParams();
  const navigate = useNavigate();
  const { getProgramById } = usePrograms();
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    const { error = null, data } = await getProgramById(Number(id));

    if (!error) {
      setData(data);
    } else {
      setError(true);
    }

    setLoading(false);
  };
  const filterDocuments = (documents: any, path: string, type: string) => {
    const result: any[] = documents.filter(
      (item: any) => item[path].type === type
    );
    return result;
  };
  useEffect(() => {
    if (id) {
      getData();
    } else {
      setLoading(false);
    }
  }, []);

  if (isLoading)
    return (
      <Center height={"100vh"}>
        <Spinner size={"xl"} />
      </Center>
    );

  if (error) return <ItemNotFound />;
  const sidebarList = [
    "A propos du program",
    "Frais",
    "Description",
    "Documents",
    "Détails additionnels",
  ];

  return (
    <Container maxW={"6xl"} mt={12} height={"auto"}>
      <SimpleGrid columns={3} spacing={2} height={"auto"}>
        <GridItem colSpan={[0, 1]} display={["none", "block"]}>
          <ListSidebar items={sidebarList} />
        </GridItem>
        <GridItem position={"relative"} colSpan={[3, 2]} my={2} height={"full"}>
          <Image
            height={260}
            width={"100%"}
            objectFit={"cover"}
            src={
              (data?.pictureUrl && `${config.S3_URL}/${data.pictureUrl}`) ||
              "/default-placeholder.png"
            }
          />
          <Box my={6}>
            <Heading color={"primary"}>{data.name}</Heading>
            <Text color={"gray.700"}>
              <>
                <b>IMPORTANT : </b>
                <Badge
                  bg={
                    !isBetween(
                      data.registrationStartDate,
                      data.registrationEndDate
                    )
                      ? "red.200"
                      : "green.200"
                  }
                  rounded={"md"}
                >
                  {!isBetween(
                    data.registrationStartDate,
                    data.registrationEndDate
                  )
                    ? "inscription fermé"
                    : "inscription ouverte"}
                </Badge>{" "}
                {moment(data.registrationStartDate).isAfter() &&
                  " seront ouverte "}
                du {moment(data.registrationStartDate).format("DD-MM-YYYY")} au
                &nbsp;
                {moment(data.registrationEndDate).format("DD-MM-YYYY")}
              </>
            </Text>
            <SimpleGrid columns={2} spacing={2}>
              <GridItem colSpan={[2, 1]} display={"flex"} alignItems={"center"}>
                <Icon as={CalendarIcon} color="primary" w={6} h={6} mx={2} />
                <Text>
                  Début des cours :{" "}
                  {moment(data.startDate).format("DD-MM-YYYY")}
                </Text>
              </GridItem>
              <GridItem colSpan={[2, 1]} display={"flex"} alignItems={"center"}>
                <Icon
                  as={FaLanguage}
                  color="primary"
                  w={8}
                  h={8}
                  mx={2}
                  my={"auto"}
                />
                <Box>
                  Langues d’enseignement :{" "}
                  {data.languages.map((value: string) => {
                    return <Badge>{value}</Badge>;
                  })}
                </Box>
              </GridItem>
            </SimpleGrid>
          </Box>
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              Fees
            </Heading>
            <UnorderedList>
              {data.fees.map(
                ({
                  label = "",
                  value = "",
                }: {
                  label: string;
                  value: string;
                }) => (
                  <ListItem>
                    {label} : {value}
                  </ListItem>
                )
              )}
            </UnorderedList>
          </Box>
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              Description
            </Heading>
            <Box
              dangerouslySetInnerHTML={{
                __html: data?.description || "",
              }}
            />
          </Box>
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              Nos services
            </Heading>
            <Box
              dangerouslySetInnerHTML={{
                __html: data?.services || "",
              }}
            />
          </Box>
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              Conditions d'admission
            </Heading>
            <Box
              dangerouslySetInnerHTML={{
                __html: data?.prerequisites || "",
              }}
            />
          </Box>
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              Documents
            </Heading>

            <Heading fontWeight={"semibold"} size={"md"}>
              Documents academiques :
            </Heading>
            <Documents
              documents={filterDocuments(
                data.documents,
                "document",
                "ACADEMIC"
              )}
            />

            <Heading fontWeight={"semibold"} size={"md"}>
              Documents administratives :
            </Heading>
            <Documents
              documents={filterDocuments(
                data.documents,
                "document",

                "ADMINISTRATIVE"
              )}
            />

            <Heading fontWeight={"semibold"} size={"md"}>
              Documents de langues :
            </Heading>
            <Documents
              documents={filterDocuments(
                data.documents,
                "document",
                "LANGUAGE"
              )}
            />
          </Box>
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              Détails additionnels :
            </Heading>
            <Box
              dangerouslySetInnerHTML={{
                __html: data?.additionalDetails || "",
              }}
            />
          </Box>

          <Button
            className="sticky-button"
            bg={"primary"}
            color={"white"}
            w={"full"}
            onClick={() => {
              navigate(`/programs/applications/${id}/apply`);
            }}
            disabled={
              !isBetween(data.registrationStartDate, data.registrationEndDate)
            }
            css={{ position: "sticky", bottom: 100 }}
          >
            {isBetween(data.registrationStartDate, data.registrationEndDate)
              ? "Postuler maintenant"
              : "Inscriptions sont fermés maintenant"}
          </Button>
        </GridItem>
      </SimpleGrid>
    </Container>
  );
};

export default Program;

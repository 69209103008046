import React, { FC, useEffect, useState } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useStorage } from "../hooks/useStorage";
import FileInput from "./common/FileInput";
import { v4 as uuidv4 } from "uuid";

import config from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import axios from "axios";
import moment from "moment";

const DepositPaiement: FC<{
  settings: any;
  onSubmit: Function;
  nextStep: Function;
  prevStep: () => void;
}> = ({ settings, onSubmit, nextStep, prevStep }) => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const { uploadFile } = useStorage();

  const onDrop = (files: File[]) => {
    setFile(files[0]);
  };

  const handleSubmit = async () => {
    setError(false);
    setLoading(true);
    if (!file) {
      setError(true);
      setErrorMessage("télécharger votre reçu de paiement");
    }
    if (file) {
      const key = await uploadFile(file, "paiements", uuidv4());
      if (key !== "error") {
        const { data, error } = await onSubmit("DEPOSIT", {
          proofUrl: key,
        });
        if (!_.isNull(error)) {
          setError(true);
          await axios.post("/api/clients/contact", {
            fullname: "SDZTRAVEL BUG ALERT",
            email: "aymenzitouni51@gmail.com",
            message: `bug_produced_at : ${moment().toISOString()} // bug_body : ${JSON.stringify(
              error
            )}`,
          });
          setErrorMessage("erreur , veuillez ré-essayer ultérieurement.");
        }
        if (_.isNull(error) && data) {
          nextStep();
        }
      }

      if (key === "error") {
        setError(true);
        setErrorMessage("erreur , veuillez ré-essayer ultérieurement.");
      }
    }
    setLoading(false);
  };

  return (
    <Box>
      <Box bg={"white"} p={4} rounded={"md"} my={3}>
        <Flex justifyContent={"space-between"}>
          <Heading size={"md"}>Informations de paiement</Heading>
          {settings?.url && (
            <a
              href={`${config.S3_URL}/${settings?.url || ""}`}
              target={"_blank"}
            >
              <Text
                textDecoration={"underline"}
                color="red"
                fontWeight={"bold"}
              >
                <FontAwesomeIcon icon={faDownload} />
                Télécharger les coordonnées bancaires
              </Text>
            </a>
          )}
        </Flex>

        <Box>
          <fieldset className="fieldset-style">
            <legend> Nos coordonnées bancaires</legend>
            <Heading px={4} size={"md"}>
              Compte bancaire en DZD :{" "}
            </Heading>
            <UnorderedList px={4} py={2}>
              <ListItem>
                Nom ou Raison social : {settings?.DZD?.socialName}
              </ListItem>
              <ListItem>Code banque : {settings?.DZD?.bankCode}</ListItem>
              <ListItem>N° de compte : {settings?.DZD?.accountNumber}</ListItem>
              <ListItem>Clé RIB : {settings?.DZD?.RIB}</ListItem>
              <ListItem>Domicilation : {settings?.DZD?.domicilation}</ListItem>
            </UnorderedList>
            <Heading px={4} size={"md"}>
              Compte bancaire en EURO :{" "}
            </Heading>
            <UnorderedList px={4} py={2}>
              <ListItem>
                Nom ou Raison social : {settings?.EUR?.socialName}
              </ListItem>
              <ListItem>Code banque : {settings?.EUR?.bankCode}</ListItem>
              <ListItem>N° de compte : {settings?.EUR?.accountNumber}</ListItem>
              <ListItem>Clé RIB : {settings?.EUR?.RIB}</ListItem>
              <ListItem>Domicilation : {settings?.EUR?.domicilation}</ListItem>
            </UnorderedList>
          </fieldset>
        </Box>
        <Box my={4}>
          <Heading size={"sm"}>Uploader reçue de paiement</Heading>
          <FileInput handleDrop={onDrop} uploadedFile={file} />
        </Box>
      </Box>
      {error && (
        <Alert status="error" textAlign={"center"}>
          <AlertIcon /> {errorMessage}
        </Alert>
      )}
      <SimpleGrid columns={[1, 2]} gap={2} my={4}>
        <Button
          onClick={prevStep}
          width={"full"}
          color={"primary"}
          bg={"white"}
        >
          Précédent
        </Button>
        <Button
          width={"full"}
          color={"white"}
          bg={"primary"}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          confirmer votre paiement
        </Button>
      </SimpleGrid>
    </Box>
  );
};

export default DepositPaiement;

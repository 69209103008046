import { Button } from "@chakra-ui/button";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { Box, Center, Heading, Text } from "@chakra-ui/layout";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth";

const ConfirmEmail = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { resendConfirmAccountEmail } = useAuth();

  const handleClick = async () => {
    setLoading(true);
    await resendConfirmAccountEmail();
    setLoading(false);
    setSuccess(true);
  };
  return (
    <Center width={"full"} height={"70vh"}>
      <Box textAlign="center" py={10} px={6}>
        <WarningTwoIcon boxSize={"50px"} color={"orange.300"} />
        <Heading as="h2" size="xl" mt={6} mb={2}>
          Confirm your email in order to be able to apply.
        </Heading>
        <Text color={"gray.500"} px={[4, 80]}>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </Text>
        <Button
          my={4}
          color="white"
          bg="primary"
          w={["full", "auto"]}
          isLoading={isLoading}
          isDisabled={success}
          onClick={handleClick}
        >
          {!success ? (
            "Re-send confrimation email"
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </Button>
      </Box>
    </Center>
  );
};

export default ConfirmEmail;

import React, { useContext, useState } from "react";
// import { v4 as uuidv4 } from "uuid";
import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  GridItem,
  Heading,
  IconButton,
  Image,
  Input,
  SimpleGrid,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "../HomePage/Card";
import EditProfileForm from "../../forms/EditProfileForm";
import ProfileInformations from "./ProfileInformations";
import { MainContext } from "../../../contexts/MainContext";
import UploadFileModal from "../../modals/UploadFileModal";
import { useStorage } from "../../../hooks/useStorage";
import { useAuth } from "../../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraAlt } from "@fortawesome/free-solid-svg-icons";
import config from "../../../config";
import SelectedPrograms from "../../common/SelectedPrograms";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { getCountry } from "../../../helpers/getCountry";

const fileFormats = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
};

const ProfilePage = () => {
  const inputs = [
    {
      component: Input,
      label: "Firstname : ",
      accessor: "firstname",
      type: "text",
      validation: {
        required: true,
      },
      errorMessage: "Please your firstname.",
    },
    {
      component: Input,

      label: "Lastname : ",
      accessor: "lastname",
      type: "text",
      validation: {
        required: true,
      },
      errorMessage: "Please your lastname.",
    },
    {
      label: "Email : ",
      accessor: "email",
      type: "email",
      validation: {
        required: true,
      },
      isDisabled: true,
      errorMessage: "Please your email.",
    },
    {
      component: Input,
      label: "Telephone : ",
      accessor: "phoneNumber",
      type: "number",
      validation: {
        required: true,
      },
      errorMessage: "Please enter your phone number.",
    },
    {
      component: Input,
      label: "Address : ",
      accessor: "address",
      type: "text",
      validation: {
        required: true,
      },
      errorMessage: "Please enter your address.",
    },
    {
      component: ReactDatePicker,
      label: "Né le : ",
      accessor: "birthDate",
      type: "date",
      dateFormat: "DD/MM/YYYY",
      render: (date: string) => moment(date).format("l"),
      validation: {
        required: true,
      },
      errorMessage: "Please enter your birth date.",
    },

    {
      component: Input,
      label: "Code postal : ",
      accessor: "postalCode",
      type: "text",
      validation: {
        required: true,
      },
      errorMessage: "Please enter your postalCode.",
    },
    {
      component: Input,
      label: "Prenom de pere : ",
      accessor: "fatherFirstname",
      type: "text",
      validation: {
        required: true,
      },
      errorMessage: "Please enter your father name.",
    },
    {
      component: Input,
      label: "Nom et prénom de mére : ",
      accessor: "motherFullName",
      type: "text",
      validation: {
        required: true,
      },
      errorMessage: "Please enter your mother full name.",
    },
    {
      component: Input,

      label: "Skype : ",
      accessor: "skype",
      type: "text",
      validation: {
        required: false,
      },
      errorMessage: "Please enter your skype.",
    },
    {
      label: "Pays : ",
      accessor: "country",

      validation: {
        required: false,
      },

      render: (country: string) =>
        `${getCountry(country).flag} - ${getCountry(country).fr}`,
      errorMessage: "Please enter your skype.",
    },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCoverModalOpen,
    onOpen: onCoverModalOpen,
    onClose: onCoverModalClose,
  } = useDisclosure();
  const toast = useToast();
  const { account } = useContext(MainContext);
  const { uploadFile } = useStorage();
  const { updateProfilePictureAndCover } = useAuth();
  const [mode, setMode] = useState<"read" | "edit">("read");
  const toggleMode = () => {
    setMode(mode === "read" ? "edit" : "read");
  };

  const uploadProfilePicture = async (file: File | null) => {
    if (file) {
      const key = await uploadFile(file, "profile-pictures", account.id);
      const { error } = await updateProfilePictureAndCover({ photoUrl: key });
      if (!error) {
        toast({
          title: "your profile picture has been updated successfully.",
          status: "info",
        });
      }
      if (error) {
        toast({
          title: "an error occured, tray agian later.",
          status: "error",
        });
      }
    }
  };

  const uploadCoverPicture = async (file: File | null) => {
    if (file) {
      const key: string = await uploadFile(file, "cover-pictures", account.id);
      const { error } = await updateProfilePictureAndCover({ coverUrl: key });
      if (!error) {
        toast({
          title: "your profile cover has been updated successfully.",
          status: "info",
        });
      }
      if (error) {
        toast({
          title: "an error occured, tray agian later.",
          status: "error",
        });
      }
    }
  };

  return (
    <>
      <Container maxW={"6xl"}>
        <SimpleGrid columns={3} mt={14}>
          <GridItem colSpan={[3, 2]}>
            <Heading size={"lg"}>Profile</Heading>
            <Box position={"relative"} height={"auto"} mt={5} mb={75}>
              <Image
                src={
                  (account?.coverUrl &&
                    `${config.S3_URL}/${account.coverUrl}`) ||
                  "https://via.placeholder.com/150"
                }
                width={"full"}
                height={"150px"}
                objectFit={"cover"}
                rounded={"md"}
              />
              <Flex
                flexDirection={"column"}
                position={"absolute"}
                bottom={-65}
                left={5}
              >
                <Avatar
                  src={
                    (account?.photoUrl &&
                      `${config.S3_URL}/${account.photoUrl}`) ||
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzHQv_th9wq3ivQ1CVk7UZRxhbPq64oQrg5Q&usqp=CAU"
                  }
                  // rounded={"md"}
                  height={"100px"}
                  width={"100px"}
                  objectFit={"cover"}
                />
                <IconButton
                  my={1}
                  bg={"primary"}
                  color={"white"}
                  aria-label="true"
                  size={"sm"}
                  onClick={onOpen}
                  icon={<FontAwesomeIcon icon={faCameraAlt} />}
                />
              </Flex>

              {/* <Image
                position={"absolute"}
                bottom={-65}
                left={5}
                src={
                  account.photoUrl ||
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzHQv_th9wq3ivQ1CVk7UZRxhbPq64oQrg5Q&usqp=CAU"
                }
                rounded={"md"}
                height={"100px"}
                width={"100px"}
                objectFit={"cover"}
              /> */}
              <Button
                position={"absolute"}
                bottom={2}
                right={3}
                onClick={onCoverModalOpen}
              >
                Change cover picture
              </Button>
            </Box>
            <Container maxW={"6xl"} my={4}>
              <Box>
                <Heading size={"md"}>
                  {account.firstname} {account.lastname}
                </Heading>
                <Heading size={"md"}>{account.email}</Heading>
                <Heading size={"sm"} color={"grey"}>
                  Nec vivamus in sem pulvinar sollicitudin.
                </Heading>
                <Button
                  my={2}
                  bg={"primary"}
                  color={"white"}
                  onClick={toggleMode}
                >
                  {mode === "read" && "Edit profile"}
                  {mode === "edit" && "Annuler"}
                </Button>
              </Box>
              <ReactDatePicker
                value={"17/03/2023"}
                dateFormat="DD/MM/YYYY"
                onChange={(date) => {
                  // setStartDate(date);
                }}
              />
              {mode === "edit" && (
                <EditProfileForm
                  inputs={inputs}
                  account={account}
                  toggle={toggleMode}
                />
              )}
              {mode === "read" && (
                <>
                  <ProfileInformations
                    personalInformations={inputs}
                    account={account}
                  />
                </>
              )}
            </Container>
          </GridItem>
          <GridItem display={["none", "block"]} colSpan={[0, 1]}>
            <Container>
              <Heading size={"lg"} my={2}>
                Selected for you
              </Heading>
              <SelectedPrograms />
            </Container>
          </GridItem>
        </SimpleGrid>
      </Container>
      <UploadFileModal
        title="Photo de profile"
        isOpen={isOpen}
        onClose={onClose}
        onUpload={uploadProfilePicture}
        acceptedFormats={fileFormats}
      />
      <UploadFileModal
        title="Photo de couverture"
        isOpen={isCoverModalOpen}
        onClose={onCoverModalClose}
        onUpload={uploadCoverPicture}
        acceptedFormats={fileFormats}
      />
    </>
  );
};

export default ProfilePage;

import { Box } from "@chakra-ui/react";
import React, { createRef, FC, useContext, useEffect, useRef } from "react";
import { ChatContext } from "../../contexts/ChatContext";
import { IMessage } from "../../helpers/types";
import Message from "./Message";

const Messages: FC<{
  messages: IMessage[];
}> = ({ messages }) => {
  const bottomRef: any = useRef();

  const scrollToBottom = () => {};
  useEffect(() => {
    scrollToBottom();
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  return (
    <Box my={3} height={["78vh", "70vh"]} overflowY={"scroll"} p={2}>
      {messages.map(({ content, byAdmin, type, sendAt }) => (
        <>
          <Message
            content={content}
            byAdmin={byAdmin}
            type={type}
            sendAt={sendAt}
          />
          <br />
        </>
      ))}
      <div ref={bottomRef} />
    </Box>
  );
};

export default Messages;

import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useProgramCountries } from "../hooks/useProgramCountries";
import {
  Box,
  Center,
  Container,
  GridItem,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import ListSidebar from "../components/common/ListSidebar";
import config from "../config";
const Country: FC = () => {
  const { code = "" } = useParams();
  const { getCountryByCode } = useProgramCountries();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<any>();
  const getCountry = async () => {
    setLoading(true);
    const { data } = await getCountryByCode(code);
    setCountry(data);
    setLoading(false);
  };

  useEffect(() => {
    getCountry();
  }, []);
  if (isLoading)
    return (
      <Center width={"100vw"} height={"100vh"}>
        <Spinner color="primary" width={30} height={30} size={"lg"} />
      </Center>
    );

  const sidebarList = [
    "Introduction",
    "About",
    "Why this country?",
    "Fees",
    "Visa Process",
  ];

  return (
    <Container maxW={"6xl"} mt={12}>
      <SimpleGrid columns={3} spacing={2}>
        <GridItem colSpan={[0, 1]} display={["none", "block"]}>
          <ListSidebar items={sidebarList} />
        </GridItem>
        <GridItem colSpan={[3, 2]}>
          <Image
            height={260}
            width={"100%"}
            objectFit={"cover"}
            src={
              (country?.pictureUrl &&
                `${config.S3_URL}/${country.pictureUrl}`) ||
              "/default-placeholder.png"
            }
          />
          <Box my={6}>
            <Heading color={"primary"}>Studies in {country?.name}</Heading>
            <Box
              dangerouslySetInnerHTML={{
                __html: country?.introduction || "",
              }}
            />
          </Box>
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              About Canada
            </Heading>
            <Box
              dangerouslySetInnerHTML={{
                __html: country?.about || "",
              }}
            />
          </Box>
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              Why this country?
            </Heading>
            <List spacing={3}>
              <SimpleGrid columns={2}>
                <GridItem colSpan={1}>
                  <ListItem>
                    <ListIcon color="green.500" />
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit
                  </ListItem>
                </GridItem>
                <GridItem colSpan={1}>
                  <ListItem>
                    <ListIcon color="green.500" />
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit
                  </ListItem>
                </GridItem>
              </SimpleGrid>
            </List>
          </Box>
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              Fees
            </Heading>
            <Text>
              Tempus duis dui quam consectetur sit turpis vitae. Nisl volutpat
              non tellus at rhoncu, cursus diam mauris. Elit est velit, metus,
              suspendisse iaculis ultrices proin vulputate augue. Ut id a
              faucibus tempus nisl. At at vitae condimentum cursus. Porta amet,
              dignissim vulputate. Canada boasts 96 universities scattered
              across its urban and regional areas, most of which are very
              welcoming to international students.
            </Text>
          </Box>{" "}
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              Visa process
            </Heading>
            <Text>
              Tempus duis dui quam consectetur sit turpis vitae. Nisl volutpat
              non tellus at rhoncu, cursus diam mauris. Elit est velit, metus,
              suspendisse iaculis ultrices proin vulputate augue. Ut id a
              faucibus tempus nisl. At at vitae condimentum cursus. Porta amet,
              dignissim vulputate. Canada boasts 96 universities scattered
              across its urban and regional areas, most of which are very
              welcoming to international students.
            </Text>
          </Box>
        </GridItem>
      </SimpleGrid>
    </Container>
  );
};

export default Country;

import AdministrativeSupport from "./AdministrativeSupport";
import AdministrativeSupportApplication from "./AdministrativeSupportApplication";
import AdministrativeSupportApplicationDetails from "./AdministrativeSupportApplicationDetails";
import AdministrativeSupportApplications from "./AdministrativeSupportApplications";

const administrativeSupportRoutes = [
  {
    path: "/administrative-support",
    element: AdministrativeSupport,
    provider: null,
  },
  {
    path: "/administrative-support/applications",
    element: AdministrativeSupportApplications,
    isProtected: true,
    provider: null,
  },
  {
    path: "/administrative-support/applications/:id",
    element: AdministrativeSupportApplicationDetails,
    isProtected: true,
    provider: null,
  },
  {
    path: "/administrative-support/applications/apply",
    element: AdministrativeSupportApplication,
    isProtected: true,
    provider: null,
  },
  {
    path: "/administrative-support/applications/apply/:id",
    element: AdministrativeSupportApplication,
    isProtected: true,
    provider: null,
  },
];
export default administrativeSupportRoutes;

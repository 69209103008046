import "@fontsource/poppins";
import React, {
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Box, Center, ChakraProvider, Spinner } from "@chakra-ui/react";
import { HashRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layouts/MainLayout";
import theme from "./theme";
import routes from "./views/rootRoutes";
import { v4 as uuidv4 } from "uuid";
import MainProvider, { MainContext } from "./contexts/MainContext";
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.css";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-253631329-1"; // YOUR_OWN_TRACKING_ID

function App() {
  const { isLoading } = useContext(MainContext);

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // ReactGA.pageview(window.location.pathname);
    ReactGA.pageview("*");
  }, []);

  if (isLoading)
    return (
      <Center width={"100vw"} height={"100vh"}>
        <Spinner color="primary" width={30} height={30} size={"lg"} />
      </Center>
    );

  return (
    <HashRouter>
      <MainLayout>
        <Routes>
          {routes.map(
            ({ path, element, provider = null, isProtected = false }) => {
              const Element = element;
              if (provider) {
                const ContextProvider = provider;
                if (!isProtected)
                  return (
                    <Route
                      path={path}
                      element={
                        // <ContextProvider>
                        <Element />
                        // </ContextProvider>
                      }
                    />
                  );
                if (isProtected)
                  return (
                    <Route
                      path={path}
                      element={
                        <ProtectedRoute>
                          {/* <ContextProvider> */}
                          <Element />
                          {/* </ContextProvider> */}
                        </ProtectedRoute>
                      }
                    />
                  );
              }
              if (isProtected)
                return (
                  <Route
                    key={uuidv4()}
                    path={path}
                    element={
                      <ProtectedRoute>
                        <Element />
                      </ProtectedRoute>
                    }
                  />
                );
              return <Route path={path} element={<Element />} />;
            }
          )}
        </Routes>
      </MainLayout>
    </HashRouter>
  );
}

export default App;

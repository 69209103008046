import React, { FC } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import PersonnalInformations from "../../common/ProfileInformations";
import { inputs } from "../../../data/inputs";
import { ProgramApplicationInputs } from "../../../data/applicationInputs";
const ProfileInformations: FC<{
  personalInformations: {
    label: string;
    accessor: string;
    render?: Function;
  }[];
  account: any;
}> = ({ personalInformations, account }) => {
  return (
    <Box mt={[0, 10]}>
      <Tabs borderColor={"transparent"}>
        <TabList gap={8}>
          <Tab
            _focus={{ borderColor: "transparent" }}
            _selected={{ borderColor: "primary" }}
          >
            Overview
          </Tab>
          {/* <Tab
            _focus={{ borderColor: "transparent" }}
            _selected={{ borderColor: "primary" }}
          >
            Education
          </Tab>
          <Tab
            _focus={{ borderColor: "transparent" }}
            _selected={{ borderColor: "primary" }}
          >
            Languages
          </Tab> */}
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box>
              <Heading size={"base"}>Biographie</Heading>
              <Text fontSize={"md"}>
                {account["bibliography"] || "not setted yet."}
              </Text>
            </Box>
            <Box my={8}>
              <PersonnalInformations
                items={personalInformations}
                displayedData={ProgramApplicationInputs}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <p>two!</p>
          </TabPanel>
          <TabPanel>
            <p>three!</p>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ProfileInformations;

import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Flex, GridItem, SimpleGrid } from "@chakra-ui/react";
import _ from "lodash";
import Select from "react-select";
import { ProgramsContext } from "../../contexts/ProgramsContext";
import getFlagByCode from "../../helpers/getFlagByCode";
import { useEstablishments } from "../../hooks/useEstablishments";
import { useProgramCountries } from "../../hooks/useProgramCountries";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { programsTypes } from "../../helpers/types";

const ProgramsSearchForm = () => {
  const { updateFilter, init, filter } = useContext(ProgramsContext);
  const { getCountries } = useProgramCountries();
  const { getEstablishmentsByCountry } = useEstablishments();
  // OPTIONS LISTS
  const [countries, setCountries] = useState<any>([]);
  const [establishments, setEstablishments] = useState<any>([]);

  // States
  const [country, setCountry] = useState<any>(null);
  const [establishmentType, setEstablishmentType] = useState<any>(null);
  const [establishment, setEstablishment] = useState<any>(null);
  const [programType, setProgramType] = useState<any>(null);

  const [isCountriesLoading, setCountriesLoading] = useState<boolean>(false);
  const [isEstablishmentsLoading, setEstablishmentsLoading] =
    useState<boolean>(false);

  const formatCountriesToOptionsList = (data: any) => {
    let options: any[] = [];
    data.map(
      ({
        code,
        name,
        displayUniversities,
      }: {
        code: string;
        name: string;
        displayUniversities: boolean;
      }) => {
        options.push({
          label: `${getFlagByCode(code)} - ${name}`,
          value: code,
          displayUniversities,
        });
      }
    );
    return options;
  };

  const getCountriesList = async () => {
    setCountriesLoading(false);
    const { data, error } = await getCountries();
    const options = formatCountriesToOptionsList(data);
    setCountries(options);
    setCountriesLoading(true);
  };

  const getEstablishmentsList = async (
    countryCode: string,
    type: "SCHOOL" | "UNIVERSITY"
  ) => {
    setEstablishmentsLoading(false);
    const { data } = await getEstablishmentsByCountry(countryCode, type);
    const options: any = [];
    data.map(({ id, name }: { id: number; name: string }) =>
      options.push({ label: name, value: id })
    );
    setEstablishments(options);
    setEstablishmentsLoading(true);
  };

  const handleChangeCountry = async (e: any) => {
    setCountry(e);

    updateFilter({
      countryCode: e.value,
      establishmentType: "",
      establishmentId: "",
      programType: "",
    });
    setEstablishmentType(null);
    setEstablishment(null);
    setProgramType(null);
  };

  const handleChangeEstablishmentType = async (e: any) => {
    init();
    setEstablishment(null);
    setProgramType(null);
    setEstablishmentType(e);
    updateFilter({
      countryCode: country.value,
      establishmentType: e.value,
      establishmentId: "",
      programType: "",
    });
    await getEstablishmentsList(country.value, e.value);
  };

  const handleChangeEstablishment = async (e: any) => {
    init();
    setProgramType(null);
    setEstablishment(e);
    updateFilter({
      countryCode: country.value,
      establishmentType: establishmentType.value,
      establishmentId: e.value,
      programType: "",
    });
  };

  const handleChangeProgramType = async (e: any) => {
    setProgramType(e);
    updateFilter({
      countryCode: country.value,
      establishmentType: establishmentType.value,
      establishmentId: establishment?.value || "",
      programType: e.value,
    });
  };

  const establishmentsTypes = [
    { label: "Université", value: "UNIVERSITY" },
    { label: "Ecole", value: "SCHOOL" },
  ];

  const inputs = [
    {
      show: true,
      options: countries,
      handleChange: handleChangeCountry,
      placeholder: "Choisir le pays ...",
      value: country,
      isLoading: false,
    },
    {
      show: !_.isNull(country),
      options: establishmentsTypes,
      handleChange: handleChangeEstablishmentType,
      placeholder: "université/école ...",
      value: establishmentType,
      isLoading: false,
    },

    {
      show: !_.isNull(establishmentType) && country.displayUniversities,
      options: establishments,
      handleChange: handleChangeEstablishment,
      placeholder: "Nom d'etablissement",
      value: establishment,
      isLoading: false,
    },
    {
      show:
        !_.isNull(establishment) ||
        (!_.isNull(establishmentType) && !country.displayUniversities),
      options: programsTypes,
      handleChange: handleChangeProgramType,
      placeholder: "Bachelor / Master  ...",
      value: programType,
    },
  ];

  useEffect(() => {
    getCountriesList();
    // initStates();
  }, []);

  return (
    <Box bg={"white"} p={4} rounded={"md"}>
      <SimpleGrid columns={4} spacing={2}>
        {inputs.map(
          ({
            show,
            options,
            handleChange,
            placeholder,
            value,
            isLoading = false,
          }) =>
            show && (
              <GridItem colSpan={[4, 1]} key={uuidv4()}>
                <Select
                  options={options}
                  onChange={handleChange}
                  placeholder={placeholder}
                  value={value}
                  isLoading={isLoading}
                />
              </GridItem>
            )
        )}
      </SimpleGrid>
      <Flex justifyContent={"flex-end"} my={1}>
        <Button
          size={"xs"}
          bg={"primary"}
          color={"white"}
          onClick={() => {
            setCountry(null);
            setEstablishmentType(null);
            setEstablishment(null);
            setProgramType(null);
            updateFilter({
              countryCode: "",
              establishmentType: "",
              establishmentId: "",
              programType: "",
            });
          }}
        >
          clear all
        </Button>
      </Flex>
    </Box>
  );
};

export default ProgramsSearchForm;

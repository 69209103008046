import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Center,
  Container,
  GridItem,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  OrderedList,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useEstablishments } from "../hooks/useEstablishments";
import SidebarList from "../components/common/SidebarList";
import ListSidebar from "../components/common/ListSidebar";
import config from "../config";
const Establishment = () => {
  const { id = "" } = useParams();
  const { getEstablishmentById } = useEstablishments();
  const [establishment, setEstablishment] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const getEstablishment = async () => {
    setLoading(true);
    const { data } = await getEstablishmentById(Number(id));

    setEstablishment(data);
    setLoading(false);
  };
  useEffect(() => {
    getEstablishment();
  }, []);
  if (isLoading)
    return (
      <Center width={"100vw"} height={"100vh"}>
        <Spinner color="primary" width={30} height={30} size={"lg"} />
      </Center>
    );

  const sidebarList = [""];

  return (
    <Container maxW={"6xl"} mt={12}>
      <SimpleGrid columns={3} spacing={2}>
        <GridItem colSpan={[0, 1]} display={["none", "block"]}>
          {/* {country?.name} */}
          <ListSidebar items={sidebarList} />
        </GridItem>
        <GridItem colSpan={[3, 2]}>
          <Image
            height={260}
            objectFit={"cover"}
            width={"100%"}
            src={
              (establishment?.pictureUrl &&
                `${config.S3_URL}/${establishment.pictureUrl}`) ||
              "/default-placeholder.png"
            }
          />
          <Box my={6}>
            <Heading color={"primary"}>{establishment?.name}</Heading>
            <Box
              dangerouslySetInnerHTML={{
                __html: establishment?.description || "",
              }}
            />
          </Box>
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              How to apply
            </Heading>
            <OrderedList m={4}>
              {establishment?.steps?.map((step: string) => (
                <ListItem>{step}</ListItem>
              ))}
            </OrderedList>
          </Box>
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              Accomodation & Transport
            </Heading>
            <Box
              dangerouslySetInnerHTML={{
                __html: establishment?.accomodation || "",
              }}
            />
            <br />
            <Box
              dangerouslySetInnerHTML={{
                __html: establishment?.transport || "",
              }}
            />
          </Box>
        </GridItem>
      </SimpleGrid>
    </Container>
  );
};

export default Establishment;

import React, { useContext, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Alert,
  AlertIcon,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate, Link as ReachLink } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import { useAuth } from "../../hooks/useAuth";
import _ from "lodash";

const ForgetPasswordForm = () => {
  const navigate = useNavigate();
  const { forgetPassword } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const { error } = await forgetPassword(data.email);
    if (!error) {
      setError(false);
      setSuccess(true);

      setLoading(false);
    } else {
      setSuccess(false);

      setError(true);
      setLoading(false);
    }
  };

  const inputs = [
    {
      name: "email",
      type: "text",
      validation: {
        required: true,
      },
      errorMessage: "Please enter a valid email.",
    },
  ];

  return (
    <Box rounded={"lg"} boxShadow={"lg"} p={8}>
      <Stack spacing={2}>
        {error && (
          <Alert status="error">
            <AlertIcon />
            Wrong credentials, please try again.
          </Alert>
        )}
        {success && (
          <Alert status="success">
            <AlertIcon />
            verifier votre boite e-mail.
          </Alert>
        )}
        {!success &&
          inputs.map(({ name, type, validation, errorMessage }) => (
            <FormControl
              id={name}
              isInvalid={!_.isNil(errors[name])}
              isRequired
            >
              <FormLabel>Email address</FormLabel>
              <Input type={type} {...register(name, { ...validation })} />
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </FormControl>
          ))}

        <Stack spacing={6}>
          {!success && (
            <Button
              bg={"primary"}
              color={"white"}
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              envoyer email
            </Button>
          )}
          {success && (
            <Button
              bg={"primary"}
              color={"white"}
              isLoading={isLoading}
              onClick={() => navigate("/sign-in")}
            >
              Retour vers connexion
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ForgetPasswordForm;

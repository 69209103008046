import {
  Box,
  Center,
  GridItem,
  SimpleGrid,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ChatContext } from "../../contexts/ChatContext";
import { IConversation, IMessage, MessageType } from "../../helpers/types";
import ChatBox from "./ChatBox";
import ChatFiles from "./ChatFiles";
import ChatSidebar from "./ChatSidebar";
import "./index.css";

const Chat = () => {
  const {
    conversations,
    selectedConversation = null,
    messages = [],
    isLoading,
  } = useContext(ChatContext);

  const {
    isOpen: isSidebarOpen,
    onToggle: onSidebarToogle,
    onClose: onSidebarClose,
  } = useDisclosure();
  const { isOpen: isFilesbarOpen, onToggle: onFilesbarToogle } =
    useDisclosure();

  return (
    <ChatContext.Consumer>
      {({ isLoading, conversations, selectedConversation }) =>
        isLoading ? (
          <Center width={"100vw"} height={"100vh"}>
            <Spinner color="primary" width={30} height={30} size={"lg"} />
          </Center>
        ) : (
          <Box m={4}>
            <SimpleGrid columns={[1, 4]} gap={2}>
              <GridItem
                display={[
                  isSidebarOpen || _.isNull(selectedConversation)
                    ? "block"
                    : "none",
                  "block",
                ]}
                colSpan={[0, 1]}
                border={"1px"}
                rounded={"base"}
                borderColor={"gray.300"}
              >
                <ChatSidebar onSidebarClose={onSidebarClose} />
              </GridItem>

              {_.isNull(selectedConversation) && (
                <>
                  <GridItem
                    colSpan={[4, 3]}
                    border={"1px"}
                    rounded={"base"}
                    borderColor={"gray.300"}
                    p={2}
                  >
                    <Center height={"full"}>
                      Aucune messagerie est selectionnée.
                    </Center>
                  </GridItem>
                </>
              )}
              {!_.isNull(selectedConversation) && (
                <>
                  <GridItem
                    display={[
                      !isSidebarOpen &&
                      !_.isNull(selectedConversation) &&
                      !isFilesbarOpen
                        ? "block"
                        : "none",
                      "block",
                    ]}
                    colSpan={[4, 2]}
                    border={"1px"}
                    rounded={"base"}
                    borderColor={"gray.300"}
                    p={2}
                  >
                    <ChatBox
                      toggleSidebar={onSidebarToogle}
                      toggleFilesbar={onFilesbarToogle}
                    />
                  </GridItem>
                  <GridItem
                    display={[
                      !isSidebarOpen &&
                      !_.isNull(selectedConversation) &&
                      isFilesbarOpen
                        ? "block"
                        : "none",
                      "block",
                    ]}
                    colSpan={[0, 1]}
                    border={"1px"}
                    rounded={"base"}
                    borderColor={"gray.300"}
                    p={2}
                  >
                    <ChatFiles toggleFilesbar={onFilesbarToogle} />
                  </GridItem>
                </>
              )}
            </SimpleGrid>
          </Box>
        )
      }
    </ChatContext.Consumer>
  );
};

export default Chat;

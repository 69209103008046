import React, { ReactNode } from "react";
import {
  Box,
  Button,
  Container,
  GridItem,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { footerLists } from "../../data/footer";
import FooterList from "./FooterList";
import Brand from "../common/Brand";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <Button
      bg={"white"}
      color={"primary"}
      rounded={"md"}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target={"_blank"}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
      fontSize={"xl"}
      p={0}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </Button>
  );
};
export default function Footer() {
  return (
    <Box bg={"primary"} overflowX={"hidden"} maxWidth={"100vw"}>
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 2, sm: 2, md: 4 }} spacing={8}>
          <Stack spacing={2} justifyContent={"start"}>
            <Box>
              <Brand color="white" />
            </Box>
            <Text color={"white"} fontSize={"sm"} textAlign={"start"}>
              © {new Date().getFullYear()} SDZ Travel. All rights reserved
            </Text>
            <Text color={"white"} fontSize={"sm"} textAlign={"start"}>
              Num de téléphone : +213 540 25 43 52
            </Text>
            <Stack direction={"row"} spacing={6}>
              <SocialButton
                label={"Facebook"}
                href={"https://www.facebook.com/SaidDzTravel"}
              >
                <FaFacebook />
              </SocialButton>
              <SocialButton
                label={"Instagram"}
                href={"https://www.instagram.com/said_dz.travel/"}
              >
                <FaInstagram />
              </SocialButton>
              <SocialButton
                label={"YouTube"}
                href={
                  "https://www.youtube.com/channel/UCtW__UJKieHOb0dp_ZHgHwg"
                }
              >
                <FaYoutube />
              </SocialButton>
            </Stack>
          </Stack>
          {footerLists.map(({ header, items }) => (
            <FooterList key={uuidv4()} header={header} items={items} />
          ))}
          <GridItem colSpan={[2, 1]}>
            <Box
              rounded={"md"}
              overflow={"hidden"}
              dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3205.820967177276!2d3.9590958999999994!3d36.534313399999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128c334d80538c95%3A0x28e45024b3eb20b4!2sSaid-DZ%20&#39;&#39;Travel&#39;&#39;!5e0!3m2!1sen!2sdz!4v1667266616649!5m2!1sen!2sdz" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
                // __html: `<div class="mapouter"><div class="gmap_canvas"><iframe  id="gmap_canvas" src="https://maps.google.com/maps?q=%20Daira%20Boghni,%20Boghni,%20Algeria,%2015003&t=&z=9&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><style>.mapouter{position:relative;text-align:right;height:202px;width:355px;}</style><style>.gmap_canvas {overflow:hidden;background:none!important;height:auto;width:auto;}</style></div></div>`,
              }}
            />
          </GridItem>
        </SimpleGrid>
      </Container>
    </Box>
  );
}

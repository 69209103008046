import React, { FC, useContext } from "react";
import { Box, Button, Flex, IconButton, useDisclosure } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import NavBar from "./NavBar/NavBar";
import Brand from "../common/Brand";
import NavDropDown from "./NavDropDown/NavDropDown";
import { header, authenticatedHeader } from "../../data/header";
import MobileNavBar from "./NavBar/MobileNavBar";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import config from "../../config";
import { useChat } from "../../hooks/useChat";
import { ChatContext } from "../../contexts/ChatContext";
import { IConversation } from "../../helpers/types";
import { ResendTokenBanner } from "../common/ResendTokenBanner";

const Header: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { isAuthenticated, account } = useContext(MainContext);
  const { conversations = [] } = useContext(ChatContext);

  const unreadConversations = conversations.filter(
    ({ readByUser }: IConversation) => readByUser === false
  );

  return (
    <>
      <Box
        px={4}
        bg={"secondary"}
        zIndex={"tooltip"}
        shadow={"base"}
        maxW={"100vw"}
        w={"100vw"}
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <Brand />
          <NavBar
            items={!isAuthenticated ? authenticatedHeader : header}
            unreadConversations={unreadConversations?.length || 0}
          />
          {isAuthenticated && (
            <NavDropDown
              fullname={`${account.firstname} ${account.lastname}`}
              photoUrl={
                (account?.coverUrl && `${config.S3_URL}/${account.photoUrl}`) ||
                null
              }
            />
          )}
          {!isAuthenticated && (
            <Flex display={["none", "block"]}>
              <Button
                mx={2}
                borderColor={"primary"}
                onClick={() => navigate("/sign-in")}
              >
                Connexion
              </Button>
              <Button
                bg={"primary"}
                color={"white"}
                onClick={() => navigate("/sign-up")}
              >
                Inscription
              </Button>
            </Flex>
          )}
        </Flex>
        {isOpen && (
          <MobileNavBar
            items={!isAuthenticated ? authenticatedHeader : header}
            onClose={onClose}
          />
        )}
      </Box>
    </>
  );
};

export default Header;

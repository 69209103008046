import { Box, Container } from "@chakra-ui/react";
import React from "react";
import confidentialityTerms from "../data/confidentialityTerms";

const ConfidentialityTerms = () => {
  return (
    <Container maxW={"5xl"} my={14}>
      <Box
        fontFamily={"Poppins, sans-serif"}
        dangerouslySetInnerHTML={{ __html: confidentialityTerms }}
      />
    </Container>
  );
};

export default ConfidentialityTerms;

import React from "react";
import { Flex, Stack, Heading, Box, Text } from "@chakra-ui/react";
import SignInForm from "../../forms/SignInForm";

const SignInPage = () => {
  return (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        // bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={4} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"} textAlign={"center"}>
              Connectez-vous à votre compte
            </Heading>
          </Stack>
          <SignInForm />
        </Stack>
      </Flex>
    </Flex>
  );
};

export default SignInPage;

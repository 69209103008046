import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  GridItem,
  Heading,
  Image,
  ListItem,
  OrderedList,
  SimpleGrid,
  Spinner,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useVisas } from "../../hooks/useVisas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import SidebarList from "../../components/common/SidebarList";
import Documents from "../../components/common/Documents";
import config from "../../config";

const Visa = () => {
  const navigate = useNavigate();
  const { id = null } = useParams();
  const { getVisaById } = useVisas();
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    const { data, error } = await getVisaById(Number(id));
    if (!error) {
      setData(data);
    } else {
      setError(true);
    }

    setLoading(false);
  };
  const filterDocuments = (documents: any, value: boolean) => {
    const result: any[] = documents.filter(
      ({ toUpload }: { toUpload: boolean }) => toUpload === value
    );
    return result;
  };
  useEffect(() => {
    if (id) {
      getData();
    } else {
      setLoading(false);
    }
  }, []);

  if (isLoading)
    return (
      <Center height={"100vh"}>
        <Spinner size={"xl"} />
      </Center>
    );
  const sidebarList = [
    "About country",
    "Frais",
    "Dossier a fournir ",
    "How to apply",
  ];
  return (
    <Container maxW={"6xl"} mt={12}>
      <SimpleGrid columns={3} spacing={2}>
        <GridItem colSpan={[0, 1]} display={["none", "block"]}>
          <SidebarList items={sidebarList} />
        </GridItem>
        <GridItem colSpan={[3, 2]} position={"relative"} my={2}>
          <Image
            height={260}
            objectFit={"cover"}
            width={"100%"}
            src={
              (data?.pictureUrl && `${config.S3_URL}/${data.pictureUrl}`) ||
              "/default-placeholder.png"
            }
          />
          <Box my={6} color={"primary"}>
            <Heading size={"lg"} color={"primary"}>
              Visa {data.type} vers {data.country.name}
            </Heading>
            <Box
              dangerouslySetInnerHTML={{
                __html: data?.description || "",
              }}
            />
            <SimpleGrid columns={[1, 2]} spacing={2} width={[50, "100%"]}>
              <GridItem>
                <Flex fontSize={"xl"} color={"gray.500"}>
                  <FontAwesomeIcon icon={faLanguage} size={"lg"} />
                  <Text mx={1}>{data.language}</Text>
                </Flex>
              </GridItem>
              <GridItem>
                <Flex fontSize={"xl"} color={"gray.500"}>
                  <FontAwesomeIcon icon={faLanguage} size={"lg"} />
                  <Text mx={1}>{data.type}</Text>
                </Flex>
              </GridItem>
            </SimpleGrid>
          </Box>
          <Box my={6}>
            <Heading color={"primary"} size={"lg"}>
              Nos services
            </Heading>
            <Box
              dangerouslySetInnerHTML={{
                __html: data?.services || "",
              }}
            />
          </Box>
          <Box>
            <Heading size={"lg"} color={"primary"}>
              Frais :
            </Heading>
            <UnorderedList>
              {data.fees.map(
                ({ label, value }: { label: string; value: string }) => (
                  <>
                    <ListItem>
                      {label} : {value}
                    </ListItem>
                  </>
                )
              )}
            </UnorderedList>
          </Box>
          <Box my={6}>
            <Heading size={"lg"} color={"primary"} my={3}>
              Dossier a fournir :
            </Heading>
            <Heading size={"sm"}>Ces documents sont à télécharger</Heading>
            <Documents documents={filterDocuments(data.documents, true)} />

            {/* <Heading size={"sm"} mt={3}>
              Ces documents reste chez toi et ne sont pas à télécharger
            </Heading>
            <Documents documents={filterDocuments(data.documents, false)} /> */}
          </Box>
          <Box>
            <Heading size={"lg"} color={"primary"}>
              How to apply
            </Heading>
            <OrderedList>
              {data.steps.map((step: string) => (
                <ListItem>{step}</ListItem>
              ))}
            </OrderedList>
          </Box>
          <Button
            className="sticky-button"
            bg={"primary"}
            color={"white"}
            w={"full"}
            position={"sticky"}
            bottom={10}
            onClick={() => {
              navigate(`/visas/applications/${id}/apply`);
            }}
          >
            Postuler maintenant
          </Button>
        </GridItem>
      </SimpleGrid>
    </Container>
  );
};

export default Visa;

import React, { FC, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  Input,
  SimpleGrid,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { MainContext } from "../../contexts/MainContext";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { getCountry } from "../../helpers/getCountry";
import _ from "lodash";
import { inputs } from "../../data/inputs";

const EditProfileForm: FC<{
  inputs: any[];
  account: any;
  toggle: () => void;
}> = ({ account, toggle }) => {
  const { updateProfile } = useContext(MainContext);

  const toast = useToast();
  // const [error, setError] = useState<boolean>(false);
  // const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      ...account,
      country: !_.isNull(account?.country)
        ? {
            label: `${getCountry(account.country).flag} - ${
              getCountry(account.country).fr
            }`,
            value: getCountry(account.country).code,
          }
        : null,
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    data.country = data?.country?.value;
    await updateProfile(data);
    toggle();
    toast({
      title: "yout profile has been updated successfully.",
      status: "info",
    });
    setLoading(false);
  };

  // const inputs = [
  //   {
  //     component: Input,
  //     label: "Firstname : ",
  //     accessor: "firstname",
  //     type: "text",
  //     validation: {
  //       required: true,
  //     },
  //     errorMessage: "Please your firstname.",
  //   },
  //   {
  //     component: Input,

  //     label: "Lastname : ",
  //     accessor: "lastname",
  //     type: "text",
  //     validation: {
  //       required: true,
  //     },
  //     errorMessage: "Please your lastname.",
  //   },
  //   {
  //     label: "Email : ",
  //     accessor: "email",
  //     type: "email",
  //     validation: {
  //       required: true,
  //     },
  //     isDisabled: true,
  //     errorMessage: "Please your email.",
  //   },
  //   {
  //     component: Input,
  //     label: "Telephone : ",
  //     accessor: "phoneNumber",
  //     type: "number",
  //     validation: {
  //       required: true,
  //     },
  //     errorMessage: "Please enter your phone number.",
  //   },
  //   {
  //     component: Input,
  //     label: "Address : ",
  //     accessor: "address",
  //     type: "text",
  //     validation: {
  //       required: true,
  //     },
  //     errorMessage: "Please enter your address.",
  //   },
  //   {
  //     component: ReactDatePicker,
  //     locale: "fr",
  //     label: "Né le : ",
  //     accessor: "birthDate",
  //     type: "date",
  //     dateFormat: "DD/MM/YYYY",
  //     validation: {
  //       required: true,
  //     },
  //     handleSetValue: (e: any) => {
  //       return moment(e).format("DD/MM/YYYY");
  //     },
  //     errorMessage: "Please enter your birth date.",
  //     value: (e: any) => moment(e).format("DD/MM/YYYY"),
  //     defaultValue: (e: any) => moment(e).format("DD/MM/YYYY"),
  //     peekNextMonth: true,
  //     showMonthDropdown: true,
  //     showYearDropdown: true,
  //     dropdownMode: "select",
  //   },
  //   {
  //     component: Input,
  //     label: "Code postal : ",
  //     accessor: "postalCode",
  //     type: "text",
  //     validation: {
  //       required: true,
  //     },
  //     errorMessage: "Please enter your postalCode.",
  //   },
  //   {
  //     component: Input,
  //     label: "Prenom de pere : ",
  //     accessor: "fatherFirstname",
  //     type: "text",
  //     validation: {
  //       required: true,
  //     },
  //     errorMessage: "Please enter your father name.",
  //   },
  //   {
  //     component: Input,
  //     label: "Nom et prénom de mére : ",
  //     accessor: "motherFullName",
  //     type: "text",
  //     validation: {
  //       required: true,
  //     },
  //     errorMessage: "Please enter your mother full name.",
  //   },
  //   {
  //     component: Input,

  //     label: "Skype : ",
  //     accessor: "skype",
  //     type: "text",
  //     validation: {
  //       required: false,
  //     },
  //     errorMessage: "Please enter your skype.",
  //   },
  //   {
  //     component: Select,
  //     label: "Pays : ",
  //     accessor: "country",
  //     options: [
  //       {
  //         label: `${getCountry("DZ").flag} - ${getCountry("DZ").fr}`,
  //         value: getCountry("DZ").code,
  //       },
  //       {
  //         label: `${getCountry("TN").flag} - ${getCountry("TN").fr}`,
  //         value: getCountry("TN").code,
  //       },
  //       {
  //         label: `${getCountry("MA").flag} - ${getCountry("MA").fr}`,
  //         value: getCountry("MA").code,
  //       },
  //     ],
  //     validation: {
  //       required: false,
  //     },
  //     onChange: (e: any) => {
  //       setValue("country", e);
  //     },
  //     render: (country: string) =>
  //       `${getCountry(country).flag} - ${getCountry(country).fr}`,
  //     errorMessage: "Please enter your skype.",
  //   },
  // ];

  useEffect(() => {
    console.log(watch("birthDate"));
  }, [watch("birthDate")]);

  return (
    <Box mt={10}>
      <FormControl isInvalid={!_.isNil(errors["bibliography"])} isRequired>
        <FormLabel>
          <Heading size={"md"}>Biographie :</Heading>
        </FormLabel>
        <Textarea {...register("bibliography", { required: true })} rows={6} />
        <FormErrorMessage>{"errorMessage"}</FormErrorMessage>
      </FormControl>
      <Box mt={10}>
        <Heading size={"md"}>Informations personnels :</Heading>
        <SimpleGrid columns={2} spacing={2} mt={2}>
          {inputs.map(
            ({
              component = Input,
              accessor,
              label,
              type,
              handleSetValue = (e: any) => e?.target?.value,
              value = (e: any) => e,
              defaultValue = (e: any) => e,
              validation,
              errorMessage,
              ...rest
            }) => {
              const Component: any = component;

              return (
                <GridItem colSpan={[2, 2, 1]}>
                  <FormControl
                    id={accessor}
                    isInvalid={!_.isNil(errors[accessor])}
                    isRequired
                  >
                    <FormLabel color={"grey"}>{label}</FormLabel>
                    {/* <Input
                      {...register(accessor, { ...validation })}
                      as={Component}
                      value={getValues(accessor)}
                      w={"full"}
                      {...rest}
                    /> */}
                    {/* <Component
                      w={"full"}
                      {...register(String(accessor), { ...validation })}
                      {...rest}
                      value={value(watch(accessor))}
                      defaultValue={value(getValues(accessor))}
                    /> */}
                    <Component
                      bg={"white"}
                      {...register(String(accessor), { ...validation })}
                      {...rest}
                      onChange={(e: any) => {
                        setValue(accessor, handleSetValue(e));
                      }}
                      value={value(watch(accessor))}
                      defaultValue={value(getValues(accessor))}
                    />
                    <FormErrorMessage>{errorMessage}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              );
            }
          )}
        </SimpleGrid>
      </Box>
      <Button
        mt={4}
        bg={"primary"}
        width={"full"}
        color={"white"}
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        Sauvegarder
      </Button>
    </Box>
  );
};

export default EditProfileForm;

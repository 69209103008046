import axios from "axios";
import config from "../config";
const BASE_URL = `${config.API_URL}/administrative-support-applications`;

export function useAdministrativeSupportApplications() {
  const getApplications = async () => {
    const path = `${BASE_URL}`;
    try {
      const {
        data: {
          body: { data = [] },
        },
      } = await axios.get(path);
      return { data: data, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };
  const getApplicationByClient = async () => {
    const path = `${BASE_URL}/client/`;
    try {
      const {
        data: { body },
      } = await axios.get(path);
      return { data: body, error: null };
    } catch (error) {
      return {
        error,
        data: null,
      };
    }
  };
  const createApplication = async () => {
    const path = `${BASE_URL}`;
    try {
      const {
        data: { body },
      } = await axios.post(path);
      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };
  const getApplicationById = async (id: number) => {
    const path = `${BASE_URL}/${id}`;
    console.log("@path", path);

    try {
      const {
        data: { body },
      } = await axios.get(path);
      console.log("@body", body);

      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };

  const submitApplicationWithPaiement = async (
    id: number,
    type: "CASH" | "DEPOSIT" | "ON_LINE",
    temp: { proofUrl?: string; bookedDate?: string }
  ) => {
    const pathSuffix = {
      CASH: "cash",
      DEPOSIT: "deposit",
      ON_LINE: "online",
    };
    const path = `${BASE_URL}/${id}/paiement/${pathSuffix[type]}`;
    console.log("@path", path);

    try {
      const {
        data: { body },
      } = await axios.put(path, { ...temp, amount: 10000 });

      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };

  const deleteApplicationById = async (id: number) => {
    const path = `${BASE_URL}/${id}`;
    try {
      const {
        data: { body },
      } = await axios.delete(path);
      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };

  const getPaiementIntent = async (id: number) => {
    const path = `${BASE_URL}/${id}/paiement/online/intent`;
    try {
      const {
        data: { body },
      } = await axios.get(path);

      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };

  return {
    getApplications,
    getApplicationByClient,
    createApplication,
    getApplicationById,
    submitApplicationWithPaiement,
    deleteApplicationById,
    getPaiementIntent,
  };
}

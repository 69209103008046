import { Box, Heading, Radio, Text } from "@chakra-ui/react";
import React, { FC } from "react";

const RadioCard: FC<{
  value: "DEPOSIT" | "CASH" | "ON_LINE";
  title: string;
  description?: string;
}> = ({ value, title, description }) => {
  return (
    <Box bg={"gray.200"} my={2}>
      <Radio value={value} size={"lg"} p={4} bg={"white"}>
        <Heading size={"md"}>{title}</Heading>
        <Text fontSize={"sm"}>{description}</Text>
      </Radio>
    </Box>
  );
};

export default RadioCard;

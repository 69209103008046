import React, { useContext, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Alert,
  AlertIcon,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate, Link as ReachLink, useParams } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import { useAuth } from "../../hooks/useAuth";
import _ from "lodash";

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const { token = "" } = useParams();
  const { resetPassword } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const [error, setErrorStatus] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const { password, passwordConfirmation } = data;
    if (password !== passwordConfirmation) {
      setLoading(false);
      setError("passwordConfirmation", {
        message: "password & password-confirmation should be the same",
      });
      return;
    }
    const { error } = await resetPassword(
      token,
      password,
      passwordConfirmation
    );

    if (!error) {
      //   navigate("/");
      setSuccess(true);
      setLoading(false);
    } else {
      setErrorStatus(true);
      setLoading(false);
    }
  };

  const inputs = [
    {
      label: "Mot de passe",
      name: "password",
      type: "password",
      validation: {
        required: true,
        minLength: 8,
      },
      errorMessage: "Please enter a valid password (8 characters at least).",
    },
    {
      label: "Confirmation du mot de passe",

      name: "passwordConfirmation",
      type: "password",
      validation: {
        required: true,
        minLength: 8,
      },
      errorMessage: "password & password-confirmation should be the same",
    },
  ];

  return (
    <Box rounded={"lg"} boxShadow={"lg"} p={8}>
      <Stack spacing={2}>
        {!success && error && (
          <Alert status="error">
            <AlertIcon />
            Verifier votre mot de pass.
          </Alert>
        )}
        {success && (
          <Alert status="success">
            <AlertIcon />
            Mot de passe modifié avec succée.
          </Alert>
        )}
        {!success &&
          inputs.map(({ label, name, type, validation, errorMessage }) => (
            <FormControl
              id={name}
              isInvalid={!_.isNil(errors[name])}
              isRequired
            >
              <FormLabel>{label} :</FormLabel>
              <Input type={type} {...register(name, { ...validation })} />
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </FormControl>
          ))}

        <Stack spacing={6}>
          {!success && (
            <Button
              bg={"primary"}
              color={"white"}
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              confirmer
            </Button>
          )}
          {success && (
            <Button
              bg={"primary"}
              color={"white"}
              isLoading={isLoading}
              onClick={() => navigate("/sign-in")}
            >
              Retour vers connexion.
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ResetPasswordForm;

import { Box, Heading, Text, Button, Center } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Center width={"full"} height={"70vh"}>
      <Box textAlign="center" py={10} px={6}>
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, secondary-2, primary)"
          backgroundClip="text"
        >
          404
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          Page Not Found
        </Text>
        <Text color={"gray.500"} mb={6}>
          The page you're looking for does not seem to exist
        </Text>

        <Button
          colorScheme="primary"
          bgGradient="linear(to-r, secondary-2, primary)"
          color="white"
          variant="solid"
          onClick={() => navigate("/")}
        >
          Go to Home
        </Button>
      </Box>
    </Center>
  );
};

export default PageNotFound;

import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Box,
  Center,
  Container,
  Heading,
  Spinner,
} from "@chakra-ui/react";

import { v4 as uuidv4 } from "uuid";

import moment from "moment";
import { MainContext } from "../../contexts/MainContext";
import StatusBadge from "../../components/common/StatusBadge";
import ApplicationItem from "../../components/ApplicationItem/ApplicationItem";
import config from "../../config";
import { useVisaApplications } from "../../hooks/useVisaApplications";
import PaiementStatus from "../../components/common/PaiementStatus";
import _ from "lodash";

const VisaApplicationsPage = () => {
  const { getApplications, deleteApplicationById } = useVisaApplications();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  const getData = async () => {
    setLoading(true);
    const { data = [] } = await getApplications();
    setData(data);

    setLoading(false);
  };

  const getItems = (data: any) => {
    return [
      { label: `Visa ${data.countryCode}`, accessor: data.type },
      { label: "Date", accessor: moment(data.date).format("L") },

      { label: "Status", accessor: () => <StatusBadge status={data.status} /> },
      {
        label: "Paiement",
        accessor: () => (
          <PaiementStatus status={data?.paiement?.status || "NOT_PAIED"} />
        ),
      },
    ];
  };

  const handleDelete = async (id: number) => {
    const { error = null } = await deleteApplicationById(id);
    if (error) return { error };
    await getData();
    return { error };
  };
  useEffect(() => {
    getData();
  }, []);

  if (isLoading)
    return (
      <Center width={"100vw"} height={"100vh"}>
        <Spinner color="primary" width={30} height={30} size={"lg"} />
      </Center>
    );

  return (
    <Container maxW="container.lg" mt={10} py={10}>
      <Heading>Mes candidatures visa</Heading>
      <Box my={10}>
        {data?.map(
          ({
            paiement,
            visa: {
              id: visaId,
              type,
              language,
              countryCode,
              pictureUrl = null,
            },
            createdAt,
            status,
            id,
          }: any) => (
            <Box key={uuidv4()}>
              <ApplicationItem
                isDeleteEnabled={
                  _.isNull(paiement) || paiement?.status !== "ACCEPTED"
                }
                onDelete={async () => await handleDelete(Number(id))}
                items={getItems({
                  university: "University of Pradua",
                  countryCode,
                  type,
                  language,
                  date: moment(createdAt).format("DD-MM-YYYY"),
                  paiement,
                  status,
                })}
                imageUrl={
                  (pictureUrl && `${config.S3_URL}/${pictureUrl}`) ||
                  "/default-placeholder.png"
                }
                buttonText={
                  status === "PENDING" || _.isNull(paiement)
                    ? "Continuer"
                    : "Consulter"
                }
                path={
                  status === "PENDING" || _.isNull(paiement)
                    ? `/visas/applications/${visaId}/apply/${id}`
                    : `/visas/applications/${id}`
                }
              />
            </Box>
          )
        )}
      </Box>

      {/* 
            <CardItem
            heading="Lorem ipsum dolor sit amet."
            subHeading=""
            description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam maiores corporis quos nulla quibusdam mollitia asperiores ratione, sint repudiandae molestiae id dolore voluptates nemo unde. Repudiandae animi officiis explicabo tenetur."
            tags={["tag", "tag", "tag"]}
            price="30000 DA"
              />
           */}
    </Container>
  );
};

export default VisaApplicationsPage;

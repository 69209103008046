import React, { FC } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Heading,
  useColorModeValue,
  Center,
} from "@chakra-ui/react";
import { UpdatePasswordForm } from "../../forms/UpdatePasswordForm";

const UpdatePassword: FC = () => {
  // Chakra color mode
  const titleColor = useColorModeValue("blue.700", "teal.200");

  return (
    <Center>
      <Flex
        alignItems="center"
        justifyContent="start"
        style={{ userSelect: "none" }}
        w={{ base: "100%", md: "50%", lg: "42%" }}
      >
        <Flex
          direction="column"
          w="100%"
          background="transparent"
          p="48px"
          mt={{ md: "150px", lg: "80px" }}
        >
          <Heading
            color={titleColor}
            fontSize="32px"
            mb="10px"
            textAlign={"center"}
          >
            Modifier votre mot de passe
          </Heading>
          <UpdatePasswordForm />
        </Flex>
      </Flex>
    </Center>
  );
};

export default UpdatePassword;

import React from "react";
import {
  Flex,
  Stack,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import SignUpForm from "../../forms/SignUpForm";

const SignUpPage = () => {
  return (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"} textAlign={"center"}>
              S'inscrire
            </Heading>
          </Stack>
          <SignUpForm />
        </Stack>
      </Flex>
    </Flex>
  );
};

export default SignUpPage;

import React, { FC, useContext } from "react";
import {
  Avatar,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { MainContext } from "../../../contexts/MainContext";
import { useNavigate } from "react-router-dom";

const NavDropDown: FC<{ fullname: string; photoUrl: string | null }> = ({
  fullname,
  photoUrl,
}) => {
  const navigate = useNavigate();
  const { signOut } = useContext(MainContext);

  const handleSignOut = async () => {
    await signOut();
    navigate("/");
  };
  return (
    <Flex>
      <Menu>
        <MenuButton
          as={Button}
          variant={"link"}
          cursor={"pointer"}
          minW={0}
          _focus={{ outline: "none" }}
        >
          <HStack>
            <Text
              px={2}
              color="black"
              _hover={{ textDecoration: "none" }}
              display={["none", "block", "block"]}
            >
              {fullname}
            </Text>
            <Avatar size={"sm"} src={photoUrl || undefined} />
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => navigate("/update-password")}>
            Mot de passe
          </MenuItem>
          <MenuItem onClick={() => navigate("/me")}>Mon profile</MenuItem>
          <MenuDivider />
          <MenuItem onClick={handleSignOut}>sign-out</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default NavDropDown;

import React from "react";
import { usePrograms } from "../hooks/usePrograms";

export const ProgramsContext = React.createContext<any | null>(null);
const ProgramsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data, filter, isLoading, error, init, updateFilter } = usePrograms();
  return (
    <ProgramsContext.Provider
      value={{ data, filter, isLoading, error, init, updateFilter }}
    >
      {children}
    </ProgramsContext.Provider>
  );
};

export default ProgramsProvider;

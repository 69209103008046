import { ComponentStyleConfig, extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";

const Button: ComponentStyleConfig = {
  baseStyle: { _focus: { outline: "none" } },
};
const theme = extendTheme({
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
  },
  breakpoints: {
    sm: "45em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
  },
  fontSizes: {
    xs: "0.6rem",
    sm: "0.8rem",
    md: "0.875rem",
    lg: "1rem",
    xl: "1.125rem",
    "2xl": "1.25rem",
    "3xl": "1.5rem",
    "4xl": "1.875rem",
    "5xl": "2.25rem",
    "6xl": "3rem",
    "7xl": "3.75rem",
    "8xl": "4.5rem",
    "9xl": "6rem",
  },

  colors: {
    primary: "#181E4B",
    secondary: "#EEF9FF",
    "secondary-2": "#0C76FF",
    tertiary: "#EB5757",
  },
  components: {
    Steps,
  },

  // components: {
  //   Input: {
  //     variants: { _focus: { outlineColor: "primary" } },
  //     baseStyle: { _focus: { outlineColor: "primary" } },
  //     _focus: { outlineColor: "primary" },
  //   },
  //   Button: {
  //     variants: { _focus: { outlineColor: "primary" } },
  //     baseStyle: { _focus: { outlineColor: "primary" } },
  //     _focus: { outlineColor: "primary" },
  //   },
  // },
});

export default theme;

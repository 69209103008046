import React, { FC } from "react";
import { Badge } from "@chakra-ui/react";

const StatusBadge: FC<{
  status: "PENDING" | "SUBMITTED" | "ACCEPTED" | "REFUSED";
}> = ({ status }) => {
  const colors = {
    ACCEPTED: "green.200",
    PENDING: "yellow.200",
    REFUSED: "red.200",
    SUBMITTED: "blue.200",
  };
  return (
    <Badge bg={colors[status]} fontSize={"xs"}>
      {status}
    </Badge>
  );
};

export default StatusBadge;

import { Alert, Box, Divider } from "@chakra-ui/react";
import React, {
  createRef,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ChatContext } from "../../contexts/ChatContext";
import { IMessage, MessageType } from "../../helpers/types";
import ChatHeader from "./ChatHeader";
import ChatInput from "./ChatInput";
import Messages from "./Messages";

const ChatBox: FC<{
  toggleSidebar: Function;
  toggleFilesbar: Function;
}> = ({ toggleSidebar, toggleFilesbar }) => {
  return (
    <ChatContext.Consumer>
      {({ selectedConversation }) => (
        <Box width={"full"} height={["83vh", "auto"]}>
          <ChatHeader
            // photoUrl={conversations[Number(index)].photoUrl || "no name"}
            // title={conversations[Number(index)].title || ""}
            toggleSidebar={toggleSidebar}
            toggleFilesbar={toggleFilesbar}
          />
          <Divider />

          <Messages messages={selectedConversation.messages} />
          <Divider />
          {selectedConversation.status === "ACTIF" && <ChatInput />}
          {selectedConversation.status === "CLOSED" && (
            <Alert textAlign={"center"} status="error" rounded={"md"}>
              Cette messagerie a été desactivé.
            </Alert>
          )}
        </Box>
      )}
    </ChatContext.Consumer>
  );
};

export default ChatBox;

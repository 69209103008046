import React, { HTMLAttributes, forwardRef } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
// import { useColorMode } from "@chakra-ui/react";
import { InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";

import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import moment from "moment";

const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
  <Input
    autoComplete="off"
    background="white"
    value={value}
    ref={ref}
    onClick={onClick}
    onChange={onChange}
  />
);
customDateInput.displayName = "DateInput";

const CustomInput = forwardRef(customDateInput);

const icon = <CalendarIcon fontSize="sm" />;

interface Props {
  excludedDays: number[];
  isClearable?: boolean;
  onChange: (date: Date) => any;
  selectedDate: Date | undefined;
  showPopperArrow?: boolean;
}

const ReactDatePickerComponent = ({
  selectedDate,
  onChange,
  isClearable = false,
  showPopperArrow = false,
  ...props
}: Props & HTMLAttributes<HTMLElement>) => {
  // const isLight = useColorMode().colorMode === "light";
  const isWeekday = (date: any) => {
    const day = Number(moment(date).format("d"));
    return day !== 1 && day !== 6;
  };
  return (
    <>
      <InputGroup>
        <ReactDatePicker
          selected={selectedDate}
          onChange={onChange}
          isClearable={isClearable}
          showPopperArrow={showPopperArrow}
          className="react-datapicker__input-text"
          dateFormat="MM/dd/yyyy"
          customInput={<CustomInput />}
          filterDate={isWeekday}
          placeholderText="Click to select a date"
        />
        <InputRightElement color="gray.500" children={icon} />
      </InputGroup>
    </>
  );
};

const DatePicker = ({
  excludedDays,
  selectedDate,
  onChange,
  ...props
}: Props) => {
  const isWeekday = (date: any) => {
    const day = Number(moment(date).format("d"));
    return excludedDays.indexOf(day) === -1;
  };
  // const days = [""]
  // const locale = {
  //   localize: {
  //     day: (n) => days[n],
  //     month: (n) => months[n],
  //   },
  //   formatLong: {
  //     date: () => "mm/dd/yyyy",
  //   },
  // };
  return (
    <>
      <InputGroup className="dark-theme">
        <ReactDatePicker
          // locale={locale}
          selected={selectedDate}
          onChange={onChange}
          className="react-datapicker__input-text"
          customInput={<CustomInput />}
          minDate={new Date()}
          dateFormat="dd/MM/yyyy"
          filterDate={isWeekday}
          placeholderText="Click to select a date"
          {...props}
        />
        <InputRightElement color="gray.500" children={icon} />
        aa
      </InputGroup>
    </>
  );
};

// set className to "light-theme-original"
{
  /* <div className={isLight ? "light-theme" : "dark-theme"}>
<ReactDatePicker
  selected={selectedDate}
  onChange={onChange}
  isClearable={isClearable}
  showPopperArrow={showPopperArrow}
  className="react-datapicker__input-text"
  dateFormat="MM/dd/yyyy"
  customInput={<CustomInput />}
/>
</div> */
}

export default DatePicker;

import React from "react";
import { Flex, Stack, Heading } from "@chakra-ui/react";
import SignInForm from "../../forms/SignInForm";
import ForgetPasswordForm from "../../forms/ForgetPasswordForm";

const ForgetPasswordPage = () => {
  return (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        // bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={4} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Mot de passe oublié ?</Heading>
          </Stack>
          <ForgetPasswordForm />
        </Stack>
      </Flex>
    </Flex>
  );
};

export default ForgetPasswordPage;

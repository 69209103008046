import React from "react";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <Center height={"100vh"} bg={"secondary"}>
      <Container maxW={"5xl"}>
        <Flex flexDirection={["column-reverse", "row"]}>
          <Box p={2} textAlign={["center", "start"]}>
            <Heading fontSize={["4xl", "6xl"]}>
              Trouvez votre programme d'études et postulez via SDZ TRAVEL
            </Heading>
            <Text my={6}>
              SDZ TRAVEL est une plateforme qui permet aux étudiants de
              rechercher, de trouver et de postuler aux écoles et universités à
              travers le monde.
            </Text>
            <Button
              bg={"primary"}
              color={"white"}
              onClick={() => navigate("/programs")}
              size={"lg"}
            >
              Trouver mon programme
            </Button>
          </Box>
          <Image
            alt={"Hero Image"}
            fit={"cover"}
            align={"center"}
            roundedTopLeft={["3xl", 80]}
            roundedBottomRight={["3xl", 80]}
            roundedTopRight={"3xl"}
            roundedBottomLeft={"3xl"}
            w={["100%", "100%", "50%"]}
            h={"100%"}
            src={"/hero.png"}
          />
        </Flex>
      </Container>
    </Center>
  );
};

export default Hero;

import { Box, Divider, Flex, Heading, IconButton } from "@chakra-ui/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useContext } from "react";
import { ChatContext } from "../../contexts/ChatContext";
import shortenString from "../../helpers/shortenString";
import FileItem from "../common/FileItem";

const ChatFiles: FC<{
  toggleFilesbar: Function;
}> = ({ toggleFilesbar }) => {
  const {
    selectedConversation: { messages },
  } = useContext(ChatContext);
  let files = messages.filter((message: any) => message.type === "FILE");

  return (
    <Box>
      <Flex alignItems={"center"}>
        <IconButton
          display={["block", "none"]}
          justifySelf={"end"}
          size={"sm"}
          bg={"gray.200"}
          color={"black"}
          aria-label="true"
          onClick={() => toggleFilesbar()}
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          mx={1}
        />
        <Heading size={"md"}>Fichier & Médias </Heading>
      </Flex>
      <Divider />
      <Box my={3} height={["90vh", "70vh"]} overflowY={"scroll"} p={2}>
        {files &&
          files.length > 0 &&
          files.map(({ content }: any) => (
            <>
              <FileItem
                id={1}
                isEditable={false}
                isRequired={true}
                description={""}
                name={shortenString(content.replace("chats/", ""), 20)}
                currentDocumentUrl={content}
              />
              <Divider />
            </>
          ))}
      </Box>
    </Box>
  );
};

export default ChatFiles;

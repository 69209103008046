import { Badge } from "@chakra-ui/react";
import React, { FC } from "react";

const ApplicationStatus: FC<{
  status: "PENDING" | "SUBMITTED" | "ACCEPTED" | "REFUSED";
}> = ({ status }) => {
  const color = {
    PENDING: "yellow",
    SUBMITTED: "blue",
    ACCEPTED: "green",
    REFUSED: "red",
  };

  const text = {
    PENDING: "pending",
    SUBMITTED: "submitted",
    ACCEPTED: "accepted",
    REFUSED: "refused",
  };
  return (
    <Badge colorScheme={color[status]} fontSize={"md"} p={1} rounded={"md"}>
      {text[status]}
    </Badge>
  );
};

export default ApplicationStatus;

import React from "react";
import { Box, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { INavItem } from "../../helpers/types";
import FooterListHeader from "./FooterListHeader";

const FooterList = ({
  items,
  header,
}: {
  items: INavItem[];
  header: string;
}) => (
  <Stack align={"flex-start"}>
    <FooterListHeader>{header}</FooterListHeader>
    {items.map(({ link, content }) => (
      <Box as={Link} color={"white"} to={link || ""}>
        {content}
      </Box>
    ))}
  </Stack>
);

export default FooterList;

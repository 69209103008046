export const flags = {
  AF: {
    code: "AF",
    dial_code: "+93",
    en: "Afghanistan",
    ar: "أفغانستان",
    fr: "Afghanistan ",
    flag: "🇦🇫",
  },
  AL: {
    code: "AL",
    dial_code: "+355",
    en: "Albania",
    ar: "ألبانيا",
    fr: "Albanie ",
    flag: "🇦🇱",
  },
  DZ: {
    code: "DZ",
    dial_code: "+213",
    en: "Algeria",
    ar: "الجزائر",
    fr: "Algérie ",
    flag: "🇩🇿",
  },
  AD: {
    code: "AD",
    dial_code: "+376",
    en: "Andorra",
    ar: "أندورا",
    fr: "Andorre ",
    flag: "🇦🇩",
  },
  AO: {
    code: "AO",
    dial_code: "+244",
    en: "Angola",
    ar: "أنغولا",
    fr: "Angola ",
    flag: "🇦🇴",
  },
  AG: {
    code: "AG",
    dial_code: "+1268",
    en: "Antigua and Barbuda",
    ar: "أنتيغوا وبربودا",
    fr: "Antigua-et-Barbuda",
    flag: "🇦🇬",
  },
  AR: {
    code: "AR",
    dial_code: "+54",
    en: "Argentina",
    ar: "الأرجنتين",
    fr: "Argentine ",
    flag: "🇦🇷",
  },
  AM: {
    code: "AM",
    dial_code: "+374",
    en: "Armenia",
    ar: "أرمينيا",
    fr: "Arménie ",
    flag: "🇦🇲",
  },
  AU: {
    code: "AU",
    dial_code: "+61",
    en: "Australia",
    ar: "أستراليا",
    fr: "Australie ",
    flag: "🇦🇺",
  },
  AT: {
    code: "AT",
    dial_code: "+43",
    en: "Austria",
    ar: "النمسا",
    fr: "Autriche ",
    flag: "🇦🇹",
  },
  AZ: {
    code: "AZ",
    dial_code: "+994",
    en: "Azerbaijan",
    ar: "أذربيجان",
    fr: "Azerbaïdjan ",
    flag: "🇦🇿",
  },
  BH: {
    code: "BH",
    dial_code: "+973",
    en: "Bahrain",
    ar: "البحرين",
    fr: "Bahreïn",
    flag: "🇧🇭",
  },
  BD: {
    code: "BD",
    dial_code: "+880",
    en: "Bangladesh",
    ar: "بنغلاديش",
    fr: "Bangladesh ",
    flag: "🇧🇩",
  },
  BB: {
    code: "BB",
    dial_code: "+1246",
    en: "Barbados",
    ar: "بربادوس",
    fr: "Barbade ",
    flag: "🇧🇧",
  },
  BY: {
    code: "BY",
    dial_code: "+375",
    en: "Belarus",
    ar: "بيلاروس",
    fr: "Bélarus ",
    flag: "🇧🇾",
  },
  BE: {
    code: "BE",
    dial_code: "+32",
    en: "Belgium",
    ar: "بلجيكا",
    fr: "Belgique ",
    flag: "🇧🇪",
  },
  BZ: {
    code: "BZ",
    dial_code: "+501",
    en: "Belize",
    ar: "بليز",
    fr: "Belize ",
    flag: "🇧🇿",
  },
  BJ: {
    code: "BJ",
    dial_code: "+229",
    en: "Benin",
    ar: "بنن",
    fr: "Bénin ",
    flag: "🇧🇯",
  },
  BT: {
    code: "BT",
    dial_code: "+975",
    en: "Bhutan",
    ar: "بوتان",
    fr: "Bhoutan ",
    flag: "🇧🇹",
  },
  BA: {
    code: "BA",
    dial_code: "+387",
    en: "Bosnia and Herzegovina",
    ar: "البوسنة والهرسك",
    fr: "Bosnie-Herzégovine ",
    flag: "🇧🇦",
  },
  BW: {
    code: "BW",
    dial_code: "+267",
    en: "Botswana",
    ar: "بوتسوانا",
    fr: "Botswana ",
    flag: "🇧🇼",
  },
  BR: {
    code: "BR",
    dial_code: "+55",
    en: "Brazil",
    ar: "البرازيل",
    fr: "Brésil ",
    flag: "🇧🇷",
  },
  BN: {
    code: "BN",
    dial_code: "+673",
    en: "Brunei Darussalam",
    ar: "بروني دار السلام",
    fr: "Brunéi Darussalam ",
    flag: "🇧🇳",
  },
  BG: {
    code: "BG",
    dial_code: "+359",
    en: "Bulgaria",
    ar: "بلغاريا",
    fr: "Bulgarie ",
    flag: "🇧🇬",
  },
  BF: {
    code: "BF",
    dial_code: "+226",
    en: "Burkina Faso",
    ar: "بوركينا فاسو",
    fr: "Burkina Faso ",
    flag: "🇧🇫",
  },
  BI: {
    code: "BI",
    dial_code: "+257",
    en: "Burundi",
    ar: "بوروندي",
    fr: "Burundi ",
    flag: "🇧🇮",
  },
  KH: {
    code: "KH",
    dial_code: "+855",
    en: "Cambodia",
    ar: "كمبوديا",
    fr: "Cambodge ",
    flag: "🇰🇭",
  },
  CM: {
    code: "CM",
    dial_code: "+237",
    en: "Cameroon",
    ar: "الكاميرون",
    fr: "Cameroun ",
    flag: "🇨🇲",
  },
  CA: {
    code: "CA",
    dial_code: "+1",
    en: "Canada",
    ar: "كندا",
    fr: "Canada ",
    flag: "🇨🇦",
  },
  TD: {
    code: "TD",
    dial_code: "+235",
    en: "Chad",
    ar: "تشاد",
    fr: "Tchad ",
    flag: "🇹🇩",
  },
  CL: {
    code: "CL",
    dial_code: "+56",
    en: "Chile",
    ar: "شيلي",
    fr: "Chili ",
    flag: "🇨🇱",
  },
  CN: {
    code: "CN",
    dial_code: "+86",
    en: "China",
    ar: "الصين",
    fr: "Chine ",
    flag: "🇨🇳",
  },
  CO: {
    code: "CO",
    dial_code: "+57",
    en: "Colombia",
    ar: "كولومبيا",
    fr: "Colombie ",
    flag: "🇨🇴",
  },
  CR: {
    code: "CR",
    dial_code: "+506",
    en: "Costa Rica",
    ar: "كوستاريكا",
    fr: "Costa Rica ",
    flag: "🇨🇷",
  },
  HR: {
    code: "HR",
    dial_code: "+385",
    en: "Croatia",
    ar: "كرواتيا",
    fr: "Croatie ",
    flag: "🇭🇷",
  },
  CU: {
    code: "CU",
    dial_code: "+53",
    en: "Cuba",
    ar: "كوبا",
    fr: "Cuba",
    flag: "🇨🇺",
  },
  CY: {
    code: "CY",
    dial_code: "+357",
    en: "Cyprus",
    ar: "قبرص",
    fr: "Chypre",
    flag: "🇨🇾",
  },
  CZ: {
    code: "CZ",
    dial_code: "+420",
    en: "Czech Republic",
    ar: "الجمهورية التشيكية",
    fr: "République Tchèque",
    flag: "🇨🇿",
  },
  DK: {
    code: "DK",
    dial_code: "+45",
    en: "Denmark",
    ar: "الدانمرك",
    fr: "Danemark ",
    flag: "🇩🇰",
  },
  DJ: {
    code: "DJ",
    dial_code: "+253",
    en: "Djibouti",
    ar: "جيبوتي",
    fr: "Djibouti",
    flag: "🇩🇯",
  },
  DM: {
    code: "DM",
    dial_code: "+1767",
    en: "Dominica",
    ar: "دومينيكا",
    fr: "Dominique ",
    flag: "🇩🇲",
  },
  EC: {
    code: "EC",
    dial_code: "+593",
    en: "Ecuador",
    ar: "إكوادور",
    fr: "Équateur ",
    flag: "🇪🇨",
  },
  EG: {
    code: "EG",
    dial_code: "+20",
    en: "Egypt",
    ar: "مصر",
    fr: "Égypte ",
    flag: "🇪🇬",
  },
  SV: {
    code: "SV",
    dial_code: "+503",
    en: "El Salvador",
    ar: "السلفادور",
    fr: "El Salvador",
    flag: "🇸🇻",
  },
  GQ: {
    code: "GQ",
    dial_code: "+240",
    en: "Equatorial Guinea",
    ar: "غينيا الاستوائية",
    fr: "Guinée équatoriale ",
    flag: "🇬🇶",
  },
  ER: {
    code: "ER",
    dial_code: "+291",
    en: "Eritrea",
    ar: "إريتريا",
    fr: "Érythrée ",
    flag: "🇪🇷",
  },
  EE: {
    code: "EE",
    dial_code: "+372",
    en: "Estonia",
    ar: "إستونيا",
    fr: "Estonie ",
    flag: "🇪🇪",
  },
  ET: {
    code: "ET",
    dial_code: "+251",
    en: "Ethiopia",
    ar: "إثيوبيا",
    fr: "Éthiopie ",
    flag: "🇪🇹",
  },
  FJ: {
    code: "FJ",
    dial_code: "+679",
    en: "Fiji",
    ar: "فيجي",
    fr: "Fidji ",
    flag: "🇫🇯",
  },
  FI: {
    code: "FI",
    dial_code: "+358",
    en: "Finland",
    ar: "فنلندا",
    fr: "Finlande ",
    flag: "🇫🇮",
  },
  FR: {
    code: "FR",
    dial_code: "+33",
    en: "France",
    ar: "فرنسا",
    fr: "France ",
    flag: "🇫🇷",
  },
  GA: {
    code: "GA",
    dial_code: "+241",
    en: "Gabon",
    ar: "غابون",
    fr: "Gabon ",
    flag: "🇬🇦",
  },
  GE: {
    code: "GE",
    dial_code: "+995",
    en: "Georgia",
    ar: "جورجيا",
    fr: "Géorgie ",
    flag: "🇬🇪",
  },
  DE: {
    code: "DE",
    dial_code: "+49",
    en: "Germany",
    ar: "ألمانيا",
    fr: "Allemagne ",
    flag: "🇩🇪",
  },
  GH: {
    code: "GH",
    dial_code: "+233",
    en: "Ghana",
    ar: "غانا",
    fr: "Ghana ",
    flag: "🇬🇭",
  },
  GR: {
    code: "GR",
    dial_code: "+30",
    en: "Greece",
    ar: "اليونان",
    fr: "Grèce ",
    flag: "🇬🇷",
  },
  GD: {
    code: "GD",
    dial_code: "+1473",
    en: "Grenada",
    ar: "غرينادا",
    fr: "Grenade ",
    flag: "🇬🇩",
  },
  GT: {
    code: "GT",
    dial_code: "+502",
    en: "Guatemala",
    ar: "غواتيمالا",
    fr: "Guatemala ",
    flag: "🇬🇹",
  },
  GN: {
    code: "GN",
    dial_code: "+224",
    en: "Guinea",
    ar: "غينيا",
    fr: "Guinée ",
    flag: "🇬🇳",
  },
  GW: {
    code: "GW",
    dial_code: "+245",
    en: "Guinea-Bissau",
    ar: "غينيا - بيساو",
    fr: "Guinée-Bissau ",
    flag: "🇬🇼",
  },
  GY: {
    code: "GY",
    dial_code: "+595",
    en: "Guyana",
    ar: "غيانا",
    fr: "Guyana ",
    flag: "🇬🇾",
  },
  HT: {
    code: "HT",
    dial_code: "+509",
    en: "Haiti",
    ar: "هايتي",
    fr: "Haïti",
    flag: "🇭🇹",
  },
  HN: {
    code: "HN",
    dial_code: "+504",
    en: "Honduras",
    ar: "هندوراس",
    fr: "Honduras ",
    flag: "🇭🇳",
  },
  HU: {
    code: "HU",
    dial_code: "+36",
    en: "Hungary",
    ar: "هنغاريا",
    fr: "Hongrie ",
    flag: "🇭🇺",
  },
  IS: {
    code: "IS",
    dial_code: "+354",
    en: "Iceland",
    ar: "آيسلندا",
    fr: "Islande ",
    flag: "🇮🇸",
  },
  IN: {
    code: "IN",
    dial_code: "+91",
    en: "India",
    ar: "الهند",
    fr: "Inde ",
    flag: "🇮🇳",
  },
  ID: {
    code: "ID",
    dial_code: "+62",
    en: "Indonesia",
    ar: "إندونيسيا",
    fr: "Indonésie ",
    flag: "🇮🇩",
  },
  IQ: {
    code: "IQ",
    dial_code: "+964",
    en: "Iraq",
    ar: "العراق",
    fr: "Iraq ",
    flag: "🇮🇶",
  },
  IE: {
    code: "IE",
    dial_code: "+353",
    en: "Ireland",
    ar: "أيرلندا",
    fr: "Irlande ",
    flag: "🇮🇪",
  },
  IL: {
    code: "IL",
    dial_code: "+972",
    en: "Israel",
    ar: "إسرائيل",
    fr: "Israël",
    flag: "🇮🇱",
  },
  IT: {
    code: "IT",
    dial_code: "+39",
    en: "Italy",
    ar: "إيطاليا",
    fr: "Italie ",
    flag: "🇮🇹",
  },
  JM: {
    code: "JM",
    dial_code: "+1876",
    en: "Jamaica",
    ar: "جامايكا",
    fr: "Jamaïque ",
    flag: "🇯🇲",
  },
  JP: {
    code: "JP",
    dial_code: "+81",
    en: "Japan",
    ar: "اليابان",
    fr: "Japon ",
    flag: "🇯🇵",
  },
  JO: {
    code: "JO",
    dial_code: "+962",
    en: "Jordan",
    ar: "الأردن",
    fr: "Jordanie ",
    flag: "🇯🇴",
  },
  KZ: {
    code: "KZ",
    dial_code: "+77",
    en: "Kazakhstan",
    ar: "كازاخستان",
    fr: "Kazakhstan ",
    flag: "🇰🇿",
  },
  KE: {
    code: "KE",
    dial_code: "+254",
    en: "Kenya",
    ar: "كينيا",
    fr: "Kenya ",
    flag: "🇰🇪",
  },
  KI: {
    code: "KI",
    dial_code: "+686",
    en: "Kiribati",
    ar: "كيريباس",
    fr: "Kiribati",
    flag: "🇰🇮",
  },
  KW: {
    code: "KW",
    dial_code: "+965",
    en: "Kuwait",
    ar: "الكويت",
    fr: "Koweït ",
    flag: "🇰🇼",
  },
  KG: {
    code: "KG",
    dial_code: "+996",
    en: "Kyrgyzstan",
    ar: "قيرغيزستان",
    fr: "Kirghizistan ",
    flag: "🇰🇬",
  },
  LV: {
    code: "LV",
    dial_code: "+371",
    en: "Latvia",
    ar: "لاتفيا",
    fr: "Lettonie ",
    flag: "🇱🇻",
  },
  LB: {
    code: "LB",
    dial_code: "+961",
    en: "Lebanon",
    ar: "لبنان",
    fr: "Liban ",
    flag: "🇱🇧",
  },
  LS: {
    code: "LS",
    dial_code: "+266",
    en: "Lesotho",
    ar: "ليسوتو",
    fr: "Lesotho ",
    flag: "🇱🇸",
  },
  LR: {
    code: "LR",
    dial_code: "+231",
    en: "Liberia",
    ar: "ليبريا",
    fr: "Libéria ",
    flag: "🇱🇷",
  },
  LI: {
    code: "LI",
    dial_code: "+423",
    en: "Liechtenstein",
    ar: "ليختنشتاين",
    fr: "Liechtenstein ",
    flag: "🇱🇮",
  },
  LT: {
    code: "LT",
    dial_code: "+370",
    en: "Lithuania",
    ar: "ليتوانيا",
    fr: "Lituanie ",
    flag: "🇱🇹",
  },
  LU: {
    code: "LU",
    dial_code: "+352",
    en: "Luxembourg",
    ar: "لكسمبرغ",
    fr: "Luxembourg ",
    flag: "🇱🇺",
  },
  MG: {
    code: "MG",
    dial_code: "+261",
    en: "Madagascar",
    ar: "مدغشقر",
    fr: "Madagascar",
    flag: "🇲🇬",
  },
  MW: {
    code: "MW",
    dial_code: "+265",
    en: "Malawi",
    ar: "ملاوي",
    fr: "Malawi ",
    flag: "🇲🇼",
  },
  MY: {
    code: "MY",
    dial_code: "+60",
    en: "Malaysia",
    ar: "ماليزيا",
    fr: "Malaisie ",
    flag: "🇲🇾",
  },
  MV: {
    code: "MV",
    dial_code: "+960",
    en: "Maldives",
    ar: "ملديف",
    fr: "Maldives ",
    flag: "🇲🇻",
  },
  ML: {
    code: "ML",
    dial_code: "+223",
    en: "Mali",
    ar: "مالي",
    fr: "Mali ",
    flag: "🇲🇱",
  },
  MT: {
    code: "MT",
    dial_code: "+356",
    en: "Malta",
    ar: "مالطة",
    fr: "Malte",
    flag: "🇲🇹",
  },
  MR: {
    code: "MR",
    dial_code: "+222",
    en: "Mauritania",
    ar: "موريتانيا",
    fr: "Mauritanie ",
    flag: "🇲🇷",
  },
  MU: {
    code: "MU",
    dial_code: "+230",
    en: "Mauritius",
    ar: "موريشيوس",
    fr: "Maurice",
    flag: "🇲🇺",
  },
  MX: {
    code: "MX",
    dial_code: "+52",
    en: "Mexico",
    ar: "المكسيك",
    fr: "Mexique ",
    flag: "🇲🇽",
  },
  MC: {
    code: "MC",
    dial_code: "+377",
    en: "Monaco",
    ar: "موناكو",
    fr: "Monaco",
    flag: "🇲🇨",
  },
  MN: {
    code: "MN",
    dial_code: "+976",
    en: "Mongolia",
    ar: "منغوليا",
    fr: "Mongolie ",
    flag: "🇲🇳",
  },
  ME: {
    code: "ME",
    dial_code: "+382",
    en: "Montenegro",
    ar: "الجبل الأسود",
    fr: "Monténégro ",
    flag: "🇲🇪",
  },
  MA: {
    code: "MA",
    dial_code: "+212",
    en: "Morocco",
    ar: "المغرب",
    fr: "Maroc ",
    flag: "🇲🇦",
  },
  MZ: {
    code: "MZ",
    dial_code: "+258",
    en: "Mozambique",
    ar: "موزامبيق",
    fr: "Mozambique ",
    flag: "🇲🇿",
  },
  MM: {
    code: "MM",
    dial_code: "+95",
    en: "Myanmar",
    ar: "ميانمار",
    fr: "Myanmar ",
    flag: "🇲🇲",
  },
  NA: {
    code: "NA",
    dial_code: "+264",
    en: "Namibia",
    ar: "ناميبيا",
    fr: "Namibie ",
    flag: "🇳🇦",
  },
  NR: {
    code: "NR",
    dial_code: "+674",
    en: "Nauru",
    ar: "ناورو",
    fr: "Nauru",
    flag: "🇳🇷",
  },
  NP: {
    code: "NP",
    dial_code: "+977",
    en: "Nepal",
    ar: "نيبال",
    fr: "Népal ",
    flag: "🇳🇵",
  },
  NZ: {
    code: "NZ",
    dial_code: "+64",
    en: "New Zealand",
    ar: "نيوزيلندا",
    fr: "Nouvelle-Zélande ",
    flag: "🇳🇿",
  },
  NI: {
    code: "NI",
    dial_code: "+505",
    en: "Nicaragua",
    ar: "نيكاراغوا",
    fr: "Nicaragua ",
    flag: "🇳🇮",
  },
  NG: {
    code: "NG",
    dial_code: "+234",
    en: "Nigeria",
    ar: "نيجيريا",
    fr: "Nigéria ",
    flag: "🇳🇬",
  },
  NO: {
    code: "NO",
    dial_code: "+47",
    en: "Norway",
    ar: "النرويج",
    fr: "Norvège ",
    flag: "🇳🇴",
  },
  OM: {
    code: "OM",
    dial_code: "+968",
    en: "Oman",
    ar: "عمان",
    fr: "Oman",
    flag: "🇴🇲",
  },
  PK: {
    code: "PK",
    dial_code: "+92",
    en: "Pakistan",
    ar: "باكستان",
    fr: "Pakistan ",
    flag: "🇵🇰",
  },
  PW: {
    code: "PW",
    dial_code: "+680",
    en: "Palau",
    ar: "بالاو",
    fr: "Palaos ",
    flag: "🇵🇼",
  },
  PA: {
    code: "PA",
    dial_code: "+507",
    en: "Panama",
    ar: "بنما",
    fr: "Panama ",
    flag: "🇵🇦",
  },
  PG: {
    code: "PG",
    dial_code: "+675",
    en: "Papua New Guinea",
    ar: "بابوا غينيا الجديدة",
    fr: "Papouasie-Nouvelle-Guinée ",
    flag: "🇵🇬",
  },
  PY: {
    code: "PY",
    dial_code: "+595",
    en: "Paraguay",
    ar: "باراغواي",
    fr: "Paraguay ",
    flag: "🇵🇾",
  },
  PE: {
    code: "PE",
    dial_code: "+51",
    en: "Peru",
    ar: "بيرو",
    fr: "Pérou ",
    flag: "🇵🇪",
  },
  PL: {
    code: "PL",
    dial_code: "+48",
    en: "Poland",
    ar: "بولندا",
    fr: "Pologne ",
    flag: "🇵🇱",
  },
  PT: {
    code: "PT",
    dial_code: "+351",
    en: "Portugal",
    ar: "البرتغال",
    fr: "Portugal ",
    flag: "🇵🇹",
  },
  QA: {
    code: "QA",
    dial_code: "+974",
    en: "Qatar",
    ar: "قطر",
    fr: "Qatar ",
    flag: "🇶🇦",
  },
  RO: {
    code: "RO",
    dial_code: "+40",
    en: "Romania",
    ar: "رومانيا",
    fr: "Roumanie ",
    flag: "🇷🇴",
  },
  RW: {
    code: "RW",
    dial_code: "+250",
    en: "Rwanda",
    ar: "رواندا",
    fr: "Rwanda ",
    flag: "🇷🇼",
  },
  KN: {
    code: "KN",
    dial_code: "+1869",
    en: "Saint Kitts and Nevis",
    ar: "سانت كيتس ونيفس",
    fr: "Saint-Kitts-et-Nevis",
    flag: "🇰🇳",
  },
  LC: {
    code: "LC",
    dial_code: "+1758",
    en: "Saint Lucia",
    ar: "سانت لوسيا",
    fr: "Sainte-Lucie",
    flag: "🇱🇨",
  },
  VC: {
    code: "VC",
    dial_code: "+1784",
    en: "Saint Vincent and the Grenadines",
    ar: "سانت فنسنت وجزر غرينادين",
    fr: "Saint-Vincent-et-les Grenadines",
    flag: "🇻🇨",
  },
  WS: {
    code: "WS",
    dial_code: "+685",
    en: "Samoa",
    ar: "ساموا",
    fr: "Samoa ",
    flag: "🇼🇸",
  },
  SM: {
    code: "SM",
    dial_code: "+378",
    en: "San Marino",
    ar: "سان مارينو",
    fr: "Saint-Marin",
    flag: "🇸🇲",
  },
  ST: {
    code: "ST",
    dial_code: "+239",
    en: "Sao Tome and Principe",
    ar: "سان تومي وبرينسيبي",
    fr: "Sao Tomé-et-Principe",
    flag: "🇸🇹",
  },
  CI: {
    code: "CI",
    dial_code: "+225",
    en: "Ivory Coast",
    ar: " ساحل العاج",
    fr: "Côte d'Ivoire",
    flag: "🇨🇮",
  },
  SA: {
    code: "SA",
    dial_code: "+966",
    en: "Saudi Arabia",
    ar: "المملكة العربية السعودية",
    fr: "Arabie saoudite ",
    flag: "🇸🇦",
  },
  SN: {
    code: "SN",
    dial_code: "+221",
    en: "Senegal",
    ar: "السنغال",
    fr: "Sénégal ",
    flag: "🇸🇳",
  },
  RS: {
    code: "RS",
    dial_code: "+381",
    en: "Serbia",
    ar: "صربيا",
    fr: "Serbie ",
    flag: "🇷🇸",
  },
  SC: {
    code: "SC",
    dial_code: "+248",
    en: "Seychelles",
    ar: "سيشيل",
    fr: "Seychelles ",
    flag: "🇸🇨",
  },
  SL: {
    code: "SL",
    dial_code: "+232",
    en: "Sierra Leone",
    ar: "سيراليون",
    fr: "Sierra Leone ",
    flag: "🇸🇱",
  },
  SG: {
    code: "SG",
    dial_code: "+65",
    en: "Singapore",
    ar: "سنغافورة",
    fr: "Singapour",
    flag: "🇸🇬",
  },
  SK: {
    code: "SK",
    dial_code: "+421",
    en: "Slovakia",
    ar: "سلوفاكيا",
    fr: "Slovaquie ",
    flag: "🇸🇰",
  },
  SI: {
    code: "SI",
    dial_code: "+386",
    en: "Slovenia",
    ar: "سلوفينيا",
    fr: "Slovénie ",
    flag: "🇸🇮",
  },
  SB: {
    code: "SB",
    dial_code: "+677",
    en: "Solomon Islands",
    ar: "جزر سليمان",
    fr: "Îles Salomon ",
    flag: "🇸🇧",
  },
  SO: {
    code: "SO",
    dial_code: "+252",
    en: "Somalia",
    ar: "الصومال",
    fr: "Somalie ",
    flag: "🇸🇴",
  },
  ZA: {
    code: "ZA",
    dial_code: "+27",
    en: "South Africa",
    ar: "جنوب أفريقيا",
    fr: "Afrique du Sud ",
    flag: "🇿🇦",
  },
  SS: {
    code: "SS",
    dial_code: "+211",
    en: "South Sudan",
    ar: "جنوب السودان",
    fr: "Soudan du Sud ",
    flag: "🇸🇸",
  },
  ES: {
    code: "ES",
    dial_code: "+34",
    en: "Spain",
    ar: "إسبانيا",
    fr: "Espagne ",
    flag: "🇪🇸",
  },
  LK: {
    code: "LK",
    dial_code: "+94",
    en: "Sri Lanka",
    ar: "سري لانكا",
    fr: "Sri Lanka",
    flag: "🇱🇰",
  },
  SR: {
    code: "SR",
    dial_code: "+597",
    en: "Suriname",
    ar: "سورينام",
    fr: "Suriname ",
    flag: "🇸🇷",
  },
  SE: {
    code: "SE",
    dial_code: "+46",
    en: "Sweden",
    ar: "السويد",
    fr: "Suède ",
    flag: "🇸🇪",
  },
  CH: {
    code: "CH",
    dial_code: "+41",
    en: "Switzerland",
    ar: "سويسرا",
    fr: "Suisse ",
    flag: "🇨🇭",
  },
  TJ: {
    code: "TJ",
    dial_code: "+992",
    en: "Tajikistan",
    ar: "طاجيكستان",
    fr: "Tadjikistan ",
    flag: "🇹🇯",
  },
  TH: {
    code: "TH",
    dial_code: "+66",
    en: "Thailand",
    ar: "تايلند",
    fr: "Thaïlande ",
    flag: "🇹🇭",
  },
  TL: {
    code: "TL",
    dial_code: "+670",
    en: "Timor-Leste",
    ar: "تيمور- ليشتي",
    fr: "Timor-Leste ",
    flag: "🇹🇱",
  },
  TG: {
    code: "TG",
    dial_code: "+228",
    en: "Togo",
    ar: "توغو",
    fr: "Togo ",
    flag: "🇹🇬",
  },
  TO: {
    code: "TO",
    dial_code: "+676",
    en: "Tonga",
    ar: "تونغا",
    fr: "Tonga ",
    flag: "🇹🇴",
  },
  TT: {
    code: "TT",
    dial_code: "+1868",
    en: "Trinidad and Tobago",
    ar: "ترينيداد وتوباغو",
    fr: "Trinité-et-Tobago ",
    flag: "🇹🇹",
  },
  TN: {
    code: "TN",
    dial_code: "+216",
    en: "Tunisia",
    ar: "تونس",
    fr: "Tunisie ",
    flag: "🇹🇳",
  },
  TR: {
    code: "TR",
    dial_code: "+90",
    en: "Turkey",
    ar: "تركيا",
    fr: "Turquie ",
    flag: "🇹🇷",
  },
  TM: {
    code: "TM",
    dial_code: "+993",
    en: "Turkmenistan",
    ar: "تركمانستان",
    fr: "Turkménistan ",
    flag: "🇹🇲",
  },
  TV: {
    code: "TV",
    dial_code: "+688",
    en: "Tuvalu",
    ar: "توفالو",
    fr: "Tuvalu ",
    flag: "🇹🇻",
  },
  UG: {
    code: "UG",
    dial_code: "+256",
    en: "Uganda",
    ar: "أوغندا",
    fr: "Ouganda ",
    flag: "🇺🇬",
  },
  UA: {
    code: "UA",
    dial_code: "+380",
    en: "Ukraine",
    ar: "أوكرانيا",
    fr: "Ukraine ",
    flag: "🇺🇦",
  },
  UY: {
    code: "UY",
    dial_code: "+598",
    en: "Uruguay",
    ar: "أوروغواي",
    fr: "Uruguay ",
    flag: "🇺🇾",
  },
  UZ: {
    code: "UZ",
    dial_code: "+998",
    en: "Uzbekistan",
    ar: "أوزبكستان",
    fr: "Ouzbékistan ",
    flag: "🇺🇿",
  },
  VU: {
    code: "VU",
    dial_code: "+678",
    en: "Vanuatu",
    ar: "فانواتو",
    fr: "Vanuatu",
    flag: "🇻🇺",
  },
  YE: {
    code: "YE",
    dial_code: "+967",
    en: "Yemen",
    ar: "اليمن",
    fr: "Yémen ",
    flag: "🇾🇪",
  },
  ZM: {
    code: "ZM",
    dial_code: "+260",
    en: "Zambia",
    ar: "زامبيا",
    fr: "Zambie ",
    flag: "🇿🇲",
  },
  ZW: {
    code: "ZW",
    dial_code: "+263",
    en: "Zimbabwe",
    ar: "زمبابوي",
    fr: "Zimbabwe ",
    flag: "🇿🇼",
  },
};

import React from "react";
import { useVisas } from "../hooks/useVisas";

export const VisasContext = React.createContext<any | null>(null);
const VisasProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data, isLoading, error, init, updateFilter } = useVisas();
  return (
    <VisasContext.Provider
      value={{ data, isLoading, error, init, updateFilter }}
    >
      {children}
    </VisasContext.Provider>
  );
};

export default VisasProvider;

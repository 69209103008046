import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useNavigate, useParams } from "react-router-dom";

import { useAdministrativeSupportApplications } from "../../hooks/useAdministrativeSupportApplications";
import Stepper from "../../components/pages/ProgramApplication/Stepper";
import { visaApplicationInputs } from "../../data/applicationInputs";
import { useAdministrativeSupport } from "../../hooks/useAdministrativeSupport";
import { MainContext } from "../../contexts/MainContext";
import _ from "lodash";

const AdministrativeSupportApplication = () => {
  let { id: applicationId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { account } = useContext(MainContext);

  const { getAdministrativeSupport } = useAdministrativeSupport();
  const {
    createApplication,
    getApplicationById,
    getApplicationByClient,
    submitApplicationWithPaiement,
    getPaiementIntent,
  } = useAdministrativeSupportApplications();

  const [documents, setDocuments] = useState<any[]>([]);
  const [data, setData] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(true);
  const [initialStep, setInitialStep] = useState<number>(0);
  const [application, setApplication] = useState<any>(null);

  const getAdministrativeSupportData = async () => {
    const { data, error } = await getAdministrativeSupport();

    console.log("@fetch_error", error);

    const { documents } = data;
    setData(data);
    setDocuments(documents);
    console.log("@@loading");

    setLoading(false);
  };

  // const getApplication = async () => {
  //   if (id) {
  //     const { data, error = null } = await getApplicationById(Number(id));
  //     if (error) return setError(true);
  //     if (data.status === "PENDING" || _.isNull(data?.paiement)) {
  //       setApplication(data);
  //       await getAdministrativeSupportData();
  //       return setInitialStep(1);
  //     }
  //     if (data.status !== "PENDING")
  //       return navigate(`/administrative-support/applications/${id}`);
  //   }

  //   setInitialStep(0);
  //   await getAdministrativeSupportData();
  // };

  const getApplication = async () => {
    if (applicationId) {
      console.log("@applicationId", applicationId);

      const { data, error } = await getApplicationById(Number(applicationId));
      console.log("@data", data);
      console.log("@error", error);

      if (error) return setError(true);
      if (
        data.status === "PENDING" ||
        (data.status === "SUBMITTED" && _.isNull(data?.paiement))
      ) {
        console.log("----------- 1 ---------");
        setApplication(data);
        console.log("----------- 2 ---------");

        getAdministrativeSupportData();
        return setInitialStep(1);
      }
      if (data.status !== "PENDING" || !_.isNull(data?.paiement)) {
        toast({
          title: "Il existe déja une candidature en ce programme.",
          status: "info",
          position: "top",
          containerStyle: { fontWeight: "bold" },
        });
        return navigate(
          `/administrative-support/applications/${applicationId}`
        );
      }
    } else {
      const { data, error } = await getApplicationByClient();
      if (data) {
        console.log("application_found", data);
        applicationId = data;
        getApplication();
      }

      if (error) {
        setInitialStep(0);
        getAdministrativeSupportData();
      }
    }
  };

  const handleCreateApplication = async (id: number) => {
    const { data } = await createApplication();
    setApplication(data);
  };

  const handleGetPaiementIntent = async () => {
    return await getPaiementIntent(Number(application.id));
  };

  const handleSubmit = async (
    type: "CASH" | "DEPOSIT" | "ON_LINE",
    temp: { proofUrl?: string; bookedDate?: string }
  ) => {
    return await submitApplicationWithPaiement(
      Number(application.id),
      type,
      temp
    );
  };

  useEffect(() => {
    if (!account?.verified) return navigate("/confirm-email");
    getApplication();
  }, []);

  if (isLoading)
    return (
      <Center width={"100vw"} height={"100vh"}>
        <Spinner color="primary" width={30} height={30} size={"lg"} />
      </Center>
    );

  return (
    <Box mt={10} py={12} bg={"gray.100"}>
      <Container maxWidth={"6xl"}>
        <Box>
          <Heading size={"lg"}>
            Procedure administrative des étudiants algériens en france
          </Heading>
        </Box>
        {/* ---------------------------------------------------------- */}
        <Stepper
          id={0}
          applicationId={Number(applicationId) || null}
          filesNotes={data?.filesNotes || ""}
          documents={documents}
          initialStep={initialStep}
          inputs={visaApplicationInputs}
          levelForm={false}
          onCreateApplication={handleCreateApplication}
          priceDZD={data.priceDZD}
          priceEUR={data.priceEUR}
          refused={false}
          onSubmit={handleSubmit}
          getIntnet={handleGetPaiementIntent}
          url={`/administrative-support/applications/${application?.id || ""}`}
        />
      </Container>
    </Box>
  );
};

export default AdministrativeSupportApplication;

import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

const ApplicationSubmitted: FC<{ url: string }> = ({ url }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url);
  };
  return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={"50px"} color={"green.500"} />
      <Heading as="h2" size="xl" mt={6} my={3}>
        Cadidature envoyé avec succée.
      </Heading>
      <Text color={"gray.500"} my={3}></Text>
      <Button bg={"primary"} color={"white"} onClick={handleClick}>
        Consulter ma candidature
      </Button>
    </Box>
  );
};

export default ApplicationSubmitted;

import React, { FC } from "react";
import { Badge, Link, Text } from "@chakra-ui/react";
import { INavItem } from "../../../helpers/types";
import { Link as ReachLink } from "react-router-dom";

const NavItem: FC<INavItem> = ({
  link = "",
  content,
  active,
  label = null,
}) => (
  <Link
    isExternal={false}
    _hover={{
      textDecoration: "none",
      borderColor: "red.100",
      borderBottom: "4px",
    }}
    _focus={{
      outline: "none",
    }}
    to={link}
    as={ReachLink}
    px={2}
    borderColor={active ? "primary" : ""}
    borderBottom={active ? "4px" : ""}
    // color="primary"
  >
    <Text py={2} fontSize="md" fontWeight={"extrabold"}>
      {content}
      {Number(label) !== 0 && (
        <Badge bg={"red.500"} rounded={"full"} fontSize={"lg"} px={2} mx={1}>
          {label}
        </Badge>
      )}
    </Text>
  </Link>
);

export default NavItem;

import { Flex, Image, Text } from "@chakra-ui/react";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

const Brand: FC<{ color?: string }> = ({ color = "black" }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <Flex cursor={"pointer"}>
      <Image mx={1} width={10} height={10} src={"/favicon.ico"} my={"auto"} />
      <Text
        borderLeft={"1px"}
        paddingLeft={"1.5"}
        textAlign={"start"}
        fontSize={"md"}
        color={color}
        width={["100%", "auto", "auto"]}
        onClick={handleClick}
      >
        SDZ <br />
        TRAVEL
      </Text>
    </Flex>
  );
};

export default Brand;

import React, { FC, useContext, useState } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { MainContext } from "../../contexts/MainContext";
import { useDocuments } from "../../hooks/useDocuments";
import { useStorage } from "../../hooks/useStorage";
import UploadFileModal from "../modals/UploadFileModal";
import FileItem from "./FileItem";
import shortenString from "../../helpers/shortenString";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const ApplicationFilesForm: FC<{
  documents: any[];
  notes: string;

  nextStep: () => void;
  prevStep: () => void;
}> = ({ documents, notes, nextStep, prevStep }) => {
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const { uploadFile } = useStorage();
  const { updateDocumentURL } = useDocuments();
  const { account, refreshUploadedDocuments } = useContext(MainContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [files, setFiles] = useState<any>({});
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<number | "">("");
  const handleOpenModal = (id: number) => {
    setSelectedDocumentId(id);
    onOpen();
  };

  const handleUploadFile = (uploadedFile: File | null) => {
    setFiles({ ...files, [selectedDocumentId]: uploadedFile });
    setSelectedDocumentId("");
  };

  const handleSubmit = async () => {
    setError(false);
    setLoading(true);

    // check if all files are uploaded
    let isValid: boolean = true;
    let currentFiles: any[] = [];
    const requiredFiles: any[] = [];

    // select the id_s of already uploaded documents
    const uploadedFiles: any[] = Object.keys(files);

    let { uploadedDocumets } = account;
    // select the id_s of mandatory documents
    documents.map(({ document, mandatory }) => {
      if (mandatory) return requiredFiles.push(document);
    });

    // select the id_s of the existing documents
    uploadedDocumets.map(({ documentId, url }: any) => {
      if (url && url !== "") currentFiles.push(documentId);
    });

    // check if the file already uploaded or exist in the uploaeded documents
    requiredFiles.map(({ id }) => {
      isValid =
        (isValid && uploadedFiles.includes(String(id))) ||
        currentFiles.includes(id);
    });

    if (isValid) {
      Promise.all(
        uploadedFiles.map(async (documentId) => {
          const key: string = await uploadFile(
            files[documentId],
            "documents",
            `${documentId}-${account.firstname}_${
              account.lastname
            }-(${uuidv4()})`
          );
          const result = await updateDocumentURL(Number(documentId), key);
        })
      );

      await refreshUploadedDocuments();

      setLoading(false);
      nextStep();
    }

    if (!isValid) {
      setError(true);
      setLoading(false);
    }
  };

  const getFileIndex = (id: number) => {
    return _.findIndex(account.uploadedDocumets, { documentId: id });
  };

  return (
    <>
      <Box>
        {error && (
          <Alert status="error">
            <AlertIcon />
            Veuillez telecharger tous les documents obligatoires.
          </Alert>
        )}
        {notes && notes.length > 0 && (
          <Box bg={"white"} p={4} rounded={"md"} my={2}>
            <Box
              dangerouslySetInnerHTML={{
                __html:
                  (notes.length > 600 &&
                    seeMore &&
                    shortenString(notes, 550)) ||
                  notes,
              }}
            />

            <Text
              textColor={"blue.800"}
              fontWeight={"bold"}
              textDecoration={"underline"}
              cursor={"pointer"}
              onClick={() => setSeeMore(!seeMore)}
            >
              {seeMore && (
                <>
                  voir plus <FontAwesomeIcon icon={faChevronDown} />
                </>
              )}
              {!seeMore && (
                <>
                  voir moins <FontAwesomeIcon icon={faChevronUp} />
                </>
              )}
            </Text>
          </Box>
        )}
        {documents.map(({ document, mandatory }) => (
          <FileItem
            id={document.id}
            name={document.name}
            description={document.description}
            isRequired={mandatory}
            currentDocumentUrl={
              getFileIndex(document.id) !== -1
                ? account.uploadedDocumets[getFileIndex(document.id)].url
                : null
            }
            uploadedFileName={files[document.id]?.name || null}
            handleOpenModal={handleOpenModal}
          />
        ))}
      </Box>
      <UploadFileModal
        title="Télécharger votre fichier"
        isOpen={isOpen}
        onClose={onClose}
        onUpload={handleUploadFile}
      />
      <SimpleGrid columns={[1, 2]} gap={2}>
        <Button
          onClick={prevStep}
          width={"full"}
          bg={"white"}
          color={"primary"}
        >
          Précédent
        </Button>
        <Button
          width={"full"}
          bg={"primary"}
          color={"white"}
          onClick={handleSubmit}
          isLoading={isLoading}
        >
          Suivant
        </Button>
      </SimpleGrid>
    </>
  );
};

export default ApplicationFilesForm;

import { Avatar, Flex, IconButton, Text } from "@chakra-ui/react";
import {
  faArrowLeft,
  faArrowRight,
  faBars,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { ChatContext } from "../../contexts/ChatContext";

const ChatHeader: FC<{
  toggleSidebar: Function;
  toggleFilesbar: Function;
}> = ({ toggleSidebar, toggleFilesbar }) => {
  return (
    <ChatContext.Consumer>
      {({ selectedConversation }) => (
        <Flex alignItems={"center"} justifyContent={"space-between"} flex={1}>
          <Flex alignItems={"center"}>
            <IconButton
              display={["block", "none"]}
              size={"sm"}
              bg={"gray.200"}
              color={"black"}
              aria-label="true"
              onClick={() => toggleSidebar()}
              icon={<FontAwesomeIcon icon={faBars} />}
              mx={1}
            />
            <Avatar
              size={"sm"}
              rounded={"md"}
              src={selectedConversation.photoUrl}
            />
            <Text fontSize={"xl"} mx={2} fontWeight={"bold"}>
              {selectedConversation.title}
            </Text>
          </Flex>
          <IconButton
            display={["block", "none"]}
            justifySelf={"end"}
            size={"sm"}
            bg={"gray.200"}
            color={"black"}
            aria-label="true"
            onClick={() => toggleFilesbar()}
            icon={<FontAwesomeIcon icon={faArrowRight} />}
            mx={1}
          />
        </Flex>
      )}
    </ChatContext.Consumer>
  );
};

export default ChatHeader;

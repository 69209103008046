import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Box,
  Center,
  Container,
  Heading,
  Spinner,
} from "@chakra-ui/react";
import ApplicationItem from "../../ApplicationItem/ApplicationItem";
import { v4 as uuidv4 } from "uuid";
import StatusBadge from "../../common/StatusBadge";
import { MainContext } from "../../../contexts/MainContext";
import moment from "moment";
import shortenString from "../../../helpers/shortenString";
import config from "../../../config";
import { useProgramApplications } from "../../../hooks/useProgramApplications";
import { getCountry } from "../../../helpers/getCountry";
import PaiementStatus from "../../common/PaiementStatus";
import _ from "lodash";

const ProgramApplicationsPage = () => {
  // const {
  //   account: { programApplications = [] },
  // } = useContext(MainContext);
  const { getApplications, deleteApplicationById } = useProgramApplications();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  const getData = async () => {
    setLoading(true);
    const { data = [] } = await getApplications();

    setData(data);
    setLoading(false);
  };

  const handleDelete = async (id: number) => {
    const { error = null } = await deleteApplicationById(id);
    if (error) return { error };
    await getData();
    return { error };
  };

  const getItems = (data: any) => {
    return [
      {
        label: `${getCountry(data?.country).flag} ${shortenString(
          data.program,
          17
        )}`,
        accessor: data.degree,
      },
      { label: data.duration, accessor: data.language },
      { label: "Status", accessor: () => <StatusBadge status={data.status} /> },
      {
        label: "Paiement",
        accessor: () => (
          <PaiementStatus status={data?.paiement?.status || "NOT_PAIED"} />
        ),
      },
    ];
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading)
    return (
      <Center width={"100vw"} height={"100vh"}>
        <Spinner color="primary" width={30} height={30} size={"lg"} />
      </Center>
    );

  return (
    <Container maxW="container.lg" mt={10} py={10}>
      <Heading>Mes candidatures programmes</Heading>
      <Box my={10}>
        {data?.map((item: any) => {
          const {
            id,
            createdAt,
            status,
            paiement = null,
            program: {
              id: programId,
              name,
              degree,
              languages,
              duration,
              pictureUrl = null,

              countryCode,
            },
          } = item;
          return (
            <Box key={uuidv4()}>
              <ApplicationItem
                isDeleteEnabled={
                  _.isNull(paiement) || paiement?.status !== "ACCEPTED"
                }
                onDelete={async () => await handleDelete(Number(id))}
                items={getItems({
                  paiement,
                  degree,
                  program: name,
                  country: countryCode,
                  language: languages[0],
                  date: moment(createdAt).format("DD-MM-YYYY"),
                  duration,
                  status,
                })}
                imageUrl={
                  (pictureUrl && `${config.S3_URL}/${pictureUrl}`) ||
                  "/default-placeholder.png"
                }
                buttonText={
                  status === "PENDING" || _.isNull(paiement)
                    ? "Continuer"
                    : "Consulter"
                }
                path={
                  status === "PENDING" || _.isNull(paiement)
                    ? `/programs/applications/${programId}/apply/${id}`
                    : `/programs/applications/${id}`
                }
              />
            </Box>
          );
        })}
      </Box>
    </Container>
  );
};

export default ProgramApplicationsPage;

import axios from "axios";
import config from "../config";
const BASE_URL = `${config.API_URL}/program-countries`;

export function useProgramCountries() {
  async function getCountries() {
    const path = `${BASE_URL}?itemsPerPage=100`;
    try {
      const {
        data: {
          body: { data },
        },
      } = await axios.get(path);
      return { data: data, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  }
  async function getCountryByCode(code: string) {
    const path = `${BASE_URL}/${code}`;
    try {
      const {
        data: { body },
      } = await axios.get(path);
      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  }

  return { getCountries, getCountryByCode };
}

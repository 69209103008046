import React, { FC, ReactElement } from "react";

import { useChat } from "../hooks/useChat";

export const ChatContext = React.createContext<any | null>(null);
const ChatProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const {
    conversations,
    selectedConversation,
    messages,
    handleSelectConversation,
    handleSendMessage,
    handleRecieveMessage,
    metaData,
    isLoading,
    filter,

    updateFilter,
  } = useChat();

  return (
    <ChatContext.Provider
      value={{
        conversations,
        selectedConversation,
        messages,
        handleSelectConversation,
        handleSendMessage,
        handleRecieveMessage,
        metaData,
        isLoading,
        filter,
        updateFilter,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;

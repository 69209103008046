import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import Stepper from "./Stepper";
import { usePrograms } from "../../../hooks/usePrograms";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { useProgramApplications } from "../../../hooks/useProgramApplications";
import { ProgramApplicationInputs } from "../../../data/applicationInputs";
import { MainContext } from "../../../contexts/MainContext";
import _ from "lodash";

const ProgramApplicationPage = () => {
  let { programId, applicationId = null } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { account } = useContext(MainContext);

  const { getProgramById } = usePrograms();
  const {
    createApplication,
    getApplicationById,
    getApplicationByProgramId,
    submitApplicationWithPaiement,
    getPaiementIntent,
  } = useProgramApplications();

  const [documents, setDocuments] = useState<any[]>([]);
  const [data, setData] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(true);
  const [initialStep, setInitialStep] = useState<number>(0);
  const [application, setApplication] = useState<any>(null);

  const getProgramData = async () => {
    const { data, error } = await getProgramById(Number(programId));

    const { documents } = data;
    setData(data);
    setDocuments(documents);
    setLoading(false);
  };

  const getApplication = async () => {
    if (applicationId) {
      const { data, error } = await getApplicationById(Number(applicationId));
      if (error) return setError(true);
      if (
        data.status === "PENDING" ||
        (data.status === "SUBMITTED" && _.isNull(data?.paiement))
      ) {
        setApplication(data);
        getProgramData();
        return setInitialStep(1);
      }
      if (data.status !== "PENDING" || !_.isNull(data?.paiement)) {
        toast({
          title: "Il existe déja une candidature en ce programme.",
          status: "info",
          position: "top",
          containerStyle: { fontWeight: "bold" },
        });
        return navigate(`/programs/applications/${applicationId}`);
      }
    }
    if (programId) {
      const { data, error } = await getApplicationByProgramId(
        Number(programId)
      );
      if (data) {
        applicationId = data;
        getApplication();
      }

      if (error) {
        setInitialStep(0);
        getProgramData();
      }
    }
  };

  const handleCreateApplication = async (id: number, level: string) => {
    const { data: createdApplication, error = null } = await createApplication(
      id,
      level
    );

    if (_.isNull(error)) {
      setApplication(createdApplication);
      navigate(
        `/programs/applications/${programId}/apply/${
          applicationId || createdApplication.id
        }`
      );
    }
  };

  const handleGetPaiementIntent = async () => {
    return await getPaiementIntent(Number(application.id));
  };

  const handleSubmit = async (
    type: "CASH" | "DEPOSIT" | "ON_LINE",
    temp: { proofUrl?: string; bookedDate?: string }
  ) => {
    return await submitApplicationWithPaiement(
      Number(application.id),
      type,
      temp
    );
  };

  useEffect(() => {
    if (!account?.verified) return navigate("/confirm-email");
    getApplication();
  }, []);

  if (isLoading)
    return (
      <Center width={"100vw"} height={"100vh"}>
        <Spinner color="primary" width={30} height={30} size={"lg"} />
      </Center>
    );

  return (
    <Box mt={10} py={12} bg={"gray.100"}>
      <Container maxWidth={"6xl"}>
        <Box>
          <Heading size={"lg"}>Postuler en {data.name}</Heading>
        </Box>
        {/* ---------------------------------------------------------- */}
        <Stepper
          id={Number(programId)}
          applicationId={Number(applicationId) || null}
          filesNotes={data?.filesNotes || ""}
          documents={documents}
          initialStep={initialStep}
          inputs={ProgramApplicationInputs}
          levelForm={true}
          onCreateApplication={handleCreateApplication}
          priceDZD={data.priceDZD}
          priceEUR={data.priceEUR}
          refused={false}
          onSubmit={handleSubmit}
          getIntnet={handleGetPaiementIntent}
          url={`/programs/applications/${application?.id || ""}`}
        />
      </Container>
    </Box>
  );
};

export default ProgramApplicationPage;

// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// 👇️ ts-ignore ignores any ts errors on the next line
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useToast,
} from "@chakra-ui/react";
import { faNoteSticky, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { FC, useContext, useState } from "react";
import { ChatContext } from "../../contexts/ChatContext";
import { useStorage } from "../../hooks/useStorage";
import FileInput from "../common/FileInput";
import { v4 as uuidv4 } from "uuid";

const FileMessagePopover = () => {
  const toast = useToast();
  const { uploadFile } = useStorage();
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  return (
    <ChatContext.Consumer>
      {({ handleSendMessage, selectedConversation }) => (
        <Popover>
          <PopoverTrigger>
            <IconButton
              bg={"gray.200"}
              color={"primary"}
              aria-label="true"
              icon={<FontAwesomeIcon icon={faPaperclip} />}
              onClick={() => {}}
            />
          </PopoverTrigger>

          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>
              <FontAwesomeIcon icon={faNoteSticky} /> Notes :
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              <FileInput
                handleDrop={(files: File[]) => {
                  setUploadedFile(files[0], "");
                }}
                uploadedFile={uploadedFile}
              />
              <Button
                bg={"primary"}
                color={"white"}
                width={"full"}
                isDisabled={
                  _.isNull(uploadedFile) || _.isUndefined(uploadedFile)
                }
                isLoading={isLoading}
                onClick={async () => {
                  setLoading(true);
                  const key = await uploadFile(
                    uploadedFile,
                    "chats",
                    uploadedFile.name
                  );
                  if (key === "error") {
                    toast({
                      status: "error",
                      description: "veuillez re-essayer d'envoyer le fichier",
                      isClosable: true,
                    });
                  } else {
                    await handleSendMessage(
                      "FILE",
                      key,
                      selectedConversation.doc_id
                    );
                    setUploadedFile(null);
                  }
                  setLoading(false);
                }}
              >
                envoyer
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </ChatContext.Consumer>
  );
};

export default FileMessagePopover;

import axios from "axios";
import config from "../config";
const BASE_URL = `${config.API_URL}/documents`;

export function useDocuments() {
  const updateDocumentURL = async (id: number, url: string) => {
    const path = `${BASE_URL}/${id}`;
    try {
      const {
        data: { body },
      } = await axios.put(path, { url });

      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };
  return { updateDocumentURL };
}

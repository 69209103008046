import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import config from "../config";
const BASE_URL = `${config.API_URL}/auth`;
export function useAuth() {
  const [account, setAccount] = useState<any>(null);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    checkAuthentification();
  }, []);

  async function checkAuthentification() {
    try {
      const {
        data: { body, message },
      } = await axios.get(`${BASE_URL}`);

      setAccount(body);
      setAuthenticated(true);
    } catch (error: any) {
      setAuthenticated(false);
      setAccount(null);
    }
    setLoading(false);
  }

  async function signIn(email: string, password: string) {
    try {
      const {
        data: { body },
      } = await axios.post(`/api/auth/sign-in`, {
        email,
        password,
      });

      setAuthenticated(true);
      setAccount(body);
      return { error: null };
    } catch (error: any) {
      const {
        response: {
          data: { code },
        },
      } = error;
      return { error: code };
    }
  }

  async function signUp(
    firstname: string,
    lastname: string,
    email: string,
    phoneNumber: string,
    password: string,
    passwordConfirmation: string
  ) {
    try {
      const {
        data: { body },
      } = await axios.post(`${BASE_URL}/sign-up`, {
        firstname,
        lastname,
        email,
        phoneNumber,
        password,
        passwordConfirmation,
      });

      setAuthenticated(true);
      setAccount(body);
      return { error: null };
    } catch (error: any) {
      const {
        response: { data },
      } = error;

      return { error: data };
    }
  }

  async function signOut() {
    try {
      await axios.get(`${BASE_URL}/sign-out`);
      setAuthenticated(false);
      setAccount(null);
      return { error: null };
    } catch (error: any) {
      const {
        response: {
          data: { code },
        },
      } = error;
      return { error: code };
    }
  }

  async function forgetPassword(email: string) {
    try {
      await axios.get(`${BASE_URL}/forget-password/${email}`);
      return { error: null };
    } catch (error: any) {
      return { error: error.code };
    }
  }

  async function resetPassword(
    token: string,
    password: string,
    passwordConfirmation: string
  ) {
    try {
      await axios.put(`${BASE_URL}/reset-password/${token}`, {
        password,
        passwordConfirmation,
      });
      return { error: null };
    } catch (error: any) {
      return { error: error.code };
    }
  }
  async function updatePassword(
    currentPassword: string,
    password: string,
    passwordConfirmation: string
  ) {
    try {
      await axios.patch(`${BASE_URL}/update-password`, {
        // currentPassword,
        password,
        passwordConfirmation,
      });
      return { error: null };
    } catch (error: any) {
      const {
        response: {
          data: { code },
        },
      } = error;
      return { error: code };
    }
  }

  async function verifyAccount() {}
  async function resendConfirmAccountEmail() {
    try {
      await axios.post(`${BASE_URL}/verify-account`);
      return { error: null };
    } catch (error: any) {
      const {
        response: {
          data: { code },
        },
      } = error;
      return { error: code };
    }
  }

  async function updateProfile(profile: any) {
    try {
      const {
        data: { body },
      } = await axios.patch(`/api/clients`, {
        ...profile,
        photoUrl: profile?.photoUrl || account.photoUrl,
        coverUrl: profile?.coverUrl | account.coverUrl,
      });
      setAccount({ ...account, ...body });
      

      return { error: null };
    } catch (error: any) {
      const {
        response: {
          data: { code },
        },
      } = error;
      return { error: code };
    }
  }

  async function updateProfilePictureAndCover({
    photoUrl,
    coverUrl,
  }: {
    photoUrl?: string;
    coverUrl?: string;
  }) {
    try {
      const {
        data: { body },
      } = await axios.put(`/api/clients`, {
        photoUrl: photoUrl || account.photoUrl,
        coverUrl: coverUrl || account.coverUrl,
      });
      setAccount({ ...account, ...body });
      return { error: null };
    } catch (error: any) {
      const {
        response: {
          data: { code },
        },
      } = error;
      return { error: code };
    }
  }

  async function refreshUploadedDocuments() {
    try {
      const {
        data: { body, message },
      } = await axios.get(`${BASE_URL}`);
      setAccount(body);
    } catch (error: any) {
      setAccount(null);
    }
  }

  return {
    account,
    isAuthenticated,
    isLoading,
    checkAuthentification,
    signIn,
    signUp,
    signOut,
    forgetPassword,
    resetPassword,
    updatePassword,
    resendConfirmAccountEmail,
    verifyAccount,
    updateProfile,
    updateProfilePictureAndCover,
    refreshUploadedDocuments,
  };
}

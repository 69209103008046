import React, { FC, useState } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import DatePicker from "./common/DatePicker";

const CashPaiement: FC<{
  excludedDays: number[];
  onSubmit: Function;
  nextStep: Function;
  prevStep: () => void;
}> = ({ excludedDays, onSubmit, nextStep, prevStep }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const handleSubmit = async () => {
    setError(false);
    setLoading(true);

    if (selectedDate) {
      const { data, error } = await onSubmit("CASH", {
        bookedDate: selectedDate,
      });
      if (error) {
        setError(true);
      }
      if (data) {
        nextStep();
      }
    }
    setLoading(false);
  };
  return (
    <Box bg={"white"} rounded={"md"} p={2}>
      <Flex justify={"center"} my={6}>
        <Box alignItems="flex-start" width={["100%", "50%"]}>
          <Heading size={"lg"} textAlign={"center"} my={4}>
            Choisissez une date de rendez-vous, vous aller recevoir un mail de
            confirmation avec l’adresse ou vous devez vous présenter
          </Heading>
          <DatePicker
            excludedDays={excludedDays || []}
            selectedDate={selectedDate || new Date()}
            onChange={(date: any) => setSelectedDate(date)}
          />
        </Box>
      </Flex>
      {/* <Alert status="error">
        <AlertIcon />
        Veuillez choisir une date
      </Alert> */}

      <SimpleGrid columns={[1, 2]} gap={2} my={4}>
        <Button
          onClick={prevStep}
          width={"full"}
          color={"primary"}
          bg={"white"}
        >
          Précédent
        </Button>
        <Button
          width={"full"}
          color={"white"}
          bg={"primary"}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          confirmer votre paiement
        </Button>
      </SimpleGrid>
    </Box>
  );
};

export default CashPaiement;

import React from "react";
import ProgramApplicationsPage from "../../components/pages/ProgramApplicationsPage/ProgramApplicationsPage";

const ProgramApplications = () => {
  return (
    <>
      <ProgramApplicationsPage />
    </>
  );
};

export default ProgramApplications;

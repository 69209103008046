import React, { FC, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import FileInput from "../common/FileInput";

const fileFormats = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "application/pdf": [".pdf"],
};

const UploadFileModal: FC<{
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onUpload: (file: File | null) => void;
  acceptedFormats?: any;
}> = ({ title, onClose, isOpen, onUpload, acceptedFormats = fileFormats }) => {
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const onDrop = (files: File[]) => {
    setFile(files[0]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onUpload(file);
    setFile(null);
    onClose();
    setLoading(false);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={"center"}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FileInput
            handleDrop={onDrop}
            uploadedFile={file}
            acceptedFiles={acceptedFormats}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            width={"full"}
            color={"white"}
            bg={"primary"}
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadFileModal;

// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// 👇️ ts-ignore ignores any ts errors on the next line
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  faNoteSticky,
  faPaperclip,
  faRotate,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../../contexts/ChatContext";
import { usePaiements } from "../../hooks/usePaiements";
import { useStorage } from "../../hooks/useStorage";
import FileInput from "../common/FileInput";

const DeletePaiementPopover: FC<{
  id: number;
  url: string;
}> = ({ id, url }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { deletePaiementById } = usePaiements();

  const handleSubmit = async () => {
    setLoading(true);
    const { error = null } = await deletePaiementById(id);
    setLoading(false);
    if (error)
      return toast({
        description: "An error occured, please try later.",
        position: "top",
        status: "error",
        isClosable: true,
      });
    toast({
      description: "Paiement re-initialisé avec succée.",
      position: "top",
      status: "info",
      isClosable: true,
    });
    navigate(url);
  };
  return (
    <Popover>
      <PopoverTrigger>
        <Button
          leftIcon={<FontAwesomeIcon icon={faTrash} />}
          bg={"primary"}
          color={"white"}
          w={"full"}
          my={2}
        >
          Re-initialiser votre paiement
        </Button>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>
          <FontAwesomeIcon icon={faRotate} />
        </PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody>
          <Text textAlign={"center"}>
            Voulez-vous vraiment re-initialiser votre paiement ?
          </Text>

          <Button
            bg={"primary"}
            color={"white"}
            width={"full"}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Confirmer
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default DeletePaiementPopover;

const config = {
  API_URL: "/api",
  S3_URL: " https://sdztravel-prod.ams3.digitaloceanspaces.com",
  S3_BUCKET: "sdztravel-prod",
  S3_ENDPOINT: "ams3.digitaloceanspaces.com",
  S3_ACCESS_KEY_ID: "DO00HEDJX4TYHJUGN6JF",
  S3_SECRET_ACCESS_KEY: "nKDEXB8alYo8MJevrn91WoV2DD5J/HmGaHcp0ihyqUw",
  STRIPE_PUBLISHABLE_KEY:
    "pk_live_51LxiVlDPAl4p8GaosxG3vwnxNh1SdHmlxrgpYuzJ2yAKstU200LqhsXnueqpNYr17snakcyQttOGNVfypQz6gTth00STTjdbmv",
  STRIPE_TEST_PUBLISHABLE_KEY:
    "pk_test_51LxiVlDPAl4p8GaoE2XVipat44BxlYRLWEzGB84fFjXFPoH6ryd1wcYBTxHRqtbJa5q0d8xJxAPhVwfJRLcAPo2F00Aox8dt58",
};

export default config;

import React from "react";
import ReactDOM from "react-dom/client";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MessengerCustomerChat from "react-messenger-customer-chat";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import MainProvider from "./contexts/MainContext";
import ProgramsProvider from "./contexts/ProgramsContext";
import VisasProvider from "./contexts/VisasContext";
import ChatProvider from "./contexts/ChatContext";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <MainProvider>
        <ProgramsProvider>
          <VisasProvider>
            <ChatProvider>
              <App />
            </ChatProvider>
          </VisasProvider>
        </ProgramsProvider>
      </MainProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState } from "react";
import { Flex, IconButton, Input } from "@chakra-ui/react";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChatContext } from "../../contexts/ChatContext";
import FileMessagePopover from "./FileMessagePopover";

const ChatInput = () => {
  const [value, setValue] = useState<string>("");
  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <ChatContext.Consumer>
      {({ handleSendMessage, selectedConversation }) => (
        <Flex gap={2}>
          <Input
            borderColor={"gray.800"}
            onChange={handleChange}
            value={value || ""}
            placeholder="ecrire votre mesage ici ..."
            // onKeyDown={onKeyboardPress}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if (value && value.length > 0)
                  handleSendMessage(
                    "MESSAGE",
                    value,
                    selectedConversation.doc_id
                  );
                setValue("");
              }
            }}
          />
          <FileMessagePopover />
          <IconButton
            bg={"primary"}
            color={"white"}
            aria-label="truee"
            onClick={async () => {
              if (value && value.length > 0)
                handleSendMessage(
                  "MESSAGE",
                  value,
                  selectedConversation.doc_id
                );
              setValue("");
            }}
            icon={<FontAwesomeIcon icon={faPaperPlane} />}
          />
        </Flex>
      )}
    </ChatContext.Consumer>
  );
};

export default ChatInput;

import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Heading } from "@chakra-ui/react";
import Select from "react-select";
import { ProgramsContext } from "../../contexts/ProgramsContext";
import { useProgramCountries } from "../../hooks/useProgramCountries";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import getFlagByCode from "../../helpers/getFlagByCode";
import { useNavigate } from "react-router-dom";
import { programsTypes } from "../../helpers/types";

const ProgramsSearchHomeForm = () => {
  const navigate = useNavigate();
  const { updateFilter } = useContext(ProgramsContext);
  const { getCountries } = useProgramCountries();
  // OPTIONS LISTS
  const [countries, setCountries] = useState<any>([]);
  const [establishments, setEstablishments] = useState<any>([]);

  // States
  const [country, setCountry] = useState<any>(null);
  const [establishmentType, setEstablishmentType] = useState<any>(null);
  const [establishment, setEstablishment] = useState<any>(null);
  const [programType, setProgramType] = useState<any>(null);

  const formatCountriesToOptionsList = (data: any) => {
    let options: any[] = [];
    data.map(
      ({
        code,
        name,
        displayUniversities,
      }: {
        code: string;
        name: string;
        displayUniversities: boolean;
      }) => {
        options.push({
          label: `${getFlagByCode(code)} - ${name}`,
          value: code,
          displayUniversities,
        });
      }
    );
    return options;
  };

  const getCountriesList = async () => {
    const { data, error } = await getCountries();

    const options = formatCountriesToOptionsList(data);
    setCountries(options);
  };

  const handleChangeCountry = async (e: any) => {
    setCountry(e);
  };

  const handleChangeEstablishmentType = async (e: any) => {
    setEstablishmentType(e);
  };

  const handleChangeProgramType = async (e: any) => {
    setProgramType(e);
  };

  const establishmentsTypes = [
    { label: "Université", value: "UNIVERSITY" },
    { label: "Ecole", value: "SCHOOL" },
  ];

  const inputs = [
    {
      options: countries,
      handleChange: handleChangeCountry,
      placeholder: "Choisir le pays ...",
      value: country,
    },
    {
      options: establishmentsTypes,
      handleChange: handleChangeEstablishmentType,
      placeholder: "université/école ...",
      value: establishmentType,
    },
    {
      options: programsTypes,
      handleChange: handleChangeProgramType,
      placeholder: "Bachelor / Master  ...",
      value: programType,
    },
  ];
  useEffect(() => {
    getCountriesList();
  }, []);
  return (
    <Box bg={"#DEE9F3"} px={4} py={6} rounded={"lg"}>
      <Heading size={"md"}>Postuler à un programme </Heading>
      {inputs.map(({ options, handleChange, placeholder, value }) => (
        <Box my={3} key={uuidv4()}>
          <Select
            options={options}
            onChange={handleChange}
            placeholder={placeholder}
            value={value}
          />
        </Box>
      ))}
      <Button
        width={"100%"}
        bg={"primary"}
        color={"white"}
        onClick={() => {
          updateFilter({
            countryCode: country?.value || "",
            establishmentType: establishmentType?.value || "",
            programType: programType?.value || "",
            establishmentId: "",
          });
          navigate("/programs");
        }}
        mt={7}
      >
        Trouver un programme
      </Button>
    </Box>
  );
};

export default ProgramsSearchHomeForm;

import { Avatar, Box, Heading, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import config from "../../config";
import { MainContext } from "../../contexts/MainContext";

const ProfileBiography = () => {
  const {
    account: {
      firstname,
      lastname,
      bibliography = "",
      photoUrl = "",
      account: { email },
    },
  } = useContext(MainContext);

  return (
    <Box textAlign={"center"} p={2}>
      <Avatar
        src={(photoUrl !== "" && `${config.S3_URL}/${photoUrl}`) || undefined}
        rounded={"full"}
        size={"lg"}
      />
      <Heading size={"md"}>
        {firstname} {lastname}
      </Heading>
      <Text fontSize={"md"} color={"gray.800"}>
        {email}
      </Text>
      <Text fontSize={"md"} color={"gray.600"} p={2}>
        {bibliography}
      </Text>
    </Box>
  );
};

export default ProfileBiography;

import axios from "axios";
import config from "../config";
const BASE_URL = `${config.API_URL}/administrative-support`;
export function useAdministrativeSupport() {
  const getAdministrativeSupport = async () => {
    const path = `${BASE_URL}`;
    try {
      const {
        data: { body },
      } = await axios.get(path);
      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };

  return { getAdministrativeSupport };
}

import { Divider, ListItem, UnorderedList } from "@chakra-ui/react";
import React, { FC } from "react";

const ListSidebar: FC<{ items: string[] }> = ({ items }) => {
  return (
    <UnorderedList p={6}>
      {items.map((item: string, index: number) => (
        <>
          <ListItem
            listStyleType={"none"}
            fontSize={"xl"}
            fontWeight={"bold"}
            color={"primary"}
            my={2}
          >
            {item}
          </ListItem>

          {Number(items.length) !== Number(index + 1) && (
            <Divider size={"lg"} bg={"gray.400"} />
          )}
        </>
      ))}
    </UnorderedList>
  );
};

export default ListSidebar;

import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import moment from "moment";
import React, { FC } from "react";
import shortenString from "../../helpers/shortenString";
import { IMessage, MessageType } from "../../helpers/types";
import FileItem from "../common/FileItem";

const Message: FC<IMessage> = ({ content, type, byAdmin, sendAt }) => {
  if (type === MessageType.MESSAGE) {
    if (byAdmin)
      return (
        <Tooltip label={moment(sendAt).format("llll")} aria-label="A tooltip">
          <Text
            bg={"#F1F1F1"}
            p={2}
            fontSize={"lg"}
            rounded={"lg"}
            my={1}
            color={"black"}
            display={"inline-block"}
            maxW={"80%"}
          >
            {content}
          </Text>
        </Tooltip>
      );
    if (!byAdmin)
      return (
        <Flex justifyContent={"flex-end"}>
          <Tooltip label={moment(sendAt).format("llll")} aria-label="A tooltip">
            <Text
              bg={"#0C76FF"}
              p={2}
              fontSize={"lg"}
              rounded={"lg"}
              my={1}
              color={"white"}
              display={"inline-block"}
              maxW={"80%"}
            >
              {content}
            </Text>
          </Tooltip>
        </Flex>
      );
  }

  if (type === MessageType.FILE) {
    if (byAdmin)
      return (
        <Box p={2} my={1} maxW={"80%"}>
          <Tooltip label={moment(sendAt).format("llll")} aria-label="A tooltip">
            <FileItem
              id={1}
              isEditable={false}
              isRequired={true}
              description={""}
              name={shortenString(content.replace("chats/", ""), 20)}
              currentDocumentUrl={content}
            />
          </Tooltip>
        </Box>
      );
    if (!byAdmin)
      return (
        <Flex justifyContent={"flex-end"}>
          <Text p={2} my={1} maxW={"80%"}>
            <Tooltip
              label={moment(sendAt).format("llll")}
              aria-label="A tooltip"
            >
              <FileItem
                id={1}
                isEditable={false}
                isRequired={true}
                description={""}
                name={shortenString(content.replace("chats/", ""), 20)}
                currentDocumentUrl={content}
              />
            </Tooltip>
          </Text>
        </Flex>
      );
  }

  return <Text>hh</Text>;
};

export default Message;

import axios from "axios";
import config from "../config";
const BASE_URL = `${config.API_URL}/establishments`;
export function useEstablishments() {
  async function getEstablishmentById(id: number) {
    const path = `${BASE_URL}/${id}`;
    try {
      const {
        data: { body },
      } = await axios.get(path);
      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  }

  async function getEstablishmentsByCountry(
    countryCode: string,
    type: "SCHOOL" | "UNIVERSITY"
  ) {
    const path = `${BASE_URL}?itemsPerPage=100&country=${countryCode}&type=${type}`;

    try {
      const {
        data: {
          body: { data },
        },
      } = await axios.get(path);
      return { data: data, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  }
  return { getEstablishmentById, getEstablishmentsByCountry };
}

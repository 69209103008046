import { ReactElement } from "react";

export interface INavItem {
  link?: string;
  content: string;
  active?: boolean;
  children?: { link: string; content: string }[];
  label?: number | null;
}

export interface IApplicationItem {
  imageUrl: string;
  items: { label: string | Function; accessor: string | Function }[];
  path: string;
  buttonText: "Consulter" | "Continuer";
  isDeleteEnabled: boolean;
  onDelete: Function;
}

export interface IFeature {
  title: string;
  text: string;
  icon: ReactElement;
}

export interface IConversation {
  fullname: string;
  photoUrl: string;
  title: string;
  updatedAt: string;
  country: string;
  status: "ACTIF" | "CLOSED";
  doc_id: string;

  readByUser: boolean;
  messages: IConversation[];
}

export interface IMessage {
  content: string;
  byAdmin: boolean;
  type: MessageType;
  sendAt: string;
}

export enum MessageType {
  FILE = "FILE",
  MESSAGE = "MESSAGE",
  CLOSED = "CLOSED",
}

export const programsTypes = [
  { label: "Année préparatoire", value: "PREPARATORY" },
  { label: "Licence", value: "BACHELOR" },
  { label: "Master", value: "MASTER" },
];

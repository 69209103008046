import {
  Box,
  Center,
  Divider,
  Flex,
  FormLabel,
  Image,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { faFileAlt, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useState } from "react";
import Dropzone from "react-dropzone";
import shortenString from "../../helpers/shortenString";

const fileFormats = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "application/pdf": [".pdf"],
  "application/doc": [".doc", ".docx"],
};

const FileInput: FC<{
  uploadedFile?: File | null;
  handleDrop: (files: File[]) => void;
  acceptedFiles?: any;
}> = ({ uploadedFile = null, handleDrop, acceptedFiles = fileFormats }) => {
  return (
    <Dropzone
      onDrop={handleDrop}
      accept={acceptedFiles}
      multiple={false}
      maxFiles={1}
    >
      {({ getRootProps, getInputProps }) => (
        <FormLabel
          width={"full"}
          height={"auto"}
          // bg={"red"}
          cursor={"pointer"}
          border={"1px"}
          rounded={"md"}
          py={4}
          my={4}
          {...getRootProps({
            onDrop: (event) => event.preventDefault(),
            onClick: (event) => event.preventDefault(),
          })}
        >
          <Center width={"full"} height={"auto"}>
            <Stack width={"auto"} height={"auto"}>
              <Box color={"secondary-2"} fontSize={"lg"} textAlign={"center"}>
                Déposez votre image ici, ou bien{""}
                <Text as={"p"} color={"black"}>
                  Telecharger
                </Text>
              </Box>
              <Text textAlign={"center"}>
                des fichier de type PDF, PNG, JPG or JPEG.
              </Text>
              <Divider />
              {uploadedFile && (
                <Flex alignItems="center" justifyContent="center">
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    size={"2x"}
                    color={"#0C76FF"}
                  />
                  <Text mx={2}>{shortenString(uploadedFile.name, 30)}</Text>
                </Flex>
              )}
            </Stack>
          </Center>
          <input type={"file"} {...getInputProps()} />
        </FormLabel>
      )}
    </Dropzone>
  );
};

export default FileInput;

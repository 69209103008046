// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// 👇️ ts-ignore ignores any ts errors on the next line
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AboutUs from "./AboutUs";
import administrativeSupportRoutes from "./AdministrativeSupport/administrativeSupportRoutes";

import ContactUs from "./ContactUs";
import Country from "./Country";
import ForgetPassword from "./ForgetPassword";
import Home from "./Home";
import Profile from "./Profile";
import programsRoutes from "./Program/programRoutes";
import ResetPassword from "./ResetPassword";
import Search from "./Search";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Establishment from "./Establishment";
import visaRoutes from "./Visa/visaRoutes";
import Chat from "./Chat";
import ChatProvider from "../contexts/ChatContext";
import PageNotFound from "./PageNotFound";
import EmailConfirmed from "./EmailConfirmed";
import UpdatePassword from "../components/pages/Auth/UpdatePassword";
import ConfirmEmail from "./ConfirmEmail";
import ConfidentialityTerms from "./ConfidentialityTerms";

const routes = [
  { path: "/", element: Home },
  // { path: "/chat", element: Chat, provider: ChatProvider, isProtected: true },
  { path: "/chat", element: Chat, isProtected: true },
  { path: "/about-us", element: AboutUs, provider: null },
  {
    path: "/confidentiality-terms",
    element: ConfidentialityTerms,
    provider: null,
  },
  { path: "/contact-us", element: ContactUs, provider: null },
  { path: "/confirm-email", element: ConfirmEmail, provider: null },
  { path: "/email-confirmed", element: EmailConfirmed, provider: null },
  { path: "/country/:code", element: Country, provider: null },
  { path: "/establishment/:id", element: Establishment, provider: null },
  { path: "/search", element: Search, provider: null },
  { path: "/me", element: Profile, isProtected: true, provider: null },
  { path: "/sign-up", element: SignUp, provider: null },
  { path: "/sign-in", element: SignIn, provider: null },
  { path: "/forget-password", element: ForgetPassword, provider: null },
  { path: "/update-password", element: UpdatePassword, provider: null },
  { path: "/reset-password/:token", element: ResetPassword, provider: null },
  ...visaRoutes,
  ...programsRoutes,
  ...administrativeSupportRoutes,
  { path: "/not-found", element: PageNotFound, provider: null },
  { path: "*", element: PageNotFound, provider: null },
];
export default routes;

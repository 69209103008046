export const header = [
  {
    content: "Programmes",
    children: [
      { link: "/programs", content: "Trouver un programme" },
      { link: "/programs/applications", content: "Mes candidatures" },
    ],
  },
  {
    content: "Visa",
    children: [
      { link: "/visas", content: "Faire une demande" },
      { link: "/visas/applications", content: "Mes candidatures" },
    ],
  },
  {
    content: "Procédure Campus France",
    children: [
      { link: "/administrative-support", content: "Demande de suivi" },
      {
        link: "/administrative-support/applications",
        content: "Mes candidatures",
      },
    ],
  },

  { link: "/chat", content: "Messagerie" },
];
export const authenticatedHeader = [
  {
    link: "/programs",
    content: "Programmes",
  },

  { link: "/visas", content: "Visa" },
  { link: "/administrative-support", content: "Procédure Campus France" },
  // { link: "/about-us", content: "About us" },
  { link: "/contact-us", content: "Contactez-nous" },
];

export const footerLists = [
  {
    header: "Product",
    items: [
      { link: "/#overview", content: "Overview" },

      { link: "/#tutorials", content: "Tutorials" },
    ],
  },
  {
    header: "",
    items: [
      // { link: "/about-us", content: "About Us" },

      { link: "/contact-us", content: "Contactez-nous" },
      { link: "/confidentiality-terms", content: "Confidentiality Terms" },
    ],
  },
];

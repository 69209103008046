import React, { useContext, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Alert,
  AlertIcon,
  FormErrorMessage,
  Text,
  Divider,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate, Link as ReachLink } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import _ from "lodash";

const SignInForm = () => {
  const navigate = useNavigate();
  const { signIn } = useContext(MainContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const { error } = await signIn(data.email, data.password);
    if (!error) {
      navigate("/");
      setSuccess(true);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const inputs = [
    {
      label: "Adresse Email",
      name: "email",
      type: "text",
      validation: {
        required: true,
      },
      errorMessage: "Please enter a valid email.",
    },
    {
      label: "Mot de passe",

      name: "password",
      type: "password",
      validation: {
        required: true,
        minLength: 8,
      },
      errorMessage: "Please enter a valid password (8 characters at least).",
    },
  ];

  return (
    <Box rounded={"lg"} boxShadow={"lg"} p={8}>
      <Stack spacing={2}>
        {error && (
          <Alert status="error">
            <AlertIcon />
            Identifiants erronés, veuillez réessayer.
          </Alert>
        )}
        {success && (
          <Alert status="error">
            <AlertIcon />
            vous etes connecté avec succée.
          </Alert>
        )}
        {inputs.map(({ name, type, validation, errorMessage, label }) => (
          <FormControl id={name} isInvalid={!_.isNil(errors[name])} isRequired>
            <FormLabel>{label}</FormLabel>
            <Input type={type} {...register(name, { ...validation })} />
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
          </FormControl>
        ))}

        <Stack spacing={6}>
          <Stack
            direction={{ base: "column", sm: "row" }}
            align={"start"}
            justify={"space-between"}
          >
            <Link color={"primary"} as={ReachLink} to="/forget-password">
              Mot de passe oublié?
            </Link>
          </Stack>
          <Button
            bg={"primary"}
            color={"white"}
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            S'identifier
          </Button>
        </Stack>
      </Stack>
      <Divider my={2} h={4} />
      <Box my={2}>
        <Text textAlign={"center"}>Vous n'avez pas de compte ?</Text>
        <Button
          bg={"secondary-2"}
          color={"white"}
          width={"full"}
          onClick={() => navigate("/sign-up")}
        >
          S'inscrire maintenant
        </Button>
      </Box>
    </Box>
  );
};

export default SignInForm;

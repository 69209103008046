import { BellIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Box,
  Flex,
  Icon,
  IconButton,
  IconProps,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

const Blob = (props: IconProps) => {
  return (
    <Icon
      width={"100%"}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};

const Video = () => (
  <AspectRatio
    my={8}
    height={"400px"}
    roundedBottomEnd={"lg"}
    width={"full"}
    id="video"
  >
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/PLoxFuoxfaU"
      title="YouTube video player"
      // frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      // allowfullscreen
    ></iframe>
  </AspectRatio>
  // <Flex
  //   my={8}
  //   flex={1}
  //   justify={"center"}
  //   align={"center"}
  //   position={"relative"}
  //   w={"full"}
  //   __css={{ zIndex: -1 }}
  // >
  //   <Blob
  //     w={"150%"}
  //     h={"150%"}
  //     position={"absolute"}
  //     top={"-20%"}
  //     left={0}
  //     zIndex={-1}
  //     color={useColorModeValue("red.50", "red.400")}
  //   />
  //   <Box
  //     position={"relative"}
  //     height={"400px"}
  //     rounded={"2xl"}
  //     // boxShadow={"2xl"}
  //     width={"full"}
  //     overflow={"hidden"}
  //   >
  //     <IconButton
  //       aria-label={"Play Button"}
  //       variant={"ghost"}
  //       _hover={{ bg: "transparent" }}
  //       icon={<BellIcon w={12} h={12} />}
  //       size={"lg"}
  //       color={"white"}
  //       position={"absolute"}
  //       left={"50%"}
  //       top={"50%"}
  //       transform={"translateX(-50%) translateY(-50%)"}
  //     />
  //     <Image
  //       alt={"Hero Image"}
  //       fit={"cover"}
  //       align={"center"}
  //       w={"100%"}
  //       h={"100%"}
  //       src={
  //         "https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80"
  //       }
  //     />
  //   </Box>
  // </Flex>
);

export default Video;

import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  IconButton,
  Text,
} from "@chakra-ui/react";
import {
  faDownload,
  faDownLong,
  faFile,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { FC } from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import shortenString from "../../helpers/shortenString";

const FileItem: FC<{
  id: number;
  name: string;
  description: string;
  isRequired: boolean;
  isEditable?: boolean;
  currentDocumentUrl?: string | null;
  uploadedFileName?: string | null;
  handleOpenModal?: (id: number) => void;
}> = ({
  id,
  name,
  description,
  isRequired,
  isEditable = true,
  currentDocumentUrl = null,
  uploadedFileName = null,
  handleOpenModal = (id: number) => {},
}) => {
  const selectColor = () => {
    if (uploadedFileName) return "orange.400";
    if (currentDocumentUrl) return "green.400";
    if (!isRequired) return "yellow.400";
    return "red.400";
  };
  return (
    <>
      <Flex my={2} justifyContent={"space-between"} alignItems={"center"}>
        <Flex alignItems={"center"}>
          <Box color={selectColor()} p={4}>
            <FontAwesomeIcon icon={faFileAlt} size={"2x"} />
          </Box>
          <Box mx={2}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              {" "}
              <div>
                {name}&nbsp;&nbsp;{!isRequired && <>( optionnel )</>}
                {uploadedFileName && (
                  <Text textDecoration={"underline"}>
                    ({shortenString(uploadedFileName, 30)})
                  </Text>
                )}
              </div>
            </Flex>
            <Text fontSize={"sm"}>{description}</Text>
          </Box>
        </Flex>
        <Flex>
          {isEditable && (
            <Box>
              <Button
                bg={"primary"}
                color={"white"}
                mx={1}
                onClick={() => handleOpenModal(id)}
              >
                Modifier
              </Button>
            </Box>
          )}
          {!uploadedFileName && currentDocumentUrl && (
            <a
              href={`${config.S3_URL}/${currentDocumentUrl}`}
              target={"_blank"}
            >
              <IconButton
                aria-label="true"
                color={"white"}
                bg={"primary"}
                icon={<FontAwesomeIcon icon={faDownload} />}
              />
            </a>
          )}
        </Flex>
      </Flex>
      <Divider />
    </>
  );
};

export default FileItem;

import SearchVisa from "./SearchVisa";
import Visa from "./Visa";
import VisaApplication from "./VisaApplication";
import VisaApplicationDetails from "./VisaApplicationDetails";
import VisaApplications from "./VisaApplications";

const visaRoutes = [
  { path: "/visas/", element: SearchVisa, provider: null },
  { path: "/visas/:id", element: Visa, provider: null },
  {
    path: "/visas/applications",
    element: VisaApplications,
    isProtected: true,
    provider: null,
  },
  {
    path: "/visas/applications/:id",
    element: VisaApplicationDetails,
    isProtected: true,
    provider: null,
  },
  {
    path: "/visas/applications/:visaId/apply",
    element: VisaApplication,
    isProtected: true,
    provider: null,
  },
  {
    path: "/visas/applications/:visaId/apply/:applicationId",
    element: VisaApplication,
    isProtected: true,
    provider: null,
  },
];
export default visaRoutes;

import React, { useContext, useEffect, useRef } from "react";
import {
  Alert,
  Badge,
  Box,
  Center,
  Container,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import CardItem from "../components/CardItem/CardItem";
import ProgramsSearchForm from "../components/forms/ProgramsSearchForm";
import { ProgramsContext } from "../contexts/ProgramsContext";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import isBetween from "../helpers/isBetween";
import config from "../config";
import shortenString from "../helpers/shortenString";

const Search = () => {
  const navigate = useNavigate();
  const { data, isLoading, error } = useContext(ProgramsContext);

  const handleClick = (id: number) => {
    navigate(`/programs/${id}`);
  };

  const topRef: any = useRef();

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div ref={topRef} />
      <Box my={10}>
        <Box bg={"gray.100"} py={8}>
          <Container maxW={"6xl"}>
            <Box my={4}>
              {/* <Text>Find program</Text> */}
              <Heading fontSize={"lg"}>Trouver mon programme</Heading>
              <Text fontSize={"sm"}>
                choisir le pays auquel vous souhaitez postuler, et le type de
                programme
              </Text>
            </Box>
            <ProgramsSearchForm />
          </Container>
        </Box>
        <Container maxW={"6xl"}>
          {isLoading && (
            <Center height={"100vh"}>
              <Spinner size={"xl"} />
            </Center>
          )}
          {!error && !isLoading && data.length === 0 && (
            <Center height={"100vh"}>
              <Box>
                <Text textAlign={"center"}>
                  Aucun programme trouvé ,<br />
                  Veuillez re-éssayer !
                </Text>
                <Image src={"/search.svg"} w={80} />
              </Box>
            </Center>
          )}
          {!error && !isLoading && data.length > 0 && (
            <SimpleGrid columns={5} spacing={2}>
              {/* <GridItem colSpan={1}></GridItem> */}
              <GridItem colSpan={4}>
                {/* {error && <Alert>error happened please try again</Alert>} */}
                {data.map((item: any) => (
                  <CardItem
                    key={uuidv4()}
                    pictureUrl={
                      (item?.pictureUrl &&
                        `${config.S3_URL}/${item.pictureUrl}`) ||
                      "/default-placeholder.png"
                    }
                    handleClick={() => {
                      handleClick(item.id);
                    }}
                    subChildren={
                      <>
                        Prix a payer :<br />
                        {/* <b>{item.priceDZD} DA</b> ou */}
                        <b>{item.priceEUR} Euros</b>
                      </>
                    }
                  >
                    <Box
                      justifyContent={"space-between"}
                      height={"full"}
                      w={"100%"}
                    >
                      <Heading size={"md"}>{item.name}</Heading>
                      <Text fontSize={"sm"} w={"100%"}>
                        {shortenString(item.shortDescription, 100)}
                      </Text>
                      <Box>
                        {item.fees.map(
                          ({
                            label,
                            value,
                          }: {
                            label: string;
                            value: string;
                          }) => (
                            <Box>
                              <Badge mx={1} whiteSpace={"normal"}>
                                {label} : {value}
                              </Badge>
                            </Box>
                          )
                        )}
                      </Box>

                      <Text fontSize={"sm"}>
                        <Badge
                          bg={
                            !isBetween(
                              item.registrationStartDate,
                              item.registrationEndDate
                            )
                              ? "red.200"
                              : "green.200"
                          }
                          rounded={"md"}
                        >
                          {!isBetween(
                            item.registrationStartDate,
                            item.registrationEndDate
                          )
                            ? "inscription fermé"
                            : "inscription ouverte"}
                        </Badge>{" "}
                        :
                        {moment(item.registrationStartDate).isAfter() &&
                          " seront ouverte"}{" "}
                        du{" "}
                        {moment(item.registrationStartDate).format(
                          "DD-MM-YYYY"
                        )}{" "}
                        au &nbsp;
                        {moment(item.registrationEndDate).format("DD-MM-YYYY")}
                      </Text>
                    </Box>
                  </CardItem>
                ))}
              </GridItem>
            </SimpleGrid>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Search;

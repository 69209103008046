import axios from "axios";
import config from "../config";
const BASE_URL = `${config.API_URL}/visa-countries`;

export function useVisaCountries() {
  const getCountries = async () => {
    const path = `${BASE_URL}?itemsPerPage=100`;
    try {
      const {
        data: {
          body: { data },
        },
      } = await axios.get(path);
      return { data: data, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };
  return { getCountries };
}

import axios from "axios";
import { useEffect, useState } from "react";
import config from "../config";

const BASE_URL = `${config.API_URL}/visas`;
export function useVisas() {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>({
    countryCode: "",
    type: "",
  });

  useEffect(() => {
    getData();
  }, [filter.countryCode]);

  async function init() {
    setLoading(false);
    setError(false);
    setFilter({ countryCode: "", type: "" });
    setData([]);
  }

  const getData = async () => {
    setLoading(true);
    setError(false);

    if (filter.countryCode !== "") {
      const path = `${BASE_URL}?itemsPerPage=100&${
        filter.countryCode.length > 0 ? "country=" + filter.countryCode : ""
      }${filter.type.length > 0 ? "&type=" + filter.type : ""}`;
      try {
        const {
          data: {
            body: { data },
          },
        } = await axios.get(path);
        setData(data);
        setLoading(false);
      } catch (error) {
        setError(true);
      }
    } else {
      setData([]);
    }
    setLoading(false);
  };

  async function get(id: number) {
    const path = `${BASE_URL}/${id}`;

    try {
      const {
        data: { body },
      } = await axios.get(path);

      return { data: body, error: null };
    } catch (error) {
      return {
        error,
        data: null,
      };
    }
  }

  async function getVisaById(id: number) {
    const path = `${BASE_URL}/${id}`;

    try {
      const {
        data: { body },
      } = await axios.get(path);

      return { data: body, error: null };
    } catch (error) {
      return {
        error,
        data: null,
      };
    }
  }

  function updateFilter(attribute: string, value: string) {
    setFilter({ ...filter, [attribute]: value });
  }
  return { data, isLoading, error, init, updateFilter, getVisaById, get };
}

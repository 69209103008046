import React, { FC } from "react";
import {
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import NavItem from "./NavItem";
import { INavItem } from "../../../helpers/types";
import { useLocation } from "react-router-dom";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

const NavBar: FC<{ items: INavItem[]; unreadConversations: number }> = ({
  items,
  unreadConversations,
}) => {
  const location = useLocation();

  return (
    <HStack spacing={8} alignItems={"center"}>
      <HStack as={"nav"} spacing={3} display={{ base: "none", md: "flex" }}>
        {items.map(
          ({ link = null, content, children = null, label = null }) => {
            return (
              <>
                {children && (
                  <>
                    <Menu>
                      <MenuButton
                        _hover={{
                          textDecoration: "none",
                          borderColor: "red.100",
                          borderBottom: "4px",
                        }}
                        _focus={{
                          outline: "none",
                        }}
                        // as={ReachMenuButton}
                        px={2}

                        // color="primary"
                      >
                        <HStack>
                          <Text py={2} fontSize="md" fontWeight={"extrabold"}>
                            {content}
                          </Text>
                          <Icon
                            as={ChevronDownIcon}
                            transition={"all .25s ease-in-out"}
                            color="black"
                            w={6}
                            h={6}
                          />
                        </HStack>
                      </MenuButton>

                      <MenuList>
                        {children.map(({ link, content }) => (
                          <MenuItem
                            as={Link}
                            to={link}
                            fontWeight={"extrabold"}
                          >
                            {content}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </>
                )}
                {link && (
                  <NavItem
                    key={uuidv4()}
                    link={link}
                    content={content}
                    label={link === "/chat" ? unreadConversations : null}
                    active={
                      location.pathname === "/"
                        ? false
                        : link.includes(location.pathname)
                    }
                  />
                )}
              </>
            );
          }
        )}
      </HStack>
    </HStack>
  );
};

export default NavBar;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import ProgramsSearchHomeForm from "../../forms/ProgramsSearchHomeForm";
import VisasSearchHomeForm from "../../forms/VisasSearchHomeForm";
import { useNavigate } from "react-router-dom";
import { useAdministrativeSupport } from "../../../hooks/useAdministrativeSupport";
import shortenString from "../../../helpers/shortenString";

const SearchCards = () => {
  const navigate = useNavigate();
  const { getAdministrativeSupport } = useAdministrativeSupport();
  const [data, setData] = useState<any>();
  const getData = async () => {
    const { data } = await getAdministrativeSupport();

    setData(data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      width={"100%"}
      height={"auto"}
      position={["static", "static", "absolute"]}
      top={[0, 0, -100]}
      my={[10, 10, 0]}
    >
      <Container maxW={"5xl"}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={[5, 10]}>
          <ProgramsSearchHomeForm />
          <VisasSearchHomeForm />
          <Box bg={"#DEE9F3"} px={4} py={6} rounded={"lg"}>
            <Heading size={"md"}>{data?.title}</Heading>
            <Text fontSize={"md"} my={2}>
              {data?.shortDescription &&
                shortenString(data?.shortDescription, 100)}
            </Text>

            <Button
              width={"100%"}
              bg={"primary"}
              color={"white"}
              onClick={() => {
                navigate("/administrative-support");
              }}
              mt={40}
            >
              Postuler
            </Button>
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default SearchCards;

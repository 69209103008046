import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Heading } from "@chakra-ui/react";
import Select from "react-select";
import { ProgramsContext } from "../../contexts/ProgramsContext";
import { useProgramCountries } from "../../hooks/useProgramCountries";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import getFlagByCode from "../../helpers/getFlagByCode";
import { useNavigate } from "react-router-dom";
import { useVisaCountries } from "../../hooks/useVisaCountries";
import { VisasContext } from "../../contexts/VisasContext";

const VisasSearchHomeForm = () => {
  const navigate = useNavigate();
  const { updateFilter } = useContext(VisasContext);
  const { getCountries } = useVisaCountries();
  // OPTIONS LISTS
  const [countries, setCountries] = useState<any>([]);

  // States
  const [country, setCountry] = useState<any>(null);

  const formatCountriesToOptionsList = (data: any) => {
    let options: any[] = [];
    data.map(
      ({
        code,
        name,
        displayUniversities,
      }: {
        code: string;
        name: string;
        displayUniversities: boolean;
      }) => {
        options.push({
          label: `${getFlagByCode(code)} - ${name}`,
          value: code,
          displayUniversities,
        });
      }
    );
    return options;
  };

  const formatVisasToOptionsList = (data: any) => {
    let options: any[] = [];
    data.map(({ id, type }: { id: number; type: string }) => {
      options.push({
        label: `Visa ${type}`,
        value: id,
      });
    });
    return options;
  };

  const getCountriesList = async () => {
    const { data, error } = await getCountries();

    const options = formatCountriesToOptionsList(data);

    setCountries(options);
  };

  const handleChangeCountry = async (e: any) => {
    setCountry(e);

    updateFilter("countryCode", e.value);
  };

  const inputs = [
    {
      options: countries,
      handleChange: handleChangeCountry,
      placeholder: "Choisir le pays ...",
      value: country,
    },
  ];
  useEffect(() => {
    getCountriesList();
  }, []);
  return (
    <Box
      bg={"#DEE9F3"}
      px={4}
      py={6}
      rounded={"lg"}
      flexDir={"column"}
      justifyContent={"space-between"}
      height={"full"}
    >
      <Heading size={"md"}>Demande de visa</Heading>
      {inputs.map(({ options, handleChange, placeholder, value }) => (
        <Box my={3} key={uuidv4()}>
          <Select
            options={options}
            onChange={handleChange}
            placeholder={placeholder}
            value={value}
          />
        </Box>
      ))}
      <Button
        width={"100%"}
        bg={"primary"}
        color={"white"}
        onClick={() => {
          navigate("/visas");
        }}
        alignSelf={"end"}
        mt={32}
      >
        Faire une demande
      </Button>
    </Box>
  );
};

export default VisasSearchHomeForm;

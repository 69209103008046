import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Container,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { faq } from "../data/faq";

const AboutUs = () => {
  return (
    <Container maxW={"7xl"}>
      <Center width={"full"} height={"70vh"}>
        <Box textAlign="center" py={10} px={6}>
          <Heading as="h2" size="xl" mt={6} mb={2} color={"primary"}>
            About our company
          </Heading>
          <Text color={"gray.500"} width={["95%", "50%"]} mx={"auto"}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </Text>
          <Button bg={"primary"} color={"white"} my={1}>
            Let’s discover
          </Button>
        </Box>
      </Center>
      <SimpleGrid columns={3} gap={4}>
        <GridItem
          colSpan={1}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          borderBottom={"4px"}
          borderBottomColor={"#0C76FF"}
          py={4}
        >
          <Image src={"/earth.png"} />
          <Heading
            as="h2"
            size="md"
            mt={6}
            mb={2}
            color={"primary"}
            px={8}
            textAlign={"center"}
          >
            Vitae malesuada lectus pulvinar faucibus
          </Heading>
        </GridItem>
        <GridItem
          colSpan={1}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          borderBottom={"4px"}
          borderBottomColor={"#0C76FF"}
          py={4}
        >
          <Image src={"/university.png"} />
          <Heading
            as="h2"
            size="md"
            mt={6}
            mb={2}
            color={"primary"}
            px={8}
            textAlign={"center"}
          >
            Vitae malesuada lectus pulvinar faucibus
          </Heading>
        </GridItem>{" "}
        <GridItem
          colSpan={1}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          borderBottom={"4px"}
          borderBottomColor={"#0C76FF"}
          py={4}
        >
          <Image src={"/business-progress.png"} />
          <Heading
            as="h2"
            size="md"
            mt={6}
            mb={2}
            color={"primary"}
            px={8}
            textAlign={"center"}
          >
            Vitae malesuada lectus pulvinar faucibus
          </Heading>
        </GridItem>
      </SimpleGrid>

      {/* --------------------------- FAQ ---------------------------  */}
      <Center height={"60vh"} my={24}>
        <Accordion width={"full"} px={4}>
          {faq.map(({ question, answer }) => (
            <AccordionItem width={"full"}>
              <h2>
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    color={"primary"}
                    fontWeight={"bold"}
                  >
                    {question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>{answer}</AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        <Image
          alt={"Hero Image"}
          fit={"cover"}
          align={"center"}
          roundedTopLeft={["3xl", 80]}
          roundedBottomRight={["3xl", 80]}
          roundedTopRight={"3xl"}
          roundedBottomLeft={"3xl"}
          w={["100%", "100%", "50%"]}
          h={"100%"}
          src={
            "https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80"
          }
        />
      </Center>
    </Container>
  );
};

export default AboutUs;

import { Badge } from "@chakra-ui/react";
import React, { FC } from "react";

const PaiementStatus: FC<{
  status: "NOT_PAIED" | "PENDING" | "ACCEPTED" | "REFUSED";
}> = ({ status }) => {
  const color = {
    NOT_PAIED: "yellow",
    PENDING: "blue",
    ACCEPTED: "green",
    REFUSED: "red",
  };

  const text = {
    NOT_PAIED: "not paied",
    PENDING: "envoyé",
    ACCEPTED: "accepté",
    REFUSED: "refusé",
  };
  return (
    <Badge colorScheme={color[status]} fontSize={"md"} p={1} rounded={"md"}>
      {text[status]}
    </Badge>
  );
};

export default PaiementStatus;

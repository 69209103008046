// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// 👇️ ts-ignore ignores any ts errors on the next line
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCA8BFsETh9EGB1z_3oaAzOfJuD9zuDdPI",
  authDomain: "sdztravel-b0e05.firebaseapp.com",
  projectId: "sdztravel-b0e05",
  storageBucket: "sdztravel-b0e05.appspot.com",
  messagingSenderId: "292129820389",
  appId: "1:292129820389:web:8627f3fb2efa729f580e0c",
  measurementId: "G-PKVHQNZ5VY",
};

const firebaseDevConfig = {
  apiKey: "AIzaSyAPhS9aPXDvB0ePgnXGyFzYKvmQ6Ng-770",
  authDomain: "sdztravel-dev.firebaseapp.com",
  projectId: "sdztravel-dev",
  storageBucket: "sdztravel-dev.appspot.com",
  messagingSenderId: "55634639686",
  appId: "1:55634639686:web:9d4fc3cfa3fd7dbfb34347",
};

// const analytics = getAnalytics(app);
firebase.initializeApp(firebaseConfig);
export const firestore_db = firebase.firestore();

import React, { FC, useContext } from "react";
import { Box, Center, Heading } from "@chakra-ui/react";
import Conversation from "./Conversation";
import { IConversation } from "../../helpers/types";
import { ChatContext } from "../../contexts/ChatContext";
import moment from "moment";
import _ from "lodash";

const ChatSidebar: FC<{
  onSidebarClose: Function;
}> = ({ onSidebarClose }) => {
  return (
    <ChatContext.Consumer>
      {({ conversations, selectedConversation, handleSelectConversation }) => (
        <Box my={3} height={["90vh", "70vh"]} overflowY={"scroll"} p={2}>
          <Heading size={"md"}>Vos conversations </Heading>
          {conversations.length === 0 && (
            <Center height={"full"} textAlign={"center"}>
              Aucune messagerie n'est encore creé.
            </Center>
          )}
          {conversations.length > 0 &&
            conversations.map((conversation: IConversation) => (
              <Conversation
                conversation={conversation}
                selectedConversationId={selectedConversation?.doc_id || null}
                onSidebarClose={onSidebarClose}
              />
            ))}
        </Box>
      )}
    </ChatContext.Consumer>
  );
};

export default ChatSidebar;

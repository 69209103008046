import React, { FC, useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import config from "../config";
import CheckoutForm from "./forms/CheckoutForm";
import axios from "axios";
import { Center, Spinner } from "@chakra-ui/react";
const stripePromise = loadStripe(`${config.STRIPE_PUBLISHABLE_KEY}`);

const StripePaiement: FC<{
  onSubmit: Function;
  nextStep: Function;
  prevStep: () => void;
  handleGetPaiementIntent: Function;
}> = ({ onSubmit, nextStep, prevStep, handleGetPaiementIntent }) => {
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const getIntent = async () => {
    setLoading(true);
    const { data, error } = await handleGetPaiementIntent();
    console.log("error", error);

    setClientSecret(data.clientSecret);
    setLoading(false);
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    getIntent();
  }, []);

  const handleSubmit = async () => {
    await onSubmit("ON_LINE");
  };

  return (
    <div>
      {isLoading && (
        <Center width={"full"} height={"full"}>
          <Spinner color="primary" width={30} height={30} size={"lg"} />
        </Center>
      )}
      {clientSecret && (
        <Elements
          options={{ clientSecret, appearance: { theme: "stripe" } }}
          stripe={stripePromise}
        >
          <CheckoutForm
            onSubmit={handleSubmit}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        </Elements>
      )}
    </div>
  );
};

export default StripePaiement;

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useNavigate, useParams } from "react-router-dom";
import { useVisas } from "../../hooks/useVisas";
import { useVisaApplications } from "../../hooks/useVisaApplications";
import Stepper from "../../components/pages/ProgramApplication/Stepper";
import { visaApplicationInputs } from "../../data/applicationInputs";
import { getCountry } from "../../helpers/getCountry";
import { MainContext } from "../../contexts/MainContext";
import _ from "lodash";

const VisaApplicationPage = () => {
  let { visaId, applicationId = null } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { account } = useContext(MainContext);

  const { getVisaById } = useVisas();
  const {
    createApplication,
    getApplicationById,
    getApplicationByVisaId,
    submitApplicationWithPaiement,
    getPaiementIntent,
  } = useVisaApplications();

  const [documents, setDocuments] = useState<any[]>([]);
  const [data, setData] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(true);
  const [initialStep, setInitialStep] = useState<number>(0);
  const [application, setApplication] = useState<any>(null);

  const getVisaData = async () => {
    const { data, error } = await getVisaById(Number(visaId));

    const { documents } = data;
    setData(data);
    setDocuments(documents);
    setLoading(false);
  };

  const getApplication = async () => {
    if (applicationId) {
      const { data, error } = await getApplicationById(Number(applicationId));
      if (error) return setError(true);
      if (
        data.status === "PENDING" ||
        (data.status === "SUBMITTED" && _.isNull(data?.paiement))
      ) {
        setApplication(data);
        getVisaData();
        return setInitialStep(1);
      }
      if (data.status !== "PENDING" || !_.isNull(data?.paiement)) {
        toast({
          title: "Il existe déja une candidature en ce programme.",
          status: "info",
          position: "top",
          containerStyle: { fontWeight: "bold" },
        });
        return navigate(`/visas/applications/${applicationId}`);
      }
    }
    if (visaId) {
      const { data, error } = await getApplicationByVisaId(Number(visaId));
      if (data) {
        applicationId = data;
        getApplication();
      }

      if (error) {
        setInitialStep(0);
        getVisaData();
      }
    }
  };

  const handleCreateApplication = async (id: number, level: string) => {
    const { data } = await createApplication(id, level);
    setApplication(data);
  };

  const handleGetPaiementIntent = async () => {
    return await getPaiementIntent(Number(application.id));
  };

  const handleSubmit = async (
    type: "CASH" | "DEPOSIT" | "ON_LINE",
    temp: { proofUrl?: string; bookedDate?: string }
  ) => {
    return await submitApplicationWithPaiement(
      Number(application.id),
      type,
      temp
    );
  };

  useEffect(() => {
    if (!account?.verified) return navigate("/confirm-email");
    getApplication();
  }, []);

  if (isLoading)
    return (
      <Center width={"100vw"} height={"100vh"}>
        <Spinner color="primary" width={30} height={30} size={"lg"} />
      </Center>
    );

  return (
    <Box mt={10} py={12} bg={"gray.100"}>
      <Container maxWidth={"6xl"}>
        <Box>
          <Heading size={"lg"}>
            Visa {data?.type} au {data?.country?.name}{" "}
            {getCountry(data.country.code).flag}
          </Heading>
        </Box>
        {/* ---------------------------------------------------------- */}
        <Stepper
          id={Number(visaId)}
          applicationId={Number(applicationId) || null}
          filesNotes={data?.filesNotes || ""}
          documents={documents}
          initialStep={initialStep}
          inputs={visaApplicationInputs}
          levelForm={false}
          onCreateApplication={handleCreateApplication}
          priceDZD={data.priceDZD}
          priceEUR={data.priceEUR}
          refused={false}
          onSubmit={handleSubmit}
          getIntnet={handleGetPaiementIntent}
          url={`/visas/applications/${application?.id || ""}`}
        />
      </Container>
    </Box>
  );
};

export default VisaApplicationPage;

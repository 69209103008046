import React from "react";
import { Flex, Stack, Heading } from "@chakra-ui/react";
import ResetPasswordForm from "../../forms/ResetPasswordForm";

const ResetPasswordPage = () => {
  return (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        // bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={4} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>
              Re-installer votre mot de passe :
            </Heading>
          </Stack>
          <ResetPasswordForm />
        </Stack>
      </Flex>
    </Flex>
  );
};

export default ResetPasswordPage;

import React from "react";
import { useAuth } from "../hooks/useAuth";

export const MainContext = React.createContext<any | null>(null);
const MainProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    account,
    isAuthenticated,
    isLoading,
    checkAuthentification,
    signIn,
    signUp,
    signOut,
    updatePassword,
    updateProfile,
    refreshUploadedDocuments,
  } = useAuth();
  return (
    <MainContext.Provider
      value={{
        account,
        isAuthenticated,
        isLoading,
        checkAuthentification,
        signIn,
        signUp,
        signOut,
        updateProfile,
        updatePassword,
        refreshUploadedDocuments,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default MainProvider;

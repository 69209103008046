import React, { FC, useContext, useState } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
  useToast,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import _ from "lodash";
export const UpdatePasswordForm = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { updatePassword } = useContext(MainContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    setError(false);
    setSuccess(false);
    if (data.password !== data.passwordConfirmation) {
      setError(true);
      setLoading(false);
    }

    const { error } = await updatePassword(
      data.currentPassword,
      data.password,
      data.passwordConfirmation
    );

    if (!error) {
      toast({
        description: "Votre mot de passe a été modifié avec succée",
        duration: 5000,
        status: "success",
      });
      setSuccess(true);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const inputs = [
    {
      label: "Ancien mot de passe",
      name: "currentPassword",
      type: "password",
      validation: {
        required: true,
        minLength: 8,
      },
      errorMessage: "Please enter a valid email.",
    },
    {
      label: "Nouveau Mot de passe",
      name: "password",
      type: "password",
      validation: {
        required: true,
        minLength: 8,
      },
      errorMessage: "Please enter a valid password (8 characters at least).",
    },
    {
      label: "Confirmation Nouveau Mot de passe",
      name: "passwordConfirmation",
      type: "password",
      validation: {
        required: true,
        minLength: 8,
      },
      errorMessage: "Please enter a valid password (8 characters at least).",
    },
  ];
  return (
    <Box rounded={"lg"} boxShadow={"lg"} p={8}>
      <Stack spacing={2}>
        {error && (
          <Alert status="error">
            <AlertIcon />
            Wrong credentials, please try again.
          </Alert>
        )}
        {success && (
          <Alert status="success">
            <AlertIcon />
            Mot de passe modifié avec succée
          </Alert>
        )}
        {inputs.map(({ label, name, type, validation, errorMessage }) => (
          <FormControl id={name} isInvalid={!_.isNil(errors[name])} isRequired>
            <FormLabel>{label} :</FormLabel>
            <Input type={type} {...register(name, { ...validation })} />
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
          </FormControl>
        ))}

        <Button
          type="submit"
          bg="primary"
          w="100%"
          mb="20px"
          color="white"
          mt="10px"
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Valider
        </Button>
      </Stack>
    </Box>
  );
};

import aws from "aws-sdk";
import config from "../config";
import { v4 as uuidv4 } from "uuid";
export function useStorage() {
  const s3 = new aws.S3({
    endpoint: config.S3_ENDPOINT,
    accessKeyId: config.S3_ACCESS_KEY_ID,
    secretAccessKey: config.S3_SECRET_ACCESS_KEY,
  });

  const getFileExtension = (filename: string) => {
    return (
      "." +
        filename.substring(filename.lastIndexOf(".") + 1, filename.length) || ""
    );
  };

  const uploadFile = async (file: any, folder: string, name: string) => {
    const key = `${folder}/${name}-(${uuidv4()})${getFileExtension(file.name)}`;
    try {
      await s3
        .upload(
          {
            Bucket: config.S3_BUCKET, // Add bucket name here
            ACL: "public-read", // Specify whether anyone with link can access the file
            Key: key, // Specify folder and file name
            Body: file,
          },
          {
            partSize: 10 * 1024 * 1024,
            queueSize: 10,
          }
        )
        .promise();
      return key;
    } catch (error) {
      console.log("@error", error);
      return "error";
    }
  };

  return { uploadFile };
}

import { Container } from "@chakra-ui/react";
import React, { FC, useContext } from "react";
import ChatPage from "../components/Chat";
import { MainContext } from "../contexts/MainContext";

const Chat = () => {
  const { isAuthenticated } = useContext(MainContext);
  if (isAuthenticated)
    return (
      <Container maxWidth={"8xl"} mt={12}>
        <ChatPage />
      </Container>
    );
  return <></>;
};

export default Chat;

import React, { useState } from "react";
import {
  Box,
  Button,
  CloseButton,
  Container,
  Icon,
  Square,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiInfo } from "react-icons/fi";
import { useAuth } from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const ResendTokenBanner = () => {
  const [hidden, setHidden] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { resendConfirmAccountEmail } = useAuth();

  const handleClick = async () => {
    setLoading(true);
    await resendConfirmAccountEmail();
    setLoading(false);
    setSuccess(true);
  };
  return (
    <Box as="section" pb={{ base: "12", md: "24" }} my={2} hidden={hidden}>
      <Box
        bg="bg-surface"
        boxShadow={useColorModeValue("sm", "sm-dark")}
        // width={"100%"}
        // bg={"primary"}
      >
        <Container
          maxWidth={"7xl"}
          py={{ base: "4", md: "0.2" }}
          position="relative"
          bg={"primary"}
          color={"white"}
          rounded={"md"}
        >
          <CloseButton
            display={{ sm: "none" }}
            position="absolute"
            right="2"
            bottom="2"
          />
          <Stack
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
            spacing={{ base: "3", md: "2" }}
          >
            <Stack
              spacing="4"
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}
            >
              {!isMobile && (
                <Square size="12" bg="bg-subtle" borderRadius="md">
                  <Icon as={FiInfo} boxSize="6" />
                </Square>
              )}
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "0.5", md: "1.5" }}
                pe={{ base: "4", sm: "0" }}
              >
                <Text fontWeight="medium">
                  Il faut confirmer votre compte, vous avez reçu un mail de
                  confirmation, n'oubliez pas vérifier vos spams
                </Text>
              </Stack>
            </Stack>
            <Stack
              direction={{ base: "column", sm: "row" }}
              spacing={{ base: "3", sm: "2" }}
              align={{ base: "stretch", sm: "center" }}
            >
              <Button
                size={"sm"}
                width="full"
                bg={"gray.600"}
                isLoading={isLoading}
                isDisabled={success}
                onClick={handleClick}
              >
                {!success ? (
                  "Envoyez un nouveau mail"
                ) : (
                  <FontAwesomeIcon icon={faCheck} />
                )}
              </Button>
              <CloseButton
                display={{ base: "none", sm: "inline-flex" }}
                onClick={() => setHidden(true)}
              />
            </Stack>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

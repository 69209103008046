import {
  Alert,
  Box,
  Button,
  Center,
  Container,
  Flex,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApplicationStatus from "../../components/common/ApplicationStatus";
import Files from "../../components/common/Files";
import PaiementStatus from "../../components/common/PaiementStatus";
import ProfileBiography from "../../components/common/ProfileBiography";
import PersonnalInformations from "../../components/common/ProfileInformations";
import { MainContext } from "../../contexts/MainContext";
import { inputs } from "../../data/inputs";
import { useAdministrativeSupportApplications } from "../../hooks/useAdministrativeSupportApplications";
import { visaApplicationInputs } from "../../data/applicationInputs";
import FileItem from "../../components/common/FileItem";
import moment from "moment";
import { useAdministrativeSupport } from "../../hooks/useAdministrativeSupport";
import DeletePaiementPopover from "../../components/common/DeletePaiementPopover";

const AdministrativeSupportApplicationDetails = () => {
  const { id } = useParams();
  const { account } = useContext(MainContext);

  const { getApplicationById } = useAdministrativeSupportApplications();
  const { getAdministrativeSupport } = useAdministrativeSupport();

  const [data, setData] = useState<any>();
  const [administrativeSupport, setAdministrativeSupport] = useState<any>();

  const [documents, setDocuments] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(true);

  const getApplication = async () => {
    const { data, error } = await getApplicationById(Number(id));
    const { data: administrativeSupportData } =
      await getAdministrativeSupport();
    const { documents = [] } = administrativeSupportData;

    let items: any[] = [];
    documents?.map((item: any, index: number) => {
      let link: string = "";
      account.uploadedDocumets.map(({ documentId, url }: any) => {
        if (documentId === item.documentId) link = url;
      });
      items.push({
        id: item.documentId,
        url: link,
        name: item.document.name,
        description: item.document.description,
        isRequired: item.mandatory,
      });
    });
    setDocuments(items);
    setAdministrativeSupport(administrativeSupportData);
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    getApplication();
  }, []);

  if (isLoading)
    return (
      <Center width={"100vw"} height={"100vh"}>
        <Spinner color="primary" width={30} height={30} size={"lg"} />
      </Center>
    );

  return (
    <Box mt={12}>
      <Container maxWidth={"6xl"}>
        <Box>Ma candidature </Box>
        <SimpleGrid columns={3}>
          <GridItem colSpan={1}>
            <Box p={4}>
              <Flex justifyContent={"space-between"}>
                <Text>Resumé</Text>
                <ApplicationStatus status={data.status} />
              </Flex>
              <Flex my={2}>
                {/* <Box height={["8rem", "4rem"]} width={["100%", "30%"]}>
                  <Image
                    objectFit="cover"
                    boxSize="100%"
                    rounded={"md"}
                    src={imageUrl}
                  />
                </Box> */}
                <Box px={2}>
                  <Text fontSize={"xs"}>France</Text>
                  <Text>Support administrative</Text>
                </Box>
              </Flex>
              <Box my={4}>
                <Flex justifyContent={"space-between"}>
                  <Text color={"gray.600"} fontSize={"sm"}>
                    submission date
                  </Text>
                  <Text color={"gray.600"} fontSize={"sm"}>
                    {moment(data?.updatedAt).format("l")}
                  </Text>
                </Flex>
                <Flex justifyContent={"space-between"}>
                  <Text color={"gray.600"} fontSize={"sm"}>
                    Payment date
                  </Text>
                  <Text color={"gray.600"} fontSize={"sm"}>
                    {moment(data?.paiement?.createdAt).format("l")}
                  </Text>
                </Flex>
                <Flex justifyContent={"space-between"}>
                  <Text color={"gray.600"} fontSize={"sm"}>
                    Prix total
                  </Text>
                  <Text color={"gray.600"} fontSize={"sm"}>
                    {administrativeSupport?.priceDZD} DZD /
                    {administrativeSupport?.priceEUR} EUR
                  </Text>
                </Flex>
              </Box>

              <ProfileBiography />
            </Box>
          </GridItem>
          <GridItem colSpan={2} p={6}>
            {/* PAIEMENT START */}
            <Box>
              <Flex justifyContent={"space-between"}>
                <Heading size={"md"}>Paiement</Heading>

                <PaiementStatus status={data.paiement?.status || "NOT_PAIED"} />
              </Flex>
              {data.paiement && data.paiement?.type === "DEPOSIT" && (
                <Box>
                  <Heading size={"sm"}>Uploaded reçues</Heading>

                  <FileItem
                    name="Recu du paiement"
                    id={data.paiement?.id}
                    currentDocumentUrl={data.paiement?.proofUrl}
                    isRequired={false}
                    description={moment(data.paiement?.createdAt).format(
                      "YYYY-MM-YY"
                    )}
                    isEditable={false}
                  />
                </Box>
              )}
              {data.paiement && data.paiement?.type === "CASH" && (
                <Box>
                  <Heading size={"sm"}>date du rendez-vous :</Heading>

                  <Heading size={"md"} textAlign={"center"}>
                    {moment(data.paiement?.bookedDate).format("DD-MM-YYYY")}
                  </Heading>
                </Box>
              )}
              {data.paiement && data.paiement?.status === "REFUSED" && (
                <Alert status="error" rounded={"md"} my={2}>
                  {data?.paiement?.refusalNotes || "Aucune observastion"}
                </Alert>
              )}
              {data.paiement &&
                data.paiement?.status !== "PENDING" &&
                data?.paiement?.type === "CASH" &&
                moment(data?.paiement?.bookedDate).isBefore(
                  moment(),
                  "days"
                ) && (
                  <Alert status="error" rounded={"md"} my={2}>
                    Vous avez ratez votre rendez-vous, veuillez re-initialliser
                    votre paiement
                  </Alert>
                )}
              {data.paiement && data.paiement.status !== "ACCEPTED" && (
                <DeletePaiementPopover
                  id={data.paiement?.id}
                  url={`/administrative-support/applications/apply/${id}`}
                />
              )}
            </Box>

            {/* PAIEMENT END */}
            {/* ---------------------------- */}
            {/* PERSONAL INFOS START */}
            <Box my={6}>
              <PersonnalInformations
                items={inputs}
                displayedData={visaApplicationInputs}
              />
            </Box>
            {/* PERSONAL INFOS END */}
            {/* ---------------------------- */}
            {/* DOCUMENTS INFOS START */}
            <Box>
              <Heading size={"sm"}>Documents fournit</Heading>
              <Files items={documents} />
            </Box>
            {/* DOCUMENTS INFOS END */}
          </GridItem>
          <GridItem></GridItem>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default AdministrativeSupportApplicationDetails;

import React, { FC } from "react";
import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const EmailConfirmed: FC = () => {
  const navigate = useNavigate();
  return (
    <Center width={"full"} height={"70vh"}>
      <Box textAlign="center" py={10} px={6}>
        <CheckCircleIcon boxSize={"50px"} color={"green.500"} />
        <Heading as="h2" size="xl" mt={6} mb={2}>
          Votre compte a été bien confirmé.
        </Heading>
        <Text color={"gray.500"}></Text>
        <Button bg={"primary"} color={"white"} onClick={() => navigate("/")}>
          Revenir à la page de recherche
        </Button>
      </Box>
    </Center>
  );
};

export default EmailConfirmed;

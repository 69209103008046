import { Box } from "@chakra-ui/react";
import React, { FC } from "react";
import FileItem from "./FileItem";

const Files: FC<{
  items: {
    id: number;
    url: string;
    name: string;
    description: string;
    isRequired: boolean;
  }[];
}> = ({ items }) => {
  return (
    <Box>
      {items.map((item) => (
        <FileItem
          id={item.id}
          description={item.description}
          name={item.name}
          currentDocumentUrl={item.url}
          isRequired={item.isRequired}
          isEditable={false}
        />
      ))}
    </Box>
  );
};

export default Files;

import ProgramsProvider from "../../contexts/ProgramsContext";
import Search from "../Search";
import Program from "./Program";
import ProgramApplication from "./ProgramApplication";
import ProgramApplicationDetails from "./ProgramApplicationDetails";
import ProgramApplications from "./ProgramApplications";

const programsRoutes = [
  { path: "/programs/:id", element: Program, provider: null },
  {
    path: "/programs",
    element: Search,
    context: ProgramsProvider,
    provider: null,
  },
  {
    path: "/programs/applications",
    element: ProgramApplications,
    isProtected: true,
    provider: null,
  },
  {
    path: "/programs/applications/:id",
    element: ProgramApplicationDetails,
    isProtected: true,
    provider: null,
  },
  {
    path: "/programs/applications/:programId/apply",
    element: ProgramApplication,
    isProtected: true,
    provider: null,
  },
  {
    path: "/programs/applications/:programId/apply/:applicationId",
    element: ProgramApplication,
    isProtected: true,
    provider: null,
  },
];
export default programsRoutes;

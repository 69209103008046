import { Box, Center, Heading, SimpleGrid, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import config from "../../config";
import shortenString from "../../helpers/shortenString";
import { usePrograms } from "../../hooks/usePrograms";
import Card from "../pages/HomePage/Card";

const SelectedPrograms = () => {
  const { getAttachedPrograms } = usePrograms();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  const getData = async () => {
    const { data, error = null } = await getAttachedPrograms();
    if (!error) setData(data);

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading)
    return (
      <Center>
        <Spinner color="primary" width={30} height={30} size={"lg"} />
      </Center>
    );

  return (
    <Center>
      <Box width={"100%"}>
        <SimpleGrid columns={{ base: 1, sm: 1, md: 1 }} spacing={6}>
          {/* --------------------------- */}
          {data.length > 0 &&
            data.map(
              ({ id, pictureUrl, countryCode, name, shortDescription }) => (
                <>
                  <Card
                    id={id}
                    pictureUrl={
                      (pictureUrl && `${config.S3_URL}/${pictureUrl}`) ||
                      "/default-placeholder.png"
                    }
                    country={countryCode}
                    title={name}
                    description={shortenString(shortDescription, 100)}
                  />
                </>
              )
            )}

          {/* --------------------------- */}
        </SimpleGrid>
      </Box>
    </Center>
  );
};

export default SelectedPrograms;

import { Input } from "@chakra-ui/react";
import moment from "moment";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import Select from "react-select";
import DatePicker from "../components/common/DatePicker";
import { getCountry } from "../helpers/getCountry";
import fr from "date-fns/locale/fr"; // the locale you want
registerLocale("fr", fr);

export const inputs = [
  {
    component: Select,
    label: "Pays à partir duquel vous postulez :",
    accessor: "country",
    options: [
      {
        label: `${getCountry("DZ").flag} - ${getCountry("DZ").fr}`,
        value: getCountry("DZ").code,
      },
      {
        label: `${getCountry("TN").flag} - ${getCountry("TN").fr}`,
        value: getCountry("TN").code,
      },
      {
        label: `${getCountry("MA").flag} - ${getCountry("MA").fr}`,
        value: getCountry("MA").code,
      },
      {
        label: `${getCountry("SN").flag} - ${getCountry("SN").fr}`,
        value: getCountry("SN").code,
      },
      {
        label: `${getCountry("CI").flag} - ${getCountry("CI").fr}`,
        value: getCountry("CI").code,
      },
    ],
    validation: {
      required: true,
    },
    render: (country: string) =>
      `${getCountry(country).flag} - ${getCountry(country).fr}`,
    handleSetValue: (e: any) => e,
    errorMessage: "Please enter your skype.",
    md: 4,
  },
  {
    label: "Nom : ",
    accessor: "firstname",
    type: "text",
    validation: {
      required: true,
    },
    errorMessage: "Please your firstname.",
  },
  {
    label: "Prénom : ",
    accessor: "lastname",
    type: "text",
    validation: {
      required: true,
    },
    errorMessage: "Please your lastname.",
  },

  {
    label: "Telephone : ",
    accessor: "phoneNumber",
    type: "number",
    validation: {
      required: true,
    },
    errorMessage: "Please enter your phone number.",
  },
  {
    as: ReactDatePicker,
    locale: "fr",
    label: "Né le : ",
    accessor: "birthDate",
    type: "date",
    dateFormat: "MMMM d, yyyy",
    validation: {
      required: true,
    },
    handleSetValue: (e: any) => {
      return moment(e).format("DD/MM/YYYY");
    },
    component: Input,
    errorMessage: "Please enter your birth date.",
    value: (e: any) => {
      return e;
    },
    defaultValue: (e: any) => {
      return moment(e).format("DD/MM/YYYY");
    },
    // value: (e: any) => e,
    // defaultValue: (e: any) => e,
    peekNextMonth: true,
    showMonthDropdown: true,
    showYearDropdown: true,
    dropdownMode: "select",
  },
  {
    label: "Address : ",
    accessor: "address",
    type: "text",
    validation: {
      required: true,
    },
    errorMessage: "Please enter your address.",
    md: 3,
  },
  {
    label: "Code postal : ",
    accessor: "postalCode",
    type: "text",
    validation: {
      required: true,
    },
    errorMessage: "Please enter your postalCode.",
    md: 1,
  },

  // {
  //   label: "Prenom de pere : ",
  //   accessor: "fatherFirstname",
  //   type: "text",
  //   validation: {
  //     required: true,
  //   },
  //   errorMessage: "Please enter your father name.",
  // },
  // {
  //   label: "Nom et prénom de mére : ",
  //   accessor: "motherFullName",
  //   type: "text",
  //   validation: {
  //     required: true,
  //   },
  //   errorMessage: "Please enter your mother full name.",
  // },

  // {
  //   label: "Skypee : ",
  //   accessor: "skype",
  //   type: "text",
  //   validation: {
  //     required: false,
  //   },
  //   errorMessage: "Please enter your skype.",
  // },
];

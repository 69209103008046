// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// 👇️ ts-ignore ignores any ts errors on the next line
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  faNoteSticky,
  faPaperclip,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { FC, useContext, useState } from "react";
import { ChatContext } from "../../contexts/ChatContext";
import { useStorage } from "../../hooks/useStorage";
import FileInput from "../common/FileInput";

const DeleteApplicationPopover: FC<{
  onDelete: Function;
  enabled: boolean;
}> = ({ onDelete, enabled }) => {
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);
    const { error = null } = await onDelete();
    setLoading(false);
    if (error)
      return toast({
        description: "An error occured, please try later.",
        position: "top",
        status: "error",
        isClosable: true,
      });
    toast({
      description: "Candidature supprimé avec succée.",
      position: "top",
      status: "info",
      isClosable: true,
    });
  };
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          bg={"gray.200"}
          color={"primary"}
          aria-label="true"
          isDisabled={!enabled}
          icon={<FontAwesomeIcon icon={faTrash} />}
        />
      </PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>
          <FontAwesomeIcon icon={faNoteSticky} /> Notes :
        </PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody>
          <Text textAlign={"center"}>
            Vouler vous vraiment supprimer cette candidature ?
          </Text>

          <Button
            bg={"primary"}
            color={"white"}
            width={"full"}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Confirmer
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default DeleteApplicationPopover;

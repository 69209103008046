import { Avatar, Box, Heading, IconButton, Text } from "@chakra-ui/react";
import React from "react";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import Slider from "react-slick";

const Testimonials = () => {
  const [slider, setSlider] = React.useState<Slider | null>(null);

  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const items = [
    {
      fullname: "Lyes Belg",
      subtitle: "Eutiant a paris dauphine",
      imageUrl: "",
      content:
        "une équipe très sérieuse. Merci beaucoup pour vos orientations et conseils, je vous serais reconnaissant pour l'éternité. Merci et mille mercis à vous tous.",
    },
    {
      fullname: "Yasmine aider",
      subtitle: "Eutiant a paris dauphine",
      imageUrl: "",
      content:
        "Je suis très très satisfaite d’avoir connue cette platefome Suivie de ma procédure de A à Z Avec bcp bcp de conseille et hmd j’ai eu mon visa et je vous remercie chaleureusement ",
    },
    {
      fullname: "Feriel Trei",
      subtitle: "Eutiant a paris dauphine",
      imageUrl: "",
      content:
        "Je vous recommande cette plateforme pour leur sérieux et leur travail professionnel, j'étais perdue dans mes démarches mais j'ai trouvé mon chemin avec eux.",
    },
  ];
  return (
    <>
      <Heading textAlign={"center"}>Ce qu'ils disent de nous</Heading>
      <Box
        position={"relative"}
        height={"600px"}
        width={"full"}
        overflow={"hidden"}
      >
        {/* CSS files for react-slick */}
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        {/* Left Icon */}
        <IconButton
          aria-label="left-arrow"
          colorScheme="messenger"
          borderRadius="full"
          position="absolute"
          left={["1", "30%", "10px"]}
          top={"50%"}
          bg={"primary"}
          color={"white"}
          transform={"translate(0%, -50%)"}
          zIndex={2}
          onClick={() => slider?.slickPrev()}
        >
          <BiLeftArrowAlt />
        </IconButton>
        {/* Right Icon */}
        <IconButton
          aria-label="right-arrow"
          colorScheme="messenger"
          borderRadius="full"
          position="absolute"
          right={["1", "30%", "10px"]}
          top={"50%"}
          bg={"primary"}
          color={"white"}
          transform={"translate(0%, -50%)"}
          zIndex={2}
          onClick={() => slider?.slickNext()}
        >
          <BiRightArrowAlt />
        </IconButton>
        {/* Slider */}

        <Slider {...settings} ref={(slider) => setSlider(slider)}>
          {items.map(({ fullname, subtitle, imageUrl, content }) => (
            <Box textAlign={"center"} px={[10, 40]} mt={[20, 40]}>
              <Avatar width={"base"} w={100} h={100} my={2} src={imageUrl} />
              <Text fontSize={"lg"} fontWeight={"bold"}>
                {fullname}
              </Text>
              <Text>{subtitle}</Text>
              <Text color={"primary"} my={6}>
                {content}
              </Text>
            </Box>
          ))}
        </Slider>
      </Box>
    </>
  );
};

export default Testimonials;

import React from "react";
import { ChevronDownIcon, Icon } from "@chakra-ui/icons";
import {
  Collapse,
  Flex,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const MobileNavItem = ({
  path,
  content,
  children = [],
  onClose,
}: {
  path: string;
  content: string;
  children: any[];
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        justify={"space-between"}
        align={"center"}
        onClick={() => {
          if (children.length === 0) {
            navigate(path);
            onClose();
          }
        }}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {content}
        </Text>
        {children.length > 0 && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children.length &&
            children.map((child) => (
              <Text
                key={child.content}
                py={2}
                onClick={() => {
                  navigate(child.link);
                  onClose();
                }}
              >
                {child.content}
              </Text>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default MobileNavItem;

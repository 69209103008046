import { Box, Flex } from "@chakra-ui/react";
import React, { FC, ReactNode, useContext } from "react";
import { useLocation } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import { ResendTokenBanner } from "../common/ResendTokenBanner";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Messenger from "../Messenger";

const MainLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, account } = useContext(MainContext);

  return (
    <>
      <Flex
        height={"auto"}
        direction={"column"}
        justifyContent={"space-between"}
        minH={"100vh"}
        maxW={"100vw"}
        w={"100vw"}
        // overflow={"hidden"}
      >
        <Box
          position={"fixed"}
          width={"100%"}
          height={"auto"}
          zIndex={"tooltip"}
        >
          <Header />
          {isAuthenticated && !account?.verified && <ResendTokenBanner />}
        </Box>
        <Box maxWidth={"100vw"} height={"auto"} mt={19}>
          {children}
        </Box>
        {location.pathname !== "/chat" && <Footer />}
      </Flex>
      {!isAuthenticated && <Messenger />}
    </>
  );
};

export default MainLayout;

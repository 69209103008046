import { GridItem, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import React, { FC, useContext } from "react";
import { MainContext } from "../../contexts/MainContext";
import { v4 as uuidv4 } from "uuid";

const PersonnalInformations: FC<{
  items: { label: string; accessor: string; render?: Function }[];
  displayedData: string[];
}> = ({ items, displayedData }) => {
  const { account } = useContext(MainContext);
  return (
    <>
      <Heading size={"base"}>Informations personnels</Heading>
      <SimpleGrid columns={[1, 3]} py={4} gap={2}>
        {items.map(
          ({ label, accessor, render }) =>
            displayedData.includes(accessor) && (
              <GridItem colSpan={1} my={2} key={uuidv4()}>
                <Heading size={"xs"} color={"grey"}>
                  {label}
                </Heading>

                <Text>
                  {!render && account[accessor] && account[accessor]}
                  {!render && !account[accessor] && "not setted yet."}
                  {render && account[accessor] && render(account[accessor])}
                </Text>
              </GridItem>
            )
        )}
      </SimpleGrid>
    </>
  );
};

export default PersonnalInformations;

import axios from "axios";
import config from "../config";
const BASE_URL = `${config.API_URL}/visa-applications`;

export function useVisaApplications() {
  const getApplications = async () => {
    const path = `${BASE_URL}`;
    try {
      const {
        data: {
          body: { data = [] },
        },
      } = await axios.get(path);
      return { data: data, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };

  const getApplicationByVisaId = async (id: number) => {
    const path = `${BASE_URL}/visa/${id}`;
    try {
      const {
        data: { body },
      } = await axios.get(path);
      return { data: body, error: null };
    } catch (error) {
      return {
        error,
        data: null,
      };
    }
  };

  const createApplication = async (id: number, level: string) => {
    const path = `${BASE_URL}/${id}`;
    try {
      const {
        data: { body },
      } = await axios.post(path, { level });
      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };
  const getApplicationById = async (id: number) => {
    const path = `${BASE_URL}/${id}`;
    try {
      const {
        data: { body },
      } = await axios.get(path);
      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };

  const submitApplicationWithPaiement = async (
    id: number,
    type: "CASH" | "DEPOSIT" | "ON_LINE",
    temp: { proofUrl?: string; bookedDate?: string }
  ) => {
    const pathSuffix = {
      CASH: "cash",
      DEPOSIT: "deposit",
      ON_LINE: "online",
    };

    const path = `${BASE_URL}/${id}/paiement/${pathSuffix[type]}`;

    try {
      const {
        data: { body },
      } = await axios.put(path, { ...temp, amount: 10000 });

      return { data: {}, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };

  const deleteApplicationById = async (id: number) => {
    const path = `${BASE_URL}/${id}`;
    try {
      const {
        data: { body },
      } = await axios.delete(path);
      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };

  const getPaiementIntent = async (id: number) => {
    const path = `${BASE_URL}/${id}/paiement/online/intent`;
    try {
      const {
        data: { body },
      } = await axios.get(path);

      return { data: body, error: null };
    } catch (error) {
      return {
        error,
      };
    }
  };

  return {
    getApplications,
    createApplication,
    getApplicationById,
    getApplicationByVisaId,
    submitApplicationWithPaiement,
    deleteApplicationById,
    getPaiementIntent,
  };
}

import React, { useContext, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  useColorModeValue,
  SimpleGrid,
  GridItem,
  Alert,
  AlertIcon,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import _ from "lodash";

const SignUpForm = () => {
  const { signUp } = useContext(MainContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const onSubmit = async (data: any) => {
    setLoading(true);
    const {
      firstname,
      lastname,
      email,
      phoneNumber,
      password,
      passwordConfirmation,
    } = data;
    const { error } = await signUp(
      firstname,
      lastname,
      email,
      phoneNumber,
      password,
      passwordConfirmation
    );
    if (!error) {
      navigate("/");
      setSuccess(true);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const inputs = [
    {
      name: "firstname",
      label: "Prénom",
      type: "text",
      validation: {
        required: true,
      },
      errorMessage: "Please your firstname.",
      cols: 1,
    },
    {
      name: "lastname",
      label: "Nom",

      type: "text",
      validation: {
        required: true,
      },
      errorMessage: "Please your lastname.",
      cols: 1,
    },
    {
      name: "email",
      label: "Email",

      type: "email",
      validation: {
        required: true,
      },
      errorMessage: "Please your email.",
    },
    {
      name: "phoneNumber",
      label: "Numéro de téléphone",

      type: "number",
      validation: {
        required: true,
      },
      errorMessage: "Please your email.",
    },
    {
      name: "password",
      label: "Mot de passe",

      type: "password",
      validation: {
        required: true,
        minLength: 8,
      },
      errorMessage: "Please enter a valid password (8 characters at least).",
      cols: 1,
    },
    {
      name: "passwordConfirmation",
      label: "Confirmer le mot de passe",

      type: "password",
      validation: {
        required: true,
        minLength: 8,
      },
      errorMessage: "Password & Password confirmation does not match.",
      cols: 1,
    },
  ];
  return (
    <Box
      rounded={"lg"}
      bg={useColorModeValue("white", "gray.700")}
      boxShadow={"lg"}
      p={8}
    >
      <Stack spacing={4}>
        {error && (
          <Alert status="error">
            <AlertIcon />
            Veuillez saisir une adresse e-mail valide (non utilisée auparavant).
          </Alert>
        )}
        {success && (
          <Alert status="error">
            <AlertIcon />
            Compte crée avec succée.
          </Alert>
        )}
        <SimpleGrid columns={2} spacing={2}>
          {inputs.map(
            ({ name, label, type, validation, errorMessage, cols = 2 }) => (
              <GridItem colSpan={[2, 2, cols]}>
                <FormControl
                  id={name}
                  isInvalid={!_.isNil(errors[name])}
                  isRequired
                >
                  <FormLabel>{label} :</FormLabel>
                  <Input type={type} {...register(name, { ...validation })} />
                  <FormErrorMessage>{errorMessage}</FormErrorMessage>
                </FormControl>
              </GridItem>
            )
          )}
        </SimpleGrid>

        <Stack spacing={10} pt={2}>
          <Button
            isLoading={isLoading}
            size="lg"
            bg={"primary"}
            color={"white"}
            onClick={handleSubmit(onSubmit)}
          >
            S'inscrire
          </Button>
        </Stack>
        <Stack pt={6}>
          <Text align={"center"}>Déjà utilisateur ? </Text>
          <Button
            bg={"secondary-2"}
            color={"white"}
            width={"full"}
            onClick={() => navigate("/sign-in")}
          >
            Connectez vous maintenant
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SignUpForm;

export const ProgramApplicationInputs = [
  "firstname",
  "lastname",
  "phoneNumber",
  "birthDate",
  "address",
  "postalCode",
  "fatherFirstname",
  "motherFullName",
  "skype",
  "country",
];

export const visaApplicationInputs = [
  "firstname",
  "lastname",
  "phoneNumber",
  "birthDate",
  "address",
  "postalCode",
  "fatherFirstname",
  "motherFullName",
  "skype",
  "country",
];

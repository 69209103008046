import React, { FC } from "react";
import { Box, List, ListItem } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";

const SidebarList: FC<{ items: string[] }> = ({ items }) => {
  return (
    <List>
      {items.map((item) => (
        <Box key={uuidv4()}>
          <ListItem
            my={2}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"xl"}
          >
            {item}
          </ListItem>
          <hr
            style={{ height: "1px", backgroundColor: "#E0E0E0", width: "80%" }}
          />
        </Box>
      ))}
    </List>
  );
};

export default SidebarList;
